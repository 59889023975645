import React, { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridPagination, GridSortItem, GridSortModel } from '@mui/x-data-grid';

import { ChatModal } from '~/components/SupportAssistant/components/ChatModal';

import { getChatById, getFeedback } from '~/services/SupportService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import userUtils from '~/utils/userUtils';

import { useGetChatTopics } from '../ChatsHistory/hooks/useGetChatTopics';

const LE_BRAND = 'luxuryescapes';

const columns: GridColDef[] = [
  {
    field: 'sentiment',
    headerName: 'Sentiment',
    disableColumnMenu: true,
    maxWidth: 100,
    flex: 1,
    cellClassName: (params: GridCellParams) =>
      clsx('feedback', {
        positive: params.row.sentiment === 'POSITIVE',
        negative: params.row.sentiment === 'NEGATIVE',
      }),
    display: 'flex',
  },
  {
    field: 'chatId',
    headerName: 'Chat ID',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'messageId',
    headerName: 'Message ID',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'customerId',
    headerName: 'User',
    renderCell: (params: GridCellParams) => (
      <>
        {params.value && (
          <Link
            target="_blank"
            color={userUtils.isEmployeeEmail(params.row.customer?.email) ? '#ff0000' : '#000'}
            href={`/users/${params.row.customer?.id_member}`}
          >
            <Typography variant="body2">{params.row.customer?.full_name}</Typography>
            <Typography variant="body2">{params.row.customer?.email}</Typography>
          </Link>
        )}
      </>
    ),
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'issues',
    headerName: 'Feedback Type',
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'message',
    headerName: 'Feedback Content',
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'createdAt',
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    headerName: 'Feedback Received',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    display: 'flex',
  },
];

const LIMIT = 10;

export default function FeedbackPage() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [chatLoading, setChatLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [sortModel, setSortModel] = useState<GridSortItem>({ field: 'createdAt', sort: 'desc' });
  const [feedbackAuthors, setFeedbackAuthors] = useState<Map<string, App.User>>(new Map());
  const [customersOnly, setCustomersOnly] = useState(false);
  const [agentChatsOnly, setAgentChatsOnly] = useState(false);
  const [hasMessagesOnly, setHasMessagesOnly] = useState(true);

  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const searchQuery = params.get('search');

  const { chatTopics: allChatTopics } = useGetChatTopics();

  const feedbacksWithUserData = useMemo(
    () =>
      feedbacks.map((feedback) => ({
        ...feedback,
        customer: feedbackAuthors.get(feedback.customerId),
      })),
    [feedbacks, feedbackAuthors],
  );

  const messagesModalClose = useCallback(() => {
    setSelectedChat(null);
    setMessageId(null);
  }, []);

  useEffect(() => {
    setSearchString(searchQuery || '');
    messagesModalClose();
  }, [messagesModalClose, searchQuery]);

  const handleSortModelChange = (sortModel: GridSortModel) => {
    // sortModel will always have 1 item because non-PRO datagrid has only 1 sort at the same time
    setSortModel({
      field: sortModel?.[0]?.field,
      sort: sortModel?.[0]?.sort,
    });
  };

  const onRowClickHandler = useCallback((row) => {
    setChatLoading(true);
    setMessageId(row.messageId);

    getChatById(row.chatId)
      .then(({ result }) => {
        setSelectedChat(result);
      })
      .catch(console.warn)
      .finally(() => setChatLoading(false));
  }, []);

  const onCellClickHandler = (params) => {
    if (params.field !== 'customerId' || params.row.customerId === null) {
      onRowClickHandler(params.row);
    }
  };

  const fetchFeedback = useCallback(
    (feedbackPage, feedbackSearchString, sortModel, customersOnly, agentChatsOnly, hasMessagesOnly) => {
      setLoading(true);

      getFeedback({
        offset: feedbackPage,
        limit: LIMIT,
        search: feedbackSearchString?.trim(),
        sortBy: sortModel.field,
        sortDirection: sortModel.sort,
        customersOnly,
        agentChatsOnly,
        hasMessagesOnly,
      })
        .then(({ result }) => {
          setFeedbacks(result.feedbacks);
          setTotal(result.count);
        })
        .catch(console.warn)
        .finally(() => setLoading(false));
    },
    [],
  );

  useEffect(() => {
    fetchFeedback(page * LIMIT, searchQuery, sortModel, customersOnly, agentChatsOnly, hasMessagesOnly);
  }, [fetchFeedback, page, searchQuery, sortModel, customersOnly, agentChatsOnly, hasMessagesOnly]);

  const updateQuery = (e) => {
    if (e.keyCode !== 13 && e.target.name !== 'feedback-search-submit') {
      return;
    }
    params.set('search', searchString);
    history.push({
      search: params.toString(),
    });
  };

  useEffect(() => {
    if (feedbacks.length > 0) {
      const customers = feedbacks.map((feedback) => feedback.customerId).filter((feedback) => feedback);

      if (customers.length === 0) {
        return;
      }

      UsersService.getUsersSummaryByIds([...new Set(customers)], LE_BRAND).then((users) => setFeedbackAuthors(users));
    }
  }, [feedbacks]);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h4">Feedback</Typography>
      <Stack direction="row" spacing={2} onKeyDown={updateQuery}>
        <TextField
          variant="outlined"
          type="text"
          label="Search feedback by customer ID or chat title"
          onChange={(event) => setSearchString(event.target.value)}
          value={searchString}
          fullWidth
        />
        <Button name="feedback-search-submit" variant="contained" color="primary" onClick={updateQuery}>
          Search
        </Button>
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox checked={customersOnly} onChange={(event) => setCustomersOnly(event.target.checked)} />}
          label="Customers only"
        />
        <FormControlLabel
          control={<Checkbox checked={agentChatsOnly} onChange={(event) => setAgentChatsOnly(event.target.checked)} />}
          label="Agent chats only"
        />
        <FormControlLabel
          control={
            <Checkbox checked={hasMessagesOnly} onChange={(event) => setHasMessagesOnly(event.target.checked)} />
          }
          label="Hide Empty Chats"
        />
      </Stack>
      <Box
        sx={{
          '& .feedback.negative': {
            color: (theme) => theme.palette.error.light,
            fontWeight: '600',
          },
          '& .feedback.positive': {
            color: (theme) => theme.palette.success.light,
            fontWeight: '600',
          },
        }}
      >
        <DataGrid
          autoHeight
          columns={columns}
          getRowHeight={() => 'auto'}
          loading={loading}
          onPaginationModelChange={({ page }) => setPage(page)}
          onCellClick={(params) => onCellClickHandler(params)}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[LIMIT]}
          paginationMode="server"
          paginationModel={{ pageSize: LIMIT, page }}
          rowCount={total}
          rows={feedbacksWithUserData || []}
          slots={{
            loadingOverlay: LinearProgress,
            pagination: GridPagination,
          }}
          sortingMode="server"
          sortModel={[sortModel]}
        />
      </Box>
      <ChatModal
        open={!!selectedChat || chatLoading}
        onClose={messagesModalClose}
        loading={chatLoading}
        chat={selectedChat}
        messageId={messageId}
        allChatTopics={allChatTopics}
      />
    </Stack>
  );
}
