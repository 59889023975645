import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import useCruiseInclusions from '~/components/Cruises/context/useCruiseInclusions';
import { CruiseInclusion } from '~/components/Cruises/pages/Inclusions/types';

import { formatDateShort } from '~/services/TimeService';
import inclusionsService from '~/services/cruises/InclusionsService';

import InclusionSearchForm from './InclusionSearchForm';

export default function InclusionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteButton, setDeleteButton] = useState(null);
  const { inclusions, page, total, RECORDS_PER_PAGE, paginate, getInclusionList, loading } = useCruiseInclusions();
  const history = useHistory();

  const handleDeleteBanner = useCallback(
    async (id: string) => {
      try {
        await inclusionsService.deleteById(id);
        enqueueSnackbar('Inclusion deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting Inclusion', { variant: 'error' });
      }
    },
    [enqueueSnackbar],
  );

  const actionColumn = useCallback(
    (cell, row) => (
      <Box>
        {deleteButton !== row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                history.push(`/cruises/inclusions/edit/${row.id}/items`);
              }}
            >
              Edit
            </Button>

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(row.id);
              }}
            >
              Delete
            </Button>
          </Box>
        )}

        {deleteButton === row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleDeleteBanner(row.id);
                getInclusionList();
              }}
            >
              Confirm
            </Button>

            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    ),
    [deleteButton, handleDeleteBanner, getInclusionList, history],
  );

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Title',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      sortable: false,
      renderCell: (params) => actionColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <InclusionSearchForm loading={loading} onSearch={getInclusionList} />

      <Stack mt={3} width="100%" direction="row" justifyContent="flex-end">
        <Button component={Link} to="/cruises/inclusions/create" variant="text" size="small">
          Add New Inclusion
        </Button>
      </Stack>

      <DataGrid
        loading={loading}
        rows={inclusions || []}
        columns={columns}
        pagination
        paginationModel={{ page: page - 1, pageSize: RECORDS_PER_PAGE }}
        pageSizeOptions={[10]}
        getRowId={(row: CruiseInclusion) => row.id}
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => paginate(page + 1)}
        slots={{ pagination: GridPagination }}
        autoHeight
      />
    </Box>
  );
}
