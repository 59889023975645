import * as ES from '../../services/ExperiencesService';

import { themes } from './themes';

type StatusPurchase = {
  color: string;
  title: string;
};

export type PurchaseStatus = ES.ExperienceItem['status'];

export const getStatusByPurchase = (purchaseStatus?: PurchaseStatus): StatusPurchase => {
  const status = purchaseStatus?.toLocaleUpperCase();

  if (!status) {
    return { title: 'N/A', color: themes.primaryRed };
  }

  if (status === 'CANCELLED') {
    return { title: status, color: themes.primaryRed };
  }

  if (status === 'COMPLETED') {
    return { title: 'BOOKED', color: themes.primaryGreen };
  }

  return { title: 'PENDING', color: themes.primaryYellow };
};
