import React from 'react';

import { Box, Chip, Tooltip } from '@mui/material';

import { CommissionsConditionTypes, ruleConditionsToTextMap } from '~/consts/agentHub';

interface Props {
  rules: Record<string, Array<string>>;
  agents?: Record<string, string>;
  agencies?: Record<string, string>;
}

interface MappedRules {
  agents?: Record<string, string>;
  agencies?: Record<string, string>;
}

function getRuleInfo(key: string, rules: Array<string>) {
  switch (key) {
    case 'margin':
      return `${rules[0]}% - ${rules[1]}%`;
    default:
      return `${rules.length}`;
  }
}

function getRuleText(key: string, rules: Array<string>, mappedRules: MappedRules) {
  switch (key) {
    case 'agent':
      if (!mappedRules.agents) {
        return rules.join(' • ');
      }
      return rules.map((rule) => mappedRules.agents[rule]).join(' • ');
    case 'agency':
      if (!mappedRules.agencies) {
        return rules.join(' • ');
      }
      return rules.map((rule) => mappedRules.agencies[rule]).join(' • ');
    default:
      return rules.join(' • ');
  }
}

export function AgentHubCommissionRules({ rules, agents, agencies }: Props) {
  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {Object.entries(rules).map(([key, rules]: [string, Array<string>]) => (
        <Tooltip key={key} title={getRuleText(key, rules, { agents, agencies })}>
          <Chip
            sx={{ backgroundColor: '#00000014' }}
            label={`${ruleConditionsToTextMap.get(key as CommissionsConditionTypes)} (${getRuleInfo(key, rules)})`}
            variant="filled"
          />
        </Tooltip>
      ))}
    </Box>
  );
}
