import React, { useEffect, useState } from 'react';

import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { CommissionOption, RuleCondition } from '~/consts/agentHub';

import { getAffiliationList } from '~/services/AgentHub/AgentService';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsAffiliationInput({ setCreatedConditions, existingConditions }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<CommissionOption>([]);
  const [selectedAffiliations, setSelectedAffiliations] = useState<CommissionOption>(existingConditions || []);

  const handleSaveCondition = () => {
    setCreatedConditions(
      {
        type: 'affiliation',
        value: selectedAffiliations,
      },
      true,
    );
  };

  const handleChange = (_: React.ChangeEvent, value: CommissionOption) => {
    setSelectedAffiliations(value);
  };

  const fetchAffiliationList = async () => {
    setLoadingState('loading');
    getAffiliationList()
      .then((result) =>
        setResult(
          result.map((affiliation) => ({
            value: affiliation.name,
            label: `${affiliation.region} - ${affiliation.name}`,
          })),
        ),
      )
      .finally(() => setLoadingState('success'));
  };

  useEffect(() => {
    fetchAffiliationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSaveCondition();
  }, [selectedAffiliations]);

  const defaultProps = {
    options: result,
    getOptionLabel: (option: CommissionOption[0]) => option.label,
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          {...defaultProps}
          multiple
          loading={loadingState === 'loading'}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Affiliations" placeholder="Affiliations" />}
          value={selectedAffiliations}
          onChange={handleChange}
          noOptionsText={'No affiliations found'}
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agencies
        </Typography>
      </div>
    </Stack>
  );
}

export default AgentHubCommissionsAffiliationInput;
