import qs from 'qs';

import { operations } from '@luxuryescapes/contract-svc-notification/generated';

import { formatDateLongISO } from './TimeService';
import { request } from './common';

export type FailedEmailRecord =
  operations['/api/le-notify/failed-emails/get']['responses']['200']['content']['application/json']['result']['data'][0];

function basePath() {
  return window.configs.API_HOST + '/api/';
}

interface ResendFailedEmailProps {
  id: string;
}

export async function resendFailedEmail(props: ResendFailedEmailProps) {
  const { id } = props;
  return request(`${basePath()}le-notify/failed-emails/${id}/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
}

type GetFailedEmailsResponse =
  operations['/api/le-notify/failed-emails/get']['responses']['200']['content']['application/json'];
interface GetFailedEmailsProps {
  pageNum: number;
  pageSize: number;
  brand: string;
  payloadQuery: string;
  lastOccurredAtStart?: Date;
  httpPath: string;
  isSuccessfulSent: boolean;
  id: string;
}
export async function getFailedEmails(props: GetFailedEmailsProps): Promise<GetFailedEmailsResponse> {
  const { pageNum, pageSize, brand, payloadQuery, lastOccurredAtStart, httpPath, isSuccessfulSent, id = '' } = props;
  const queryParams = {
    pageSize,
    pageNum,
    brand,
    payloadQuery,
    httpPath,
    lastOccurredAtStart: lastOccurredAtStart && formatDateLongISO(lastOccurredAtStart),
    lastOccurredAtEnd: formatDateLongISO(new Date(2099, 11, 31, 23, 59)),
    isSuccessfulSent,
    id,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}le-notify/failed-emails?${queryString}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}
