import React, { useCallback, useEffect, useState } from 'react';

import { InputLabel, Stack, TextField } from '@mui/material';

interface Props {
  vendorContribution: number;
  LEContribution: number;
  handleCustomVendorContribution: (newValue: string) => void;
  costPrice: number;
  customerRefundAmount?: string;
}

export default function EditableShareOfRefund({
  vendorContribution,
  LEContribution,
  handleCustomVendorContribution,
  costPrice,
  customerRefundAmount,
}: Props) {
  const [internalCustomVendorContribution, setInternalCustomVendorContribution] = useState(vendorContribution ?? 0);

  // Update internal state when vendorContribution prop changes
  useEffect(() => {
    setInternalCustomVendorContribution(vendorContribution);
  }, [vendorContribution]);

  const updateVendorContribution = useCallback(
    (e) => {
      const maxAllowedContribution = customerRefundAmount
        ? Math.min(parseFloat(customerRefundAmount), costPrice)
        : costPrice;
      const updatedValue = Math.min(parseFloat(e.target.value) || 0, maxAllowedContribution);

      setInternalCustomVendorContribution(updatedValue);
      handleCustomVendorContribution(updatedValue.toFixed(2));
    },
    [costPrice, handleCustomVendorContribution, customerRefundAmount],
  );

  return (
    <Stack direction="row" spacing={2}>
      <Stack>
        <InputLabel
          htmlFor="vendor-contributions-input"
          sx={{
            fontWeight: 'bold',
          }}
        >
          Vendor Contribution
        </InputLabel>
        <TextField
          id="custom-vendor-contribution"
          name="customVendorContribution"
          value={internalCustomVendorContribution}
          hiddenLabel
          type="number"
          onChange={updateVendorContribution}
          size="small"
        />
      </Stack>
      <Stack>
        <InputLabel
          htmlFor="le-contributions-input"
          sx={{
            fontWeight: 'bold',
          }}
        >
          LE Contribution
        </InputLabel>
        <TextField
          id="le-contributions-input"
          value={LEContribution}
          name="LEContributions"
          type="number"
          size="small"
          variant="filled"
          hiddenLabel
          disabled
        />
      </Stack>
    </Stack>
  );
}
