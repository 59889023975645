import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { RuleCondition, TIER_OPTIONS } from '~/consts/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsTierInput({ setCreatedConditions, existingConditions }: Props) {
  const [selectedTiers, setSelectedTiers] = useState<Array<string>>(
    existingConditions?.map((cond) => cond.value as string) || [],
  );

  function handleSaveRule() {
    setCreatedConditions(
      {
        type: 'tier',
        value: selectedTiers.map((tier) => ({
          value: tier,
          label: tier,
        })),
      },
      true,
    );
  }

  useEffect(() => {
    handleSaveRule();
  }, [selectedTiers]);

  function handleChange(event: SelectChangeEvent<Array<string> | string>) {
    const {
      target: { value },
    } = event;

    setSelectedTiers(typeof value === 'string' ? value.split(',') : value);
  }

  function renderValueText() {
    if (selectedTiers.length === 1) {
      return selectedTiers[0];
    }

    return `${selectedTiers.length} tiers selected`;
  }

  return (
    <Stack gap={2}>
      <FormControl fullWidth>
        <InputLabel id="agent-hub-commissions-tier-input-label">Tier</InputLabel>
        <Select
          labelId="agent-hub-commissions-tier-input-label"
          multiple
          value={selectedTiers}
          onChange={handleChange}
          input={<OutlinedInput label="Tier" />}
          renderValue={renderValueText}
        >
          {TIER_OPTIONS.map((tier) => (
            <MenuItem key={tier} value={tier}>
              <Checkbox checked={selectedTiers.includes(tier)} />
              <ListItemText primary={tier} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default AgentHubCommissionsTierInput;
