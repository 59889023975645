import PublicOffersService from '../../../services/PublicOfferService';
import { getTourById } from '../../../services/ToursService';
import OfferService from '../../../services/cruises/OfferService';

import { OrderListItemWithSortId } from './SortableOfferListOrder';

export interface VerificationResult {
  isValid: boolean;
  reason?: string;
  bk: string;
}

export async function verifyOffer(
  offer: OrderListItemWithSortId,
  regionCode: string,
  brand: App.Brands,
  offerType: string,
): Promise<VerificationResult> {
  const getMap = {
    hotel: PublicOffersService.getOfferById,
    bedbank: PublicOffersService.getBedbankOfferById,
    tour_v2: getTourById,
    cruise: OfferService.getById,
  };

  try {
    if (!(offerType in getMap)) {
      return {
        isValid: true,
        reason: 'Offer type does not need checking',
        bk: offer.bk,
      };
    }

    const foundOffer: VerificationResult = await getMap[offerType](offer.bk, regionCode, brand);
    if (!foundOffer) {
      throw new Error('Offer not found');
    }

    return { isValid: true, bk: offer.bk };
  } catch (err) {
    return { isValid: false, reason: 'Offer not found', bk: offer.bk };
  }
}
