import React from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Link, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { ScoreSettings } from '~/services/SearchService';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#dadde9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface SearchRankingTooltipProps {
  scoreSettings: ScoreSettings;
  vertical: 'hotel' | 'tour' | 'cruise' | 'experience' | 'unified' | 'ultra lux';
  dayRangeInfo?: string;
}

function SearchRankingTooltip({ scoreSettings, vertical, dayRangeInfo }: SearchRankingTooltipProps) {
  const isAllVertical = vertical === 'unified';

  return (
    <HtmlTooltip
      title={
        <>
          {isAllVertical ? (
            <>
              <Box>Day Range: Min({scoreSettings.daySpan}, days since offer live)</Box>
              <Box>Regions: {scoreSettings.regions.join(',')}</Box>
              <Box>Ranked on daily sales</Box>
            </>
          ) : (
            <>
              <Box>Day Range: {dayRangeInfo}</Box>
              <Box>Min Views: {scoreSettings.minViews}</Box>
              <Box>Regions: {scoreSettings.regions.join(',')}</Box>
            </>
          )}
          <Link href="https://aussiecommerce.atlassian.net/wiki/spaces/OSS/pages/2971435033/Documentation+-+Search+Ranking">
            Confluence Page
          </Link>
          <Box></Box>
        </>
      }
      placement="right"
      arrow
    >
      <InfoOutlined sx={{ fontSize: 24 }} />
    </HtmlTooltip>
  );
}

export default SearchRankingTooltip;
