import React from 'react';

import pluralize from 'pluralize';

import { AttractionsOutlined, ExploreOutlined, HotelOutlined, OpenInNew, SailingOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

import Image from '~/components/Common/Image';

const RelativeImage = styled(Image)`
  position: relative;
`;

const buildUrl = (tenant: App.Tenant, path: string) => {
  let host = window.configs['LUX_CUSTOMER_PORTAL'];
  switch (tenant.value) {
    case 'scoopon':
      host = window.configs['SCOOPON_CUSTOMER_PORTAL'];
      break;
    case 'cudo':
      host = window.configs['CUDO_CUSTOMER_PORTAL'];
      break;
    case 'treatme':
      host = window.configs['TREATME_CUSTOMER_PORTAL'];
      break;
    case 'deals':
      host = window.configs['DEALS_CUSTOMER_PORTAL'];
      break;
  }
  return `${host}${path}?preview=true`;
};

const clamper = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

interface Props {
  tenant: App.Tenant;
  vertical?: 'hotel' | 'tour' | 'cruise' | 'experience';
  imageId?: string;
  title?: string;
  subtitle?: string;
  path?: string;
  index: number;
  remainingDays?: number | 'OUT_OF_SCHEDULE';
}

function getRemainingDaysMessage(remainingDays: number) {
  if (remainingDays === 0) {
    return 'Final day';
  }
  if (remainingDays <= 3) {
    return `${remainingDays} ${pluralize('day', remainingDays)} remaining`;
  }
  return null;
}

function BaseOfferBox({ tenant, vertical, imageId, title, subtitle, path, index, remainingDays }: Props) {
  let remainingDaysMessage = null;

  if (remainingDays === 'OUT_OF_SCHEDULE') {
    remainingDaysMessage = 'Out of schedule';
  } else if (remainingDays !== undefined) {
    remainingDaysMessage = getRemainingDaysMessage(remainingDays);
  }
  return (
    <Grid container columnGap={1} alignItems="center" sx={{ height: 77 }}>
      <Grid item sx={{ width: { xs: '100px', md: '138px' }, position: 'relative' }}>
        <RelativeImage publicId={imageId} sx={{ width: { xs: 100, md: 138 }, height: 77 }} />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '22px',
            background: 'radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
            borderRadius: '50%',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Typography color={grey[50]}>{index + 1}</Typography>
        </Box>
      </Grid>
      <Grid item xs padding={1}>
        <Grid container>
          <Grid item xs container alignItems="center" gap={1}>
            <Grid item display={{ xs: 'none', md: 'block' }}>
              {vertical === 'hotel' && <HotelOutlined />}
              {vertical === 'tour' && <ExploreOutlined />}
              {vertical === 'experience' && <AttractionsOutlined />}
              {vertical === 'cruise' && <SailingOutlined />}
            </Grid>
            <Grid item xs>
              <Typography variant="h5" sx={clamper}>
                {title}{' '}
              </Typography>
            </Grid>
            <Grid item xs display={{ xs: 'none', md: 'block' }}>
              {remainingDaysMessage && (
                <Typography variant="h5" sx={{ color: 'red' }}>
                  ({remainingDaysMessage})
                </Typography>
              )}
            </Grid>
          </Grid>
          {path && (
            <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
              <Button
                size="small"
                variant="text"
                endIcon={<OpenInNew />}
                target="_blank"
                href={buildUrl(tenant, path)}
                rel="noreferrer"
              >
                Preview
              </Button>
            </Box>
          )}
        </Grid>
        {subtitle && (
          <Typography variant="subtitle1" sx={{ ...clamper, display: { xs: 'none', md: 'block' } }}>
            {subtitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default BaseOfferBox;
