import React, { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import departureService from '~/services/cruises/DepartureService';

import { isNumeric } from '~/utils/stringUtils';

const DEPARTURES_LIMIT = 500;

interface Props {
  selectedDepartureIds: Array<string>;
  onChange: (selectedDepartureIds: Array<string>) => void;
  vendorId: string;
}

interface SelectedDeparture {
  id: string;
  externalId: string;
  name: string;
}

function InputDepartureSelect({ selectedDepartureIds, onChange, vendorId }: Props) {
  const [loadedVendorId, setLoadedVendorId] = useState<string | null>(null);
  const [selectedDepartures, setSelectedDepartures] = useState<Array<SelectedDeparture>>([]);
  const [departures, setDepartures] = useState<Request<Array<API.DepartureResponse>>>({
    result: [],
    loading: false,
  });

  const fetchDepartures = useCallback(
    // departureKey could be used to filter a departure.name or departure.externalId
    async (departureKey?: string) => {
      if (!departureKey || !vendorId) {
        return;
      }

      const isOnlyNumber = isNumeric(departureKey);
      const departureName = !isOnlyNumber ? departureKey : undefined;
      const departureExternalId = isOnlyNumber ? Number(departureKey) : undefined;
      setDepartures((prev) => ({ ...prev, loading: true }));
      const res = await departureService.listWithPagination({
        take: DEPARTURES_LIMIT,
        status: 'ACTIVE',
        vendorId,
        name: departureName,
        sailingExternalId: departureExternalId,
      });

      setLoadedVendorId(vendorId);
      setDepartures({ loading: false, result: res.result });
    },
    [vendorId],
  );

  useEffect(() => {
    if (!!vendorId && loadedVendorId !== vendorId) {
      fetchDepartures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);

  const departuresSelectOptions = useMemo(() => {
    return departures.result
      ?.filter((item) => !selectedDepartureIds.includes(item.id))
      .map((item) => ({
        value: item.id,
        label: `${item.externalId} - ${item.name}`,
      }));
  }, [departures.result, selectedDepartureIds]);

  const departuresSelected = useMemo(() => {
    return selectedDepartures.map((item) => ({
      value: item.id,
      label: `${item.externalId} - ${item.name}`,
    }));
  }, [selectedDepartures]);

  const handleDepartureChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(selectedOptions?.map((selectedDeparture) => selectedDeparture.value) || []);
      setSelectedDepartures((prevState) => {
        if (!selectedOptions) {
          return prevState;
        }
        return selectedOptions
          .map((selectedDeparture) => {
            const [externalId, departureName] = selectedDeparture.label.split(' - ');
            return {
              id: selectedDeparture.value,
              externalId,
              name: departureName,
            };
          })
          .filter((item) => !!item);
      });
    },
    [onChange],
  );

  const debouncedFetchDepartures = useMemo(() => debounce(fetchDepartures, 500), [fetchDepartures]);

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          id="select-departures"
          multiple
          selectOnFocus
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={!vendorId}
          loading={departures.loading}
          value={departuresSelected}
          onChange={handleDepartureChange}
          options={departuresSelectOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} required fullWidth label="Departures" placeholder="Please Enter Departures" />
          )}
          onInputChange={(_, value) => debouncedFetchDepartures(value)}
        />
      </FormControl>
      {departures.loading && <Spinner size={20} />}
    </Stack>
  );
}

export default InputDepartureSelect;
