import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Bedbank } from '@luxuryescapes/contract-svc-bedbank';

import bedbankService from '~/services/BedbankService';

import ControlRoomElement from './RoomMapping/ControlRoomElement';
import SourceRoomElement from './RoomMapping/SourceRoomElement';
import type { MappedRoom, MappingChange, PropertyRoom, SourceRoom, Suppliers } from './RoomMapping/types';

interface Room extends Bedbank.RoomEdit {
  isSelected?: boolean;
}

interface SourceRoomsBySupplier {
  webbeds: Array<SourceRoom>;
  stuba: Array<SourceRoom>;
  dida: Array<SourceRoom>;
  hotelbeds: Array<SourceRoom>;
  expedia: Array<SourceRoom>;
}

const formatSource = (rooms: Array<Bedbank.SupplierSourceRoom>, supplier: Suppliers): Array<SourceRoom> =>
  rooms.map((room) => ({
    id: room.id,
    externalId: room.externalId,
    externalPropertyId: room.externalPropertyId,
    supplier,
    name: room.name,
    // Remove HTML tags from description for Expedia rooms
    description: supplier === 'expedia' ? room.description.replace(/<[^>]*>?/gm, '') : room.description,
    amenities: room.amenities,
    bedGroups: room.bedGroups,
    views: room.views,
    characteristic: room.characteristic,
    occupancy: room.occupancy,
    isAutomappingEnabled: room.isAutoMappingEnabled,
    hasInfo: Boolean(
      room.amenities.length ||
        room.bedGroups.length ||
        room.characteristic ||
        room.views.length ||
        Object.values(room.occupancy ?? {}).length,
    ),
    sourceContent: room?.sourceContent,
    roomSize: room?.roomSize,
    availabilityScore: room.availabilityScore,
    availabilityScoreUpdatedAt: room.availabilityScoreUpdatedAt,
  }));

// First, sort by whether the room has info or not. Then, sort by availability score.
const sortSourceRoom = (a: SourceRoom, b: SourceRoom): number => {
  if (a.hasInfo && !b.hasInfo) {
    return -1;
  }

  if (!a.hasInfo && b.hasInfo) {
    return 1;
  }

  // handle both undefined and null (meant to be using == here)
  if (a.availabilityScore == null && b.availabilityScore == null) {
    return 0;
  }

  if (a.availabilityScore == null) {
    return 1;
  }
  if (b.availabilityScore == null) {
    return -1;
  }

  return b.availabilityScore - a.availabilityScore;
};

const extractKey = (key: string): [Suppliers, string] => {
  const [supplier, ...rest] = key.split('-');
  return [supplier as Suppliers, rest.join('-')];
};

interface Props {
  property: App.Bedbank.PropertyEdit;
  onForceDecoupleRoomsClick: (ids: Array<string>) => void;
  handleChange: ({
    update,
    remove,
    automappingFlagUpdate,
  }: {
    update: App.Bedbank.SupplierRoomMappingPayload;
    remove: App.Bedbank.SupplierRoomMappingPayload;
    automappingFlagUpdate: App.Bedbank.SupplierRoomAutomappingFlagPayload;
  }) => void;
}

function BedbankRoomMapping({ property, handleChange, onForceDecoupleRoomsClick }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [sourceRooms, setSourceRooms] = useState<SourceRoomsBySupplier>({
    hotelbeds: [],
    webbeds: [],
    stuba: [],
    dida: [],
    expedia: [],
  });
  const [propertyRooms, setPropertyRooms] = useState<Array<PropertyRoom>>([]);

  const [updatePayload, setUpdatePayload] = useState<App.Bedbank.SupplierRoomMappingPayload>([]);
  const [removePayload, setRemovePayload] = useState<App.Bedbank.SupplierRoomMappingPayload>([]);
  const [automappingFlagPayload, setAutomappingFlagPayload] = useState<App.Bedbank.SupplierRoomAutomappingFlagPayload>(
    [],
  );

  const [alreadyMappedRooms, setAlreadyMappedRooms] = useState<Record<string, boolean>>({});
  const [selectedSourceRooms, setSelectedSourceRooms] = useState<Array<string>>([]);
  const [selectedPropertyRoom, setSelectedPropertyRoom] = useState<string | null>(null);

  const [filteringText, setFilteringText] = useState('');
  const resetFilteringText = useCallback(() => {
    setFilteringText('');
  }, []);
  const handleFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setFilteringText(event.target.value);
  }, []);
  const [supplierFilteringText, setSupplierFilteringText] = useState('');
  const resetSupplierFilteringText = useCallback(() => {
    setSupplierFilteringText('');
  }, []);
  const handleSupplierFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSupplierFilteringText(event.target.value);
  }, []);

  const [appliedButNotSavedRooms, setAppliedButNotSavedRooms] = useState<Array<SourceRoom>>([]);

  const [hideRoomsWithNoAvailabilityScore, setHideRoomsWithNoAvailabilityScore] = useState<boolean>(true);

  const toggleHideRoomsWithNoAvailabilityScore = () => {
    setHideRoomsWithNoAvailabilityScore((prev) => !prev);
  };

  const filteredPropertyRooms = useMemo(() => {
    const notExcludedRooms = propertyRooms.filter((room) => room.status !== 'excluded');
    const filteredPropertyRooms = filteringText
      ? notExcludedRooms.filter((room) => {
          const roomDisplayName = room.nameOverride ?? room.name;
          return (
            roomDisplayName.toLowerCase().includes(filteringText.toLowerCase()) ||
            room.mappedRooms
              // if there are any existing mapping that is deleted, then the parent property room should not be returned when searching by the mapping's external id
              .filter((mappedRoom) => alreadyMappedRooms[`${mappedRoom.supplier}-${mappedRoom.externalId}`])
              .some((mappedRoom) => mappedRoom.externalId.toLowerCase().includes(filteringText.toLowerCase())) ||
            room.externalId?.toLowerCase()?.includes(filteringText.toLowerCase())
          );
        })
      : notExcludedRooms;

    return hideRoomsWithNoAvailabilityScore
      ? filteredPropertyRooms.map((propertyRoom) => {
          return {
            ...propertyRoom,
            mappedRooms: propertyRoom.mappedRooms.filter(
              (mappedRoom) =>
                mappedRoom.sourceContent.availabilityScore !== null || mappedRoom.supplier === propertyRoom.supplier,
            ),
          };
        })
      : filteredPropertyRooms;
  }, [propertyRooms, filteringText, hideRoomsWithNoAvailabilityScore, alreadyMappedRooms]);

  const unmappedFilteredSourceRooms = useMemo(() => {
    const initialRooms = [
      ...(sourceRooms.hotelbeds ?? []),
      ...(sourceRooms.webbeds ?? []),
      ...(sourceRooms.stuba ?? []),
      ...(sourceRooms.dida ?? []),
    ];

    const supplierFilteredRooms = supplierFilteringText
      ? initialRooms.filter((room) => {
          return (
            room.name.toLowerCase().includes(supplierFilteringText.toLowerCase()) ||
            room.views?.some((view) => view.toLowerCase().includes(supplierFilteringText.toLowerCase())) ||
            room.amenities?.some((amenity) => amenity.toLowerCase().includes(supplierFilteringText.toLowerCase())) ||
            room.bedGroups?.some((bedGroup) =>
              bedGroup.description.toLowerCase().includes(supplierFilteringText.toLowerCase()),
            ) ||
            room.externalId.toLowerCase().includes(supplierFilteringText.toLowerCase()) ||
            room.description.toLowerCase().includes(supplierFilteringText.toLowerCase())
          );
        })
      : initialRooms;

    const availabilityScoreFilteredRooms = hideRoomsWithNoAvailabilityScore
      ? supplierFilteredRooms.filter((room) => room.availabilityScore !== null)
      : supplierFilteredRooms;

    // Rooms that have been applied but not saved should not be shown in the list
    const appliedButNotSavedFilteredRooms = availabilityScoreFilteredRooms.filter(
      (room) => !appliedButNotSavedRooms.some((r) => r.externalId === room.externalId),
    );

    // should hide rooms that have already been mappped

    const unmappedFilteredSourceRooms = appliedButNotSavedFilteredRooms.filter(
      (room) => !alreadyMappedRooms[`${room.supplier}-${room.externalId}`],
    );

    return unmappedFilteredSourceRooms;
  }, [
    sourceRooms.hotelbeds,
    sourceRooms.webbeds,
    sourceRooms.stuba,
    sourceRooms.dida,
    supplierFilteringText,
    hideRoomsWithNoAvailabilityScore,
    appliedButNotSavedRooms,
    alreadyMappedRooms,
  ]);

  const selectedFromSourceRooms = useMemo(() => {
    const selectedCount = selectedSourceRooms.length;

    const totalCount = unmappedFilteredSourceRooms.length;

    const unmappedRoomsWithNoAvailabilityScoreCount = unmappedFilteredSourceRooms.filter(
      (room) => room.availabilityScore === null,
    ).length;

    const unmappedRoomsWithAvailabilityScoreCount = totalCount - unmappedRoomsWithNoAvailabilityScoreCount;

    return selectedCount > 0
      ? `${selectedCount} selected of ${totalCount} unmapped ${pluralize('rooms', totalCount)}`
      : `${unmappedRoomsWithAvailabilityScoreCount} 
         ${pluralize('rooms', unmappedRoomsWithAvailabilityScoreCount)} with availability score, 
        ${unmappedRoomsWithNoAvailabilityScoreCount} ${pluralize(
          'rooms',
          unmappedRoomsWithNoAvailabilityScoreCount,
        )} without availability score`;
  }, [unmappedFilteredSourceRooms, selectedSourceRooms]);

  const selectedFromPropertyRooms = useMemo(() => {
    const selectedRoomId = selectedPropertyRoom ? extractKey(selectedPropertyRoom)[1] : null;
    const selectedRoom = selectedRoomId
      ? filteredPropertyRooms.find((room) => room.externalId === selectedRoomId)
      : null;
    const totalCount = filteredPropertyRooms.length;

    // Exclude rooms that has the same externalId since they would always be mapped to their property room counterpart
    const totalWithMappedRooms = filteredPropertyRooms.filter(
      (room) => room.mappedRooms.filter((mappedRoom) => mappedRoom.externalId !== room.externalId).length > 0,
    ).length;

    const totalWithoutMappedRooms = totalCount - totalWithMappedRooms;

    return selectedRoom
      ? `${selectedRoom.nameOverride ?? selectedRoom.name} selected`
      : `${totalWithMappedRooms} ${pluralize(
          'rooms',
          totalWithMappedRooms,
        )} with mapping, ${totalWithoutMappedRooms} ${pluralize('rooms', totalWithoutMappedRooms)} without mapping`;
  }, [selectedPropertyRoom, filteredPropertyRooms]);

  const updates = useMemo(() => {
    const updates: {
      [roomId: string]: Array<MappingChange>;
    } = {};

    for (const update of updatePayload) {
      if (!updates[update.roomId]) {
        updates[update.roomId] = [];
      }
      updates[update.roomId].push({
        supplier: update.supplier,
        externalId: update.supplierRoomId,
        isVerified: update.isVerified,
      });
    }
    return updates;
  }, [updatePayload]);

  const webbedsId = property['webbedsId'];
  const stubaId = property['stubaId'];
  const didaId = property['didaId'];
  const hotelbedsId = property['hotelbedsId'];
  const expediaId = property['externalId'];

  const supplierExternalId = (supplier: Suppliers): string => {
    switch (supplier) {
      case 'hotelbeds':
        return hotelbedsId;
      case 'webbeds':
        return webbedsId;
      case 'stuba':
        return stubaId;
      case 'dida':
        return didaId;
      case 'expedia':
        return expediaId;
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = supplier;
        throw new Error(`Unhandled supplier: ${exhaustiveCheck}`);
    }
  };

  const formatSourceRoom = (mappedRoom, supplier: Suppliers, roomId, includeVerified = false) => {
    return {
      supplier: supplier,
      roomId,
      supplierRoomId: mappedRoom.externalId,
      supplierPropertyId: supplierExternalId(supplier),
      mappingMethod: mappedRoom.mappingMethod?.length ? mappedRoom.mappingMethod : ['manual'],
      ...(includeVerified && { isVerified: mappedRoom.isVerified ?? true }),
    };
  };

  const getSourceRooms = async (): Promise<SourceRoomsBySupplier> => {
    try {
      const response = await bedbankService.getSourceRooms(property.id);
      const prettifiedWebBedsSourceRooms = formatSource(response.result['webbeds'], 'webbeds');
      const prettifiedHotelBedsSourceRooms = formatSource(response.result['hotelbeds'], 'hotelbeds');
      const prettifiedStubaSourceRooms = formatSource(response.result['stuba'], 'stuba');
      const prettifiedDidaSourceRooms = formatSource(response.result['dida'], 'dida');
      const prettifiedExpediaSourceRooms = formatSource(response.result['expedia'], 'expedia');

      const prettifiedData = {
        // sorting ensures rooms with more info are at the top
        hotelbeds: prettifiedHotelBedsSourceRooms.sort(sortSourceRoom),
        webbeds: prettifiedWebBedsSourceRooms.sort(sortSourceRoom),
        stuba: prettifiedStubaSourceRooms.sort(sortSourceRoom),
        dida: prettifiedDidaSourceRooms.sort(sortSourceRoom),
        expedia: prettifiedExpediaSourceRooms.sort(sortSourceRoom),
      };

      return prettifiedData;
    } catch (error) {
      console.error('Error fetching source rooms', error);
      return { hotelbeds: [], webbeds: [], stuba: [], dida: [], expedia: [] };
    }
  };

  const handleSourceRoomSelection = (room: SourceRoom, supplier: Suppliers) => {
    const update = [...selectedSourceRooms];
    const key = `${supplier}-${room.externalId ?? room.id}`;
    if (update.includes(key)) {
      update.splice(update.indexOf(key), 1);
    } else {
      update.push(key);
    }
    setSelectedSourceRooms(update);
  };

  const handlePropertyRoomSelection = (room: PropertyRoom, supplier: Suppliers) => {
    setSelectedPropertyRoom(`${supplier}-${room.externalId}`);
  };

  const updateAutomappingFlagPayload = (
    supplierRoomId: string,
    externalPropertyId: string,
    supplier: Suppliers,
    checked: boolean,
  ) => {
    const newAutomappingFlagPayload = [...automappingFlagPayload];

    const index = newAutomappingFlagPayload.findIndex(
      (flag) => flag.supplierRoomId === supplierRoomId && flag.supplier === supplier,
    );

    if (index !== -1) {
      const existingPayload = newAutomappingFlagPayload[index];

      // if there is an existing payload that has the opposite boolean flag, we should remove the existing payload
      if (existingPayload.isAutoMappingEnabled !== checked) {
        newAutomappingFlagPayload.splice(index, 1);
      }
      // if there is an existing payload that has the same boolean flag, we don't need to add the new payload (or do anything)
    } else {
      newAutomappingFlagPayload.push({
        supplierRoomId: supplierRoomId,
        externalPropertyId: externalPropertyId,
        supplier,
        isAutoMappingEnabled: checked,
      });
    }

    setAutomappingFlagPayload(newAutomappingFlagPayload);

    return newAutomappingFlagPayload;
  };

  const handleSetAutomappingFlags = (
    supplierRoomId: string,
    externalPropertyId: string,
    supplier: Suppliers,
    checked: boolean,
  ) => {
    const newAutomappingFlagPayload = updateAutomappingFlagPayload(
      supplierRoomId,
      externalPropertyId,
      supplier,
      checked,
    );

    handleChange({
      update: updatePayload,
      remove: removePayload,
      automappingFlagUpdate: newAutomappingFlagPayload,
    });
  };

  const handleUpdateMapping = () => {
    // get all selected source rooms
    const nonExpediaMappedRooms: Array<SourceRoom> = selectedSourceRooms
      .map((key) => {
        const [supplier, externalId] = extractKey(key);
        return sourceRooms[supplier].find((sr: SourceRoom) => sr.externalId === externalId);
      })
      .filter(Boolean); // remove any falsy values

    if (nonExpediaMappedRooms.length === 0) {
      enqueueSnackbar('Please select at least one source room', { variant: 'error' });
      return;
    }
    const room = selectedPropertyRoom
      ? propertyRooms.find((r) => r.externalId === extractKey(selectedPropertyRoom)[1])
      : null;

    if (!room) {
      enqueueSnackbar('Please select a room from property rooms', { variant: 'error' });
      return;
    }

    setAppliedButNotSavedRooms([...nonExpediaMappedRooms, ...appliedButNotSavedRooms]);

    // update payload
    const newMappings = nonExpediaMappedRooms.map((sr) => formatSourceRoom(sr, sr.supplier, room.id, true));
    const newUpload = new Set([...updatePayload, ...newMappings]); // using set to ensure duplicates are trimmed
    setUpdatePayload(Array.from(newUpload));
    handleChange({
      update: Array.from(newUpload),
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
    // clear source rooms from selection
    setSelectedSourceRooms([]);
  };

  const handleRemove = (mappedRoom: MappedRoom) => {
    const remove: App.Bedbank.SupplierRoomMappingPayload = [
      ...removePayload,
      formatSourceRoom(mappedRoom, mappedRoom.supplier, mappedRoom.roomId),
    ];
    const newAlreadyMappedRooms = { ...alreadyMappedRooms };
    delete newAlreadyMappedRooms[`${mappedRoom.supplier}-${mappedRoom.externalId}`];
    setAlreadyMappedRooms(newAlreadyMappedRooms);
    setRemovePayload(remove);

    // after removing a mapped room, we need to toggle the source room's automapping flag to false, unless the room's automapping flag is already false
    const newAutomappingFlagPayload = mappedRoom.sourceContent.isAutomappingEnabled
      ? updateAutomappingFlagPayload(mappedRoom.externalId, mappedRoom.externalPropertyId, mappedRoom.supplier, false)
      : automappingFlagPayload;

    handleChange({
      update: updatePayload,
      remove,
      automappingFlagUpdate: newAutomappingFlagPayload,
    });
  };

  const verifyMappedRoomModel = (mappedRoomModel: MappedRoom) => {
    const update = [...updatePayload];
    update.push({
      roomId: mappedRoomModel.roomId,
      supplierRoomId: mappedRoomModel.externalId,
      supplierPropertyId: mappedRoomModel.externalPropertyId,
      supplier: mappedRoomModel.supplier,
      mappingMethod: ['manual'],
      isVerified: true,
    });
    const newAppliedButNotSavedRooms = [...appliedButNotSavedRooms, mappedRoomModel.sourceContent];
    setAppliedButNotSavedRooms(newAppliedButNotSavedRooms);
    setUpdatePayload(update);
    handleChange({
      update,
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
  };

  const fetchData = async () => {
    const alreadyMapped: Record<string, boolean> = {};
    const fetchedSourceRooms = await getSourceRooms();

    setPropertyRooms(
      property.rooms.map((room: Room) => {
        const {
          id,
          name,
          descriptionOverride,
          mappedRooms: mappedRoomsModel,
          supplier,
          externalId,
          status,
          nameOverride,
        } = room;
        const sourceRoom = mappedRoomsModel.find((sr) => sr.externalId === externalId && sr.supplier === supplier);
        return {
          id,
          name,
          nameOverride,
          description: descriptionOverride,
          externalId: sourceRoom?.externalId ?? externalId,
          mappedRooms: mappedRoomsModel
            .map<MappedRoom>((mappedRoom: App.Bedbank.MappedRoom) => {
              alreadyMapped[`${mappedRoom.supplier}-${mappedRoom.externalId}`] = true;
              return {
                ...mappedRoom,
                sourceContent: fetchedSourceRooms[mappedRoom.supplier]?.find(
                  (sp) => sp.externalId === mappedRoom.externalId,
                ),
              };
            })
            .sort((a, b) => sortSourceRoom(a.sourceContent, b.sourceContent)),
          supplier,
          status,
        };
      }),
    );
    setSourceRooms(fetchedSourceRooms);
    setAlreadyMappedRooms(alreadyMapped);
    setIsLoading(false);
  };

  const clearNewMappings = (room: PropertyRoom) => {
    const newMappings = updatePayload.filter((update) => update.roomId !== room.id);
    setUpdatePayload(newMappings);
    // Any applied but not saved rooms that is not in the update payload should be cleared (only ones in the update payload should be kept)
    setAppliedButNotSavedRooms(
      appliedButNotSavedRooms.filter((r) =>
        newMappings.some((mappedRoom) => mappedRoom.supplierRoomId === r.externalId),
      ),
    );
    handleChange({
      update: newMappings,
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
  };

  useEffect(() => {
    async function firstLoad() {
      setIsLoading(true);
      await fetchData();
    }
    firstLoad();
  }, []);

  return (
    <Container maxWidth="xl" disableGutters>
      <Stack direction="row" spacing={2} mb={2} justifyContent="space-between">
        <Typography variant="h6" display="flex" justifyContent="space-evenly" width="100%">
          {updatePayload.length + automappingFlagPayload.length > 0 && (
            <Alert color="success">{updatePayload.length + automappingFlagPayload.length} changes to apply</Alert>
          )}
          {removePayload.length > 0 && <Alert color="error">{removePayload.length} changes to apply</Alert>}
          {updatePayload.length === 0 &&
            removePayload.length === 0 &&
            automappingFlagPayload.length === 0 &&
            'Map Source Rooms to Property Rooms'}
        </Typography>
      </Stack>
      {isLoading && (
        <Box m={2}>
          <LinearProgress />
        </Box>
      )}
      <Box display="grid" gridTemplateColumns="480px 1fr" gap={3}>
        {/* Container for Suppliers */}
        <Box position="relative">
          <Stack direction="column" gap={2} position="sticky" top={0} overflow="auto" height="calc(100dvh - 56px)">
            <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h6">Source Rooms: {selectedFromSourceRooms}</Typography>
                <Stack direction="column" gap={2}>
                  <TextField
                    fullWidth
                    label="Filter by room name:"
                    value={supplierFilteringText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={resetSupplierFilteringText}
                            disabled={supplierFilteringText.length < 1}
                            title="clear"
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onInput={handleSupplierFilteringTextInput}
                  />
                  <div>
                    <Button
                      variant="contained"
                      startIcon={<AccountTreeIcon />}
                      title="Force decouple"
                      onClick={() =>
                        onForceDecoupleRoomsClick(
                          selectedSourceRooms
                            .map((key) => {
                              const [supplier, externalId] = extractKey(key);
                              return sourceRooms[supplier].find((sr: SourceRoom) => sr.externalId === externalId)?.id;
                            })
                            .filter(Boolean),
                        )
                      }
                      disabled={!selectedSourceRooms.length}
                    >
                      Force decouple
                    </Button>
                    <Tooltip
                      title="Availability score is a measure of how often a room has available rates"
                      placement="right-end"
                    >
                      <FormControlLabel
                        checked={hideRoomsWithNoAvailabilityScore}
                        onChange={toggleHideRoomsWithNoAvailabilityScore}
                        control={<Checkbox />}
                        label="Hide rooms with no availability score"
                      />
                    </Tooltip>
                  </div>
                  <Divider />
                </Stack>
              </Stack>
            </Paper>
            <Stack direction="column" gap={2}>
              {unmappedFilteredSourceRooms?.map(
                (room, ind) =>
                  !alreadyMappedRooms[`${room.supplier}-${room.externalId}`] && (
                    <SourceRoomElement
                      key={`${room.supplier}-${room.externalId}-${ind}`}
                      room={room}
                      onSelect={handleSourceRoomSelection}
                      isSelected={selectedSourceRooms.includes(`${room.supplier}-${room.externalId}`)}
                      isAutomappingFlagChanged={automappingFlagPayload.some(
                        (payload) => payload.supplierRoomId === room.externalId && payload.supplier === 'hotelbeds',
                      )}
                      handleSetAutomappingFlags={handleSetAutomappingFlags}
                      supplier={room.supplier}
                    />
                  ),
              )}
              {supplierFilteringText && unmappedFilteredSourceRooms.length === 0 && (
                <Typography>No rooms found based on filter</Typography>
              )}
            </Stack>
          </Stack>
        </Box>
        {/* Container for LE/Expedia Rooms */}
        <Stack direction="column" gap={2}>
          <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
            <Stack direction="column" gap={2}>
              <Typography variant="h6">Property Rooms: {selectedFromPropertyRooms}</Typography>
              <TextField
                label="Filter by room name, room ID, or mapped room ID:"
                value={filteringText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={resetFilteringText}
                        disabled={filteringText.length < 1}
                        title="clear"
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onInput={handleFilteringTextInput}
              />
              <Button
                variant="contained"
                startIcon={<AccountTreeIcon />}
                title="Map selected source rooms to the selected Room"
                onClick={handleUpdateMapping}
                disabled={selectedSourceRooms.length === 0 || !selectedPropertyRoom}
              >
                Apply selected Mapping
              </Button>
              <Divider />
            </Stack>
          </Paper>
          <Stack direction="column" gap={2}>
            {filteredPropertyRooms?.map((room) => (
              <ControlRoomElement
                key={room.id}
                room={room}
                isSelected={selectedPropertyRoom === `${room.supplier}-${room.externalId}`}
                onSelect={handlePropertyRoomSelection}
                onRemoveMappedRoom={handleRemove}
                verifyMappedRoomModel={verifyMappedRoomModel}
                newMappings={updates[room.id] ?? []}
                appliedButNotSavedRooms={appliedButNotSavedRooms}
                clearNewMappings={() => clearNewMappings(room)}
                removePayload={removePayload}
                automappingFlagPayload={automappingFlagPayload}
                handleSetAutomappingFlags={handleSetAutomappingFlags}
              />
            ))}
            {filteringText && filteredPropertyRooms.length === 0 && (
              <Typography>No rooms found based on filter</Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

export default BedbankRoomMapping;
