import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import Spinner from '~/components/Common/Spinner';

import {
  COMMISSION_RULE_CONDITION_TYPES,
  CommissionsConditionTypes,
  INITIAL_COMMISSION_CONDITIONS,
  PRODUCT_TYPES_OPTIONS,
  RuleCondition,
  RuleConditions,
} from '~/consts/agentHub';

import useCurrentBrand from '~/hooks/useCurrentBrand';

import { createCommission, getAgenciesById, updateCommission } from '~/services/AgentHub/AgentService';
import { Dayjs, formatDateLongISO, formatDateSlashes, toDayJs } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import { Commission } from '~/types/services/agentHub';

import { buildRuleParam, getSortedRegions, groupByValue } from '~/utils/commission';

import { AddNewConditionForm } from './AddNewConditionForm';

interface Props {
  data?: Commission;
  isOpen: boolean;
  onClose: () => void;
  listCommissions: () => void;
  mode: 'create' | 'edit';
}

type InputOptionFunc = (data) => { value: string; label: string };

type CommissionTypeMapping = Record<CommissionsConditionTypes, InputOptionFunc>;

type CommissionEditHistory = {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
};

export default function CommissionDialog({ data, onClose, isOpen, listCommissions, mode }: Props) {
  const [createdConditions, setCreatedConditions] = useState<RuleConditions>(INITIAL_COMMISSION_CONDITIONS);
  const [regions, setRegions] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [commissionEditHistory, setCommissionEditHistory] = useState<CommissionEditHistory | null>(null);
  const [isLoadingCommissionEditHistory, setIsLoadingCommissionEditHistory] = useState<boolean>(false);
  const [isLoadingConditions, setIsLoadingConditions] = useState<boolean>(false);
  const commissionFormRef = useRef<HTMLFormElement>(null);
  const [visibleConditions, setVisibleConditions] = useState<Set<CommissionsConditionTypes>>(new Set());
  const { enqueueSnackbar } = useSnackbar();
  const brand = useCurrentBrand();
  const groupedProductedTypes = useMemo(() => {
    return Object.values(PRODUCT_TYPES_OPTIONS).reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});
  }, []);
  const totalConditions = useMemo(() => visibleConditions.size, [visibleConditions]);
  const remainingConditions = COMMISSION_RULE_CONDITION_TYPES.filter((cond) => !visibleConditions.has(cond));
  const groupedRuleType: CommissionTypeMapping = useMemo(
    () => ({
      productType: (data) => ({ value: data, label: groupedProductedTypes[data] }),
      location: (data) => ({ value: data, label: data }),
      agency: (data) => ({ value: data, label: data }),
      agent: (data) => ({ value: data, label: data }),
      offer: (data) => ({ value: data, label: data }),
      vendor: (data) => ({ value: data, label: data }),
      affiliation: (data) => ({ value: data, label: data }),
      margin: (data) => ({ value: data, label: data }),
      tier: (data) => ({ value: data, label: data }),
    }),
    [groupedProductedTypes],
  );
  const isCreate = mode === 'create';
  const isEdit = mode === 'edit';
  const textTransformNone = { textTransform: 'none' };

  const fetchAgencies = useCallback(async (conditionsObject: RuleConditions) => {
    const agencies = await getAgenciesById(conditionsObject.agency.map((agency) => agency.value as string));
    const updatedAgencies = {
      agency: agencies.map((agency) => ({ value: agency.id, label: agency.name })),
    };

    return updatedAgencies;
  }, []);

  const fetchAgents = useCallback(
    async (conditionsObject: RuleConditions) => {
      const agents = await UsersService.getUsersSummaryByIds(
        conditionsObject.agent.map((agent) => agent.value),
        brand,
      );
      const agentsArray = Array.from(agents.values());
      const updatedAgents = {
        agent: agentsArray.map((agent) => ({ value: agent.id_member, label: `${agent.full_name} [${agent.email}]` })),
      };
      return updatedAgents;
    },
    [brand],
  );

  const fetchAndSetFields = async () => {
    setIsLoadingConditions(true);
    const conditionsObject = Object.entries(data.rules).reduce((acc, [key, rules]: [string, Array<string>]) => {
      return {
        ...acc,
        [key]: rules.map((rule) => groupedRuleType[key](rule)),
      };
    }, {} as RuleConditions);

    const objectsToSet = await Promise.all([
      conditionsObject.agent?.length > 0 && fetchAgents(conditionsObject),
      conditionsObject.agency?.length > 0 && fetchAgencies(conditionsObject),
    ]);

    const updatedObjects = objectsToSet.reduce((acc, curr) => ({ ...acc, ...(curr || {}) }), {});
    setCreatedConditions({ ...conditionsObject, ...updatedObjects });
    setIsLoadingConditions(false);
    setVisibleConditions(
      new Set(Object.keys({ ...conditionsObject, ...updatedObjects }) as CommissionsConditionTypes[]),
    );
  };

  useEffect(() => {
    if (!data || !isOpen) {
      return;
    }
    setRegions(data.regions);
    if (data.startDate) {
      setStartDate(toDayJs(data.startDate));
    }

    if (data.endDate) {
      setEndDate(toDayJs(data.endDate));
    } else {
      setEndDate(null);
    }
    fetchAndSetFields();
  }, [data, groupedRuleType, isOpen]);

  function addCondition(newCondition: RuleCondition, replace = false) {
    setCreatedConditions((createdConditions) => {
      const conditions = createdConditions[newCondition.type];
      if (!conditions) {
        return { ...createdConditions, [newCondition.type]: [...newCondition.value] };
      }
      const currentConditions = replace ? [] : conditions.reduce(groupByValue, new Map());
      const newConditions = newCondition.value.reduce(groupByValue, new Map());
      const mergedConditions = Array.from(new Map([...currentConditions, ...newConditions]).entries());
      const newConditionsForType = mergedConditions.map(([value, label]) => ({ value, label }));
      return {
        ...createdConditions,
        [newCondition.type]: newConditionsForType,
      };
    });
  }

  function handleChangeCondition(prevType: CommissionsConditionTypes, type: CommissionsConditionTypes) {
    setCreatedConditions((createdConditions) => {
      return {
        ...createdConditions,
        [type]: [],
        [prevType]: [],
      };
    });
    setVisibleConditions((setConditions) => {
      setConditions.delete(prevType);
      setConditions.add(type);
      return new Set(setConditions);
    });
  }

  function handleClearCondition(type: string) {
    setCreatedConditions((createdConditions) => {
      return {
        ...createdConditions,
        [type]: [],
      };
    });
    setVisibleConditions((setConditions) => {
      setConditions.delete(type as CommissionsConditionTypes);
      return new Set(setConditions);
    });
  }

  function handleAppendCondition(type: CommissionsConditionTypes) {
    setCreatedConditions((createdConditions) => {
      return {
        ...createdConditions,
        [type]: [],
      };
    });
    setVisibleConditions((setConditions) => {
      setConditions.add(type);
      return new Set(setConditions);
    });
  }

  function handleStartDateChange(date: Dayjs) {
    setStartDate(date);
  }

  function handleEndDateChange(date: Dayjs) {
    setEndDate(date);
  }

  function resetForm() {
    setStartDate(null);
    setEndDate(null);
    setRegions([]);
    setCreatedConditions(INITIAL_COMMISSION_CONDITIONS);
    setVisibleConditions(new Set());
    setCommissionEditHistory(null);
    setIsLoadingCommissionEditHistory(false);
    commissionFormRef.current?.reset();
  }

  async function handleSaveCommission(event: React.FormEvent<HTMLFormElement>, id: string, conditions: RuleConditions) {
    event.preventDefault();

    const isConditionsEmpty = Object.values(createdConditions).every((condition) => condition.length === 0);

    if (isConditionsEmpty) {
      enqueueSnackbar('Please add at least one condition', { variant: 'error' });
      return;
    }

    try {
      const formData = new FormData(event.currentTarget);
      const regionsForm = formData?.get('regions')?.toString();
      const regions = regionsForm === '' ? [] : regionsForm.split(',');
      const formObj = {
        id,
        rules: buildRuleParam(conditions),
        description: formData.get('description') as string,
        startDate: startDate ? formatDateLongISO(startDate.toDate()) : formatDateLongISO(new Date()),
        endDate: endDate ? formatDateLongISO(endDate.toDate()) : undefined,
        regions: regions,
        discountType: formData.get('discountType') as string,
        discountAmount: parseInt(formData.get('discountAmount') as string),
        commissionType: formData.get('discountType') as string,
        commissionPercentage: parseInt(formData.get('discountAmount') as string),
        type: formData.get('discountType') as string,
      };
      isCreate ? await createCommission(formObj) : await updateCommission(formObj.id, formObj);
      enqueueSnackbar(isCreate ? 'Commission rule created' : 'Commission rule updated', { variant: 'success' });
      listCommissions();
      resetForm();
      onClose();
    } catch (err) {
      enqueueSnackbar(err?.message || 'Unknown error', { variant: 'error' });
    }
  }

  useEffect(() => {
    if (isEdit && data) {
      setIsLoadingCommissionEditHistory(true);
      Promise.all([UsersService.getUser(data.createdBy), data.updatedBy ? UsersService.getUser(data.updatedBy) : null])
        .then(([createdBy, updatedBy]) => {
          setCommissionEditHistory({
            createdBy: createdBy?.fullName,
            createdAt: formatDateSlashes(data.createdAt),
            updatedBy: updatedBy ? updatedBy?.fullName : '',
            updatedAt: data.updatedAt ? formatDateSlashes(data.updatedAt) : '',
          });
        })
        .catch(() => {
          setCommissionEditHistory(null);
        })
        .finally(() => {
          setIsLoadingCommissionEditHistory(false);
        });
    }

    return () => {
      setCommissionEditHistory(null);
    };
  }, [data, mode]);

  function onCloseModal() {
    resetForm();
    onClose();
  }

  return (
    <Dialog open={isOpen} fullWidth onClose={onCloseModal}>
      <DialogTitle>{isCreate ? 'Create commission rule' : `Edit commission rule "${data?.description}"`}</DialogTitle>
      <form ref={commissionFormRef} onSubmit={(event) => handleSaveCommission(event, data?.id, createdConditions)}>
        <Stack padding={2} direction="column" spacing={2}>
          <Stack direction="row" gap={2}>
            <TextField name="description" defaultValue={data?.description} label="Description" required fullWidth />
          </Stack>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <TextField defaultValue={data?.type} name="discountType" select label="Commission type" required fullWidth>
              <MenuItem value="base">Base</MenuItem>
              <MenuItem value="additional">Additional</MenuItem>
            </TextField>

            <TextField
              name="discountAmount"
              InputProps={{ inputProps: { min: 1 } }}
              label="Commission percentage"
              defaultValue={data?.commissionPercentage}
              type="number"
              required
              fullWidth
            />
          </Stack>

          <Stack direction="row" gap={2}>
            <Stack flex={1}>
              <DatePicker
                value={startDate}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY"
                name="startDate"
                label="Start date"
              />
            </Stack>
            <Stack flex={1}>
              <DatePicker
                value={endDate}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disablePast
                name="endDate"
                label="End date"
              />
            </Stack>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="agent-hub-commissions-regions-input-label">Regions</InputLabel>
              <Select
                name="regions"
                labelId="agent-hub-commissions-regions-input-label"
                multiple
                value={regions}
                input={<OutlinedInput label="Regions" />}
                renderValue={(selected: Array<string>) => selected.join(', ')}
                onChange={(event) => {
                  setRegions(event.target.value as Array<string>);
                }}
              >
                {getSortedRegions().map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    <Checkbox checked={regions.includes(region.code)} />
                    <ListItemText primary={region.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Divider />

          <Stack spacing={2}>
            <Typography variant="button" color="secondary">
              Conditions:
            </Typography>

            {isLoadingConditions && <Spinner size={14} />}
            <Stack direction="column" spacing={3}>
              {Array.from(visibleConditions).map((key) => {
                const value = createdConditions[key];
                return (
                  <React.Fragment key={key}>
                    <Stack justifyContent="space-between" key={key} direction="row" spacing={2}>
                      <Box flex={1}>
                        <AddNewConditionForm
                          conditionType={key}
                          ruleConditions={value}
                          addCondition={addCondition}
                          visibleConditions={visibleConditions}
                          handleChangeCondition={handleChangeCondition}
                        />
                      </Box>
                      <DeleteIcon onClick={() => handleClearCondition(key)} />
                    </Stack>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>

          {totalConditions < COMMISSION_RULE_CONDITION_TYPES.length && (
            <Stack direction="row" justifyContent="flex-start" spacing={2}>
              <Button
                size="small"
                variant="text"
                sx={textTransformNone}
                onClick={() => {
                  handleAppendCondition(remainingConditions[0] as CommissionsConditionTypes);
                }}
              >
                <Add /> Add condition
              </Button>
            </Stack>
          )}

          {isLoadingCommissionEditHistory && <Spinner size={14} />}

          {commissionEditHistory && (
            <Stack>
              <Typography variant="caption" color="secondary">
                Created By: {commissionEditHistory?.createdBy} at {commissionEditHistory?.createdAt}
              </Typography>
              {commissionEditHistory.updatedBy && (
                <Typography variant="caption" color="secondary">
                  Edited By: {commissionEditHistory?.updatedBy} at {commissionEditHistory?.updatedAt}
                </Typography>
              )}
            </Stack>
          )}

          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button sx={textTransformNone} type="button" size="medium" variant="text" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button sx={textTransformNone} type="submit" size="medium" variant="contained">
              {isCreate ? 'Create rule' : 'Save changes'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
