import React, { useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import * as ES from '~/services/ExperiencesService';

import ExperienceItem from './item';

interface ExperienceListProps {
  tenant: App.Tenant;
  initialCount?: number;
  hasAllowedRefund: boolean;
  experiences: Array<App.ExperienceItem>;
  showRefundModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem: App.ExperienceItem) => void;
  orderBrand: string;
  accommodationItems: Array<App.OrderItem>;
}

export default function ExperiencesList({
  tenant,
  experiences,
  initialCount = 1,
  showRefundModal,
  hasAllowedRefund,
  orderBrand,
  accommodationItems,
}: ExperienceListProps) {
  const [offers, setOffers] = useState<ES.ExperienceOffers | null>([]);
  const [bookingDetails, setBookingDetails] = useState<Array<ES.BookingDetails> | null>([]);

  const getOfferById = useCallback(
    async (ids: Array<string>) => {
      const expOffers: ES.ExperienceOffers = [];
      for (const expId of ids) {
        const res = await ES.getExperienceOfferById({
          id: expId,
          brand: tenant.brand,
          curationData: true,
        });
        if (!res?.errors) {
          expOffers.push(res?.result);
        }
      }
      if (expOffers.length > 0) {
        setOffers(expOffers);
      }
    },
    [tenant.brand],
  );

  const matchOfferWithItem = (expItemId?: string): ES.ExperienceOffer | null => {
    if (!offers || offers?.length === 0 || !expItemId) {
      return null;
    }

    return offers.find((expOffer) => expOffer.id === expItemId) ?? null;
  };

  const matchBookingDetailsWithItem = (expItemId: string) => {
    return bookingDetails?.find((booking) => booking.experienceItemId === expItemId);
  };

  const getBookingDetails = async (expItemIds: string) => {
    const bookingDetails = await ES.getBookingDetails(expItemIds);
    setBookingDetails(bookingDetails?.result as Array<ES.BookingDetails>);
  };

  const getParentAccommodationBookingNumber = useCallback(
    (parentId?: string) => {
      if (!parentId) {
        return undefined;
      }

      const accommodationItem = accommodationItems.find((item) => item.id === parentId);
      return accommodationItem?.booking_number;
    },
    [accommodationItems],
  );

  useEffect(() => {
    const mapExperiencesIds = experiences.map((exp) => exp.provider_offer_id).filter((id) => id !== undefined);
    const noDuplicatedIds = [...new Set(mapExperiencesIds)];
    getOfferById(noDuplicatedIds);

    const experienceItemIDs = [...new Set(experiences.map((exp) => exp.id))].join(',');
    getBookingDetails(experienceItemIDs);
  }, [experiences, getOfferById]);

  return (
    <Stack direction="column" gap={2}>
      {experiences.map((expItem, i) => (
        <ExperienceItem
          key={expItem.id}
          count={initialCount + i}
          experienceItem={expItem}
          showRefundModal={showRefundModal}
          hasAllowedRefund={hasAllowedRefund}
          experienceOffer={matchOfferWithItem(expItem.provider_offer_id)}
          orderBrand={orderBrand}
          experienceBookingDetail={matchBookingDetailsWithItem(expItem.id)}
          parentAccommodationBookingNumber={getParentAccommodationBookingNumber(expItem.accommodation_parent_item_id)}
        />
      ))}
    </Stack>
  );
}
