import React, { useState } from 'react';

import { Alert, Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { SpoofUrlParts, constructSearchSpoofUrl } from '~/utils/url-formatter';

interface ViewLinkWithSpoofDialogProps {
  isOpen: boolean;
  onClose: () => void;
  baseUrl: string;
  spoofData: SpoofUrlParts;
  email: string;
  type: 'cart' | 'offer';
}

export function ViewLinkWithSpoofDialog(params: ViewLinkWithSpoofDialogProps) {
  const { isOpen, onClose, baseUrl, spoofData, email, type } = params;
  const [spoofUrl, setSpoofUrl] = useState<string | null>(null);
  const { isToggled: isSpoofDialogOpen, toggle: toggleSpoofDialog } = useToggleState(false);

  const handleViewClick = () => {
    window.open(baseUrl, '_blank', 'noopener,noreferrer');
  };

  const handleViewWithSpoofClick = () => {
    const url = constructSearchSpoofUrl(spoofData, baseUrl);
    navigator.clipboard.writeText(url);
    setSpoofUrl(url);
    toggleSpoofDialog();
  };

  const handleClose = () => {
    setSpoofUrl(null);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen && !isSpoofDialogOpen} onClose={handleClose}>
        <DialogTitle>View {type}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ my: 2 }}>
            <Button variant="contained" onClick={handleViewClick}>
              View {type}
            </Button>
            <Button variant="contained" onClick={handleViewWithSpoofClick}>
              View {type} while spoofing
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {isSpoofDialogOpen && (
        <Dialog open={isSpoofDialogOpen} onClose={handleClose}>
          <DialogContent>
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
              <Alert severity="warning">Extreme caution, paste into incognito only</Alert>
              <Alert severity="info">Your link has been copied to the clipboard!</Alert>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={`Spoofing link for ${email}`}
                defaultValue={spoofUrl}
                multiline
                disabled
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
