import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'clsx';
import { useSnackbar } from 'notistack';
import { MarkdownPreview } from 'react-marked-markdown';

import VerifiedUserIcon from '@mui/icons-material/AccountBox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BedIcon from '@mui/icons-material/Bed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/ListAlt';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Order } from '@luxuryescapes/contract-svc-order';
import {
  buildCancellationPolicies,
  buildPartiallyRefundableCancellationPolicies,
  formatDate,
} from '@luxuryescapes/lib-refunds';

import OrderTag from '~/components/Common/OrderTag';
import RestrictedComponent from '~/components/Common/RestrictedComponent';
import Promotions from '~/components/Purchases/OrderDetail/BedbankList/Promotions';
import CancellationPolicyWarningModal from '~/components/Refund/CancellationPolicyWarningModal';
import LPPRefundV2 from '~/components/Refund/RefundV2/LPPRefundV2';

import { LE_BUSINESS_TRAVELLER } from '~/consts/businessTraveller';
import { LUX_PLUS } from '~/consts/membership';
import { PAYMENT_METHODS_NOT_ALLOWED_REFUND } from '~/consts/payment';
import { ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useCurrentUser from '~/hooks/useCurrentUser';

import { getRefundRequestsByOrderItemId } from '~/services/OrdersService';
import { datesHaveSameDayMonthYear, formatDateOrdinalShortMonth, isAfter } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';
import { humanize } from '~/utils/humanize';

import OrderItemCredit from '../BusinessTraveller/OrderItemBusinessCredit';
// import InsuranceLetter from '../OrderItem/InsuranceLetter';
import TemplateForCSButton from '../OrderItem/TemplateForCSButton';
import TemplateForCSModal from '../OrderItem/TemplateForCSModal';
import { formatChildrenAges } from '../OrderItem/utils';

import CancellationPolicies from './CancellationPolicies';
import Inclusions from './Inclusions';

function formattedDate(cell) {
  const date = new Date(cell);

  return formatDateOrdinalShortMonth(date);
}

function isSameOrAfter(date1) {
  const now = new Date();
  return datesHaveSameDayMonthYear(now, date1) || isAfter(now, date1);
}

function allowedAutomaticRefund({ order, room, item, cancellationPolicies, hasAllowedRefund, propertyTimezone }) {
  if (!hasAllowedRefund) {
    return false;
  }

  if (!room.refundable && !room.partially_refundable) {
    return false;
  }

  if (order.payments.some((payment) => PAYMENT_METHODS_NOT_ALLOWED_REFUND.includes(payment.type))) {
    return false;
  }

  if (
    cancellationPolicies.policies.length &&
    isSameOrAfter(formatDate.convertDateByTimezone(cancellationPolicies.policies[0]?.start, propertyTimezone))
  ) {
    return false;
  }

  if (isSameOrAfter(item.check_in)) {
    return false;
  }

  return room.status === 'booked';
}

interface Props {
  count: number;
  item: Order.BedbankItem;
  room: Order.BedbankItemRoom;
  order: Order.Order;
  bedbankRoomInfo: {
    status: string;
  };
  currencyCode: string;
  propertyTimezone: string;
  regionCode: string;
  offerUrl: string;
  hasAllowedRefund: boolean;
  disableInteraction: boolean;
  promotions: Array<Order.BedbankOfferPromotion>;
  showRefundModal: (props: { itemId: string; roomId?: string; auto?: boolean; itemType?: string }) => void;
  enqueueSnackbar: (message: string, options: { variant: 'error' }) => void;
  shouldShowCancellationWarning: boolean;
  refundPolicy?: App.Bedbank.RefundPolicies;
}

export default function OrderItem({
  count,
  item,
  room,
  order,
  bedbankRoomInfo,
  currencyCode,
  propertyTimezone,
  regionCode,
  offerUrl,
  hasAllowedRefund,
  disableInteraction,
  showRefundModal,
  promotions,
  shouldShowCancellationWarning,
  refundPolicy,
}: Props) {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [refundRequest, setRefundRequest] = useState<RefundRequest>(undefined);
  const [refundApprover, setRefundApprover] = useState<string>(undefined);
  const [isCancellationWarningModalOpen, setIsCancellationWarningModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useCurrentTenant();
  const { user } = useCurrentUser();
  const totalBusinessCreditEarned = order.business_credit_items.reduce((accum, item) => accum + item.total, 0);
  const isVendorLinkRestricted = useMemo(() => {
    return user.roles.includes(ROLE_ICS_STORE_TEAM);
  }, [user]);
  const bookingDetails = {
    'Name:': order.customer_full_name,
    'LE Booking Number:': item.booking_number,
    'Dates:': `${formattedDate(item.check_in)} - ${formattedDate(item.check_out)}`,
    'Email:': order.customer_email,
    'Parent Booking Number:': item.parent_booking_number,
    'Package Type:': item.offer.room.name,
    'Property Name:': item.offer.name,
  };
  const classes = classNames('order-item', {
    cancelled: room.status === 'cancelled',
  });

  const cancellationPolicies = room.partially_refundable
    ? buildPartiallyRefundableCancellationPolicies(
        {
          checkIn: item.check_in,
          checkOut: item.check_out,
          policies: room.cancellation_policies,
          nonRefundableDates: room.nonrefundable_dateranges,
        },
        { timezone: propertyTimezone },
      )
    : buildCancellationPolicies(room.cancellation_policies, {
        regionCode,
        currencyCode,
        timezone: propertyTimezone,
      });

  const supplierPolicy = refundPolicy?.supplierPolicy;
  let supplierCancellationPolicies = undefined;
  if (supplierPolicy) {
    supplierCancellationPolicies = supplierPolicy.partiallyRefundable
      ? buildPartiallyRefundableCancellationPolicies(
          {
            checkIn: item.check_in,
            checkOut: item.check_out,
            policies: supplierPolicy.cancellationPolicies,
            nonRefundableDates: supplierPolicy.nonRefundableDateRanges,
          },
          { timezone: propertyTimezone },
        )
      : buildCancellationPolicies(supplierPolicy.cancellationPolicies, {
          regionCode,
          currencyCode,
          timezone: propertyTimezone,
        });
  }

  const hasAllowedAutomaticRefund = allowedAutomaticRefund({
    order,
    item,
    room,
    cancellationPolicies,
    hasAllowedRefund,
    propertyTimezone,
  });

  const mergedFacilities = room.facilities.concat(room.promotions);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  const onRefundV2Open = () => {
    if (shouldShowCancellationWarning) {
      setIsCancellationWarningModalOpen(true);
    } else {
      toggleShowRefundV2Modal();
    }
  };

  const toggleShowRefundV2Modal = () => {
    setIsRefundModalOpen(!isRefundModalOpen);
  };

  const updateRefundApprover = (newRefundApprover) => {
    setRefundApprover(newRefundApprover);
  };

  useEffect(() => {
    if (order.id_orders && item.id) {
      getRefundRequestsByOrderItemId(order.id_orders, item.id)
        .then((response) => {
          setRefundRequest(response);
        })
        .catch((err) => {
          enqueueSnackbar('Error! Unable fetch appropriate refund request.', {
            variant: 'error',
          });
        });
    }
  }, [enqueueSnackbar, item.id, order.id_orders]);

  return (
    <Box className={classes}>
      <Grid container>
        <Grid xs={6}>
          <Stack direction="column">
            <Stack direction="row" spacing={2}>
              <Box>
                <Typography className="count">{count}</Typography>
              </Box>
              <Box flexGrow={1}>
                <Box className="item-header">
                  <Box className="item-name data-hj-whitelist">
                    {room.name}{' '}
                    {!room.is_verified && (
                      <Typography component="span" color="error" fontWeight={500}>
                        (Unverified)
                      </Typography>
                    )}
                  </Box>
                  <Box className="item-info data-hj-whitelist">ID: {room.id}</Box>
                  <OrderTag items={order.bedbank_items} item={item} />
                </Box>
              </Box>
            </Stack>
            <Box flexGrow={1} pl={4}>
              <Box className="item-reservation T-HasReservation">
                <Stack spacing={1}>
                  {room.confirmation_id && (
                    <Stack direction="row" spacing={1}>
                      <CheckIcon />
                      <span>{room.confirmation_id}</span>
                    </Stack>
                  )}
                  <Stack direction="row" spacing={1}>
                    <VerifiedUserIcon />
                    <span className="data-hj-whitelist">{room.number_of_adults} adults</span>
                    <span className="data-hj-whitelist">
                      {room.number_of_children} children{' '}
                      {room.children_ages.length > 0 ? formatChildrenAges(room.children_ages) : ''}
                    </span>
                    <span className="data-hj-whitelist">{room.number_of_infants || 0} infants</span>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <ListIcon />
                    <span>
                      {room.guest_first_name} {room.guest_last_name}
                    </span>
                  </Stack>

                  {count === 1 && (
                    <>
                      <Stack direction="row" spacing={1}>
                        <MailIcon />
                        <span>{item.booking_email}</span>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <SmartphoneIcon />
                        <span>{item.booking_phone}</span>
                      </Stack>
                    </>
                  )}

                  <Stack direction="row" spacing={1}>
                    <CalendarMonthIcon />
                    <span className="T-ReservationDate data-hj-whitelist">{formattedDate(item.check_in)}</span>
                    <span>-</span>
                    <span>{formattedDate(item.check_out)}</span>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <CalendarMonthIcon />
                    <span>{item.nights} Night(s)</span>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <HomeIcon />
                    {isVendorLinkRestricted && <Typography>{item.offer.name}</Typography>}
                    {!isVendorLinkRestricted && (
                      <Link href={offerUrl} target="_blank" rel="noreferrer" underline="hover">
                        {item.offer.name}
                      </Link>
                    )}
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <BedIcon />
                    <span>{room.bed_group.description}</span>
                  </Stack>

                  {room.guest_special_requests && (
                    <Stack spacing={1}>
                      <Box className="section-header">Special requests</Box>
                      <p>{room.guest_special_requests}</p>
                    </Stack>
                  )}

                  {promotions.length > 0 && (
                    <>
                      <Stack direction="row" spacing={1}>
                        <AttachMoneyIcon />
                        <span>Special Promotions</span>
                      </Stack>
                      <Stack spacing={1}>
                        <Promotions promotions={promotions} />
                      </Stack>
                      <Stack spacing={1}>
                        <Box className="section-header">Inclusions</Box>
                        <MarkdownPreview value={promotions[0].rate_inclusions_long} />
                      </Stack>
                    </>
                  )}

                  {mergedFacilities.length > 0 && !promotions.length && (
                    <Box>
                      <Typography pt={1} fontWeight={500}>
                        Inclusions
                      </Typography>
                      <Inclusions facilities={mergedFacilities} />
                    </Box>
                  )}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid xs={6}>
          <Grid container justifyContent="space-between">
            <Grid xs={4}>
              <>
                {!room.rebooked && (
                  <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                    <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                      Cost: {currencyFormatter(currencyCode, room.cost_price, 2)}
                    </Typography>
                    <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                      Current Margin: {currencyFormatter(currencyCode, room.price - room.cost_price, 2)} (
                      {(((room.price - room.cost_price) / room.price) * 100).toFixed(2)}%)
                    </Typography>
                    {order.business_id !== null && (
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Business Credits: {currencyFormatter(currencyCode, totalBusinessCreditEarned)}
                      </Typography>
                    )}
                    <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                      Sell Price: {currencyFormatter(currencyCode, room.price, 2)}
                    </Typography>
                    {!!room.lux_plus_price && (
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        {LUX_PLUS.PROGRAM_NAME} Price: {currencyFormatter(currencyCode, room.lux_plus_price, 2)}
                      </Typography>
                    )}
                    <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                      {/* @dev For orders pre-LP+ for LPP feature rollout, public_price returns as zero. As there is no lux_plus_price in those cases sell price is public price */}
                      Public Price: {currencyFormatter(currencyCode, room.public_price || room.price, 2)}
                    </Typography>
                  </Box>
                )}

                {room.rebooked && (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                      <Typography pt={1} fontWeight={500} sx={{ display: 'flex', justifyContent: 'end' }}>
                        Original
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Cost: {currencyFormatter(currencyCode, room.rebooked.parent_cost_price)}
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Margin: {currencyFormatter(currencyCode, room.rebooked.parent_marketing_fee)} (
                        {((room.rebooked.parent_marketing_fee / room.rebooked.parent_price) * 100).toFixed(2)}%)
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Sell Price: {currencyFormatter(currencyCode, room.rebooked.parent_price)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                      <Typography pt={1} fontWeight={500} sx={{ display: 'flex', justifyContent: 'end' }}>
                        Rebooked
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Cost: {currencyFormatter(currencyCode, room.rebooked.cost_price)}
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Margin: {currencyFormatter(currencyCode, room.rebooked.parent_price - room.rebooked.cost_price)}{' '}
                        (
                        {(
                          ((room.rebooked.parent_price - room.rebooked.cost_price) / room.rebooked.parent_price) *
                          100
                        ).toFixed(2)}
                        %)
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                        Incremental Margin:{' '}
                        {currencyFormatter(
                          currencyCode,
                          room.rebooked.parent_price - room.rebooked.cost_price - room.rebooked.parent_marketing_fee,
                        )}{' '}
                        (
                        {(
                          ((room.rebooked.parent_price -
                            room.rebooked.cost_price -
                            room.rebooked.parent_marketing_fee) /
                            room.rebooked.parent_price) *
                          100
                        ).toFixed(2)}
                        %)
                      </Typography>
                    </Box>
                  </>
                )}

                {tenant.brand === LE_BUSINESS_TRAVELLER && (
                  <OrderItemCredit
                    orderId={item.fk_order_id}
                    orderItemId={item.id}
                    customerId={order.fk_customer_id}
                    location={item.offer.name}
                  />
                )}
              </>
            </Grid>
            <Grid xs={4}>
              <Box className="status-column">
                <Typography>{item.status === 'cancelled' ? 'Cancelled' : humanize(room.status)}</Typography>
                <Typography>
                  {bedbankRoomInfo?.status ? `Supplier status: ${humanize(bedbankRoomInfo.status)}` : null}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box className="refund-column">
                <Stack direction="column" spacing={0.5} alignItems="end">
                  <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
                    {hasAllowedRefund && (
                      <>
                        <Button onClick={onRefundV2Open}>Issue Refund</Button>
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            showRefundModal({ itemId: item.id, roomId: room.id });
                          }}
                          className="T-issue-refund"
                          disabled={disableInteraction}
                        >
                          Old Refund UX
                        </Button>
                      </>
                    )}

                    {hasAllowedAutomaticRefund && (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => {
                          showRefundModal({
                            itemId: item.id,
                            roomId: room.id,
                            auto: true,
                          });
                        }}
                        className="T-issue-refund"
                        disabled={disableInteraction}
                      >
                        Automatic refund
                      </Button>
                    )}
                  </RestrictedComponent>

                  <TemplateForCSButton bookingDetails={bookingDetails} toggleSummary={toggleSummary} />
                  <TemplateForCSModal
                    isSummaryOpen={isSummaryOpen}
                    toggleSummary={toggleSummary}
                    bookingDetails={bookingDetails}
                  />

                  {/* Disable for now, seems like we have some issue with bedbanks insurance letters */}
                  {/* {item.status === 'cancelled' && refundRequest && (
                <InsuranceLetter item={item} refundRequest={refundRequest} order={order} />
              )} */}
                </Stack>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box>
                <Typography fontWeight="medium">Customer Refund Policy:</Typography>
                <CancellationPolicies
                  room={room}
                  cancellationPolicy={cancellationPolicies}
                  propertyTimezone={propertyTimezone}
                  refundIssuer="LE"
                  nights={item.nights}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isRefundModalOpen && (
        <LPPRefundV2
          order={order}
          item={item}
          toggleRefundModal={toggleShowRefundV2Modal}
          roomId={room.id}
          showModal={isRefundModalOpen}
          approver={refundApprover}
          room={room}
          customerCancellationPolicy={cancellationPolicies}
          supplierCancellationPolicy={supplierCancellationPolicies}
          propertyTimezone={propertyTimezone}
        />
      )}

      <CancellationPolicyWarningModal
        showModal={isCancellationWarningModalOpen}
        closeModal={() => {
          setIsCancellationWarningModalOpen(false);
        }}
        showRefundModal={toggleShowRefundV2Modal}
        setRefundComment={updateRefundApprover}
      />
    </Box>
  );
}
