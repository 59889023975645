import React from 'react';

import DataObjectIcon from '@mui/icons-material/DataObject';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

type Props = {
  title: string;
  data: Record<string, unknown> | null;
};

export default function JsonDetails({ title, data }: Props) {
  const { isToggled: isOpen, toggle } = useToggleState(false);

  return (
    <>
      <IconButton onClick={toggle} title="Show details">
        <DataObjectIcon />
      </IconButton>

      {isOpen && (
        <Dialog onClose={toggle} maxWidth="lg" open>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            <DialogContentText component="pre" variant="body2">
              <code>{JSON.stringify(data, null, 2)}</code>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={toggle}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
