import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { ViewLinkWithSpoofDialog } from '~/components/Common/ViewLinkWithSpoofDialog';
import { LereAbandonedCart, LereAbandonedCartItem } from '~/components/LERE/types';

import useToggleState from '~/hooks/useToggleState';

import LereService from '~/services/LereService';
import PublicOffersService from '~/services/PublicOfferService';
import { formatDateWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';
import { customerPortalHost } from '~/services/common';

import { SpoofUrlParts } from '~/utils/url-formatter';

import GridPagination from '../Common/Elements/GridPagination';

interface Props {
  userId: string;
  email: string;
  tenant: App.Tenant;
  region: string;
}

const defaultColumns: Array<GridColDef> = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    valueGetter: (value) => formatDateWithClock(value),
    flex: 1,
    display: 'flex',
  },
  {
    field: 'checkIn',
    headerName: 'Check in',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'checkOut',
    headerName: 'Check out',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'duration',
    headerName: 'Duration',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'adults',
    headerName: 'Adults',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'children',
    headerName: 'Children',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'infants',
    headerName: 'Infants',
    flex: 1,
    display: 'flex',
  },
];

interface AbandonedCart {
  id: string;
  offer?: { name: string; url: string };
  linkToCart?: string;
  timestamp?: string;
  destination?: string;
  adults?: number;
  children?: number;
  infants?: number;
  checkIn?: string;
  checkOut?: string;
  duration?: string;
}

interface OfferDetails {
  name: string;
  offerUrl: string;
  destination: string;
}

async function fetchOfferDetailsFromAbandonedCart(params: {
  cart: LereAbandonedCart;
  brand: App.Brands;
  tenant: App.Tenant;
}): Promise<OfferDetails> {
  const { cart, brand, tenant } = params;

  if (cart.offer_type === 'bedbank_hotel') {
    const res = await PublicOffersService.getBedbankOfferById(cart.offer_id, cart.regionCode, brand);
    return {
      name: res.result.name,
      offerUrl: customerPortalHost(tenant) + `/partner/${res.result.slug}/${res.result.id}`,
      destination: res.result.property.address.city,
    };
  }

  const res = await PublicOffersService.getOfferById(cart.offer_id, cart.regionCode, brand);
  return {
    name: res.name,
    offerUrl: customerPortalHost(tenant) + `/offer/${res.slug}/${res.id_salesforce_external}`,
    destination: res.location.heading,
  };
}

function createAbandonedCartItem(params: {
  cart: LereAbandonedCart;
  item: LereAbandonedCartItem;
  tenant: App.Tenant;
  offerDetails: OfferDetails;
}): AbandonedCart {
  const { cart, item, tenant, offerDetails } = params;

  return {
    offer: {
      name: offerDetails.name,
      url: offerDetails.offerUrl,
    },
    timestamp: cart.creation_time ? new Date(cart.creation_time * 1000).toISOString() : undefined, // convert unix timestamp to ISO string
    destination: offerDetails.destination,
    adults: item.occupancy?.adults ?? 0,
    children: item.occupancy?.children ?? 0,
    infants: item.occupancy?.childrenAge?.length ?? 0,
    checkIn: item.checkIn,
    checkOut: item.checkOut,
    duration: item.duration?.toString(),
    linkToCart: `${customerPortalHost(tenant)}/checkout/${cart.cartId}/purchase`,
    id: uuid(),
  };
}

async function processAbandonedCarts(params: {
  cart: LereAbandonedCart;
  brand: App.Brands;
  tenant: App.Tenant;
}): Promise<Array<AbandonedCart>> {
  const { cart, brand, tenant } = params;

  if (!cart.offer_id) {
    return [];
  }

  const validItems = (cart.items || []).filter((item) => item.checkIn && item.checkOut);
  if (validItems.length === 0) {
    return [];
  }

  const offerDetails = await fetchOfferDetailsFromAbandonedCart({ cart, brand, tenant });
  return validItems.map((item) => createAbandonedCartItem({ cart, item, tenant, offerDetails }));
}

function UserAbandonedCartsTable({ userId, email, tenant, region }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [result, setResult] = useState<Array<AbandonedCart> | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [modalType, setModalType] = useState<'cart' | 'offer' | null>(null);
  const { isToggled: isDialogOpen, toggle: toggleDialog } = useToggleState(false);
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
  const [spoofedResponse, setSpoofedResponse] = useState<SpoofUrlParts | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleLinkClick = (url: string, type: 'cart' | 'offer') => {
    setSelectedUrl(url);
    setModalType(type);
    toggleDialog();
  };

  const displayColumns = [
    {
      field: 'linkToCart',
      headerName: 'Link to Cart',
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleLinkClick(params.row.linkToCart, 'cart')}>
          Cart
        </Button>
      ),
    },
    {
      field: 'offer',
      headerName: 'Offer',
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleLinkClick(params.row.offer.url, 'offer')}>
          {params.row.offer.name}
        </Button>
      ),
    },
    ...defaultColumns,
  ];

  useEffect(() => {
    async function loadData() {
      try {
        const [abandonedCartsResponse, spoofResponse] = await Promise.all([
          LereService.getAbandonedCarts({ userId, brand, region }),
          UsersService.spoofUserSearch(userId, tenant),
        ]);

        setSpoofedResponse(spoofResponse);
        const abandonedCarts = await Promise.all(
          abandonedCartsResponse.result.map((cart) => processAbandonedCarts({ cart, brand, tenant })),
        );

        setResult(abandonedCarts.flat());
      } catch (error) {
        reportError(error);
        enqueueSnackbar(`Error fetching abandoned carts: ${error.message}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [userId, brand, enqueueSnackbar, tenant, region]);

  return (
    <>
      <Box>
        <DataGrid
          rows={result || []}
          columns={displayColumns}
          getRowId={(row) => row.id}
          autoHeight
          loading={loading}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
          paginationModel={{ pageSize: 10, page }}
          onPaginationModelChange={({ page }) => setPage(page)}
          disableColumnMenu
          disableRowSelectionOnClick
          density="compact"
          slots={{ pagination: GridPagination }}
        />
      </Box>

      {isDialogOpen && selectedUrl && modalType && spoofedResponse && (
        <ViewLinkWithSpoofDialog
          isOpen={isDialogOpen}
          onClose={toggleDialog}
          baseUrl={selectedUrl}
          spoofData={spoofedResponse}
          email={email}
          type={modalType}
        />
      )}
    </>
  );
}

export default UserAbandonedCartsTable;
