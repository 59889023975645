import React from 'react';

import { TableCell, TableRow } from '@mui/material';

import SegmentHeaderGroups from '~/components/Content/SearchRanking/Segment/SegmentHeaderGroups';

interface Props {
  segments: Array<App.Segment>;
}

export function SearchHotelHead({ segments }: Props) {
  return (
    <TableRow>
      <TableCell colSpan={2} sx={{ minWidth: 150 }}>
        Global Rank
      </TableCell>
      <TableCell align="right">Type</TableCell>
      <TableCell sx={{ minWidth: 150 }} align="right">
        List Visibility Schedule
      </TableCell>
      <TableCell align="right">Views</TableCell>
      <TableCell align="right">Sales #</TableCell>
      <TableCell align="right">Sales $</TableCell>
      <TableCell align="right">Margin #</TableCell>
      <TableCell align="right">Margin %</TableCell>
      <TableCell align="right">Margin / View</TableCell>
      <TableCell align="right">Adjusted MPV</TableCell>
      <TableCell align="right">Manual Score</TableCell>
      <SegmentHeaderGroups segments={segments} />
      <TableCell align="right">CTR</TableCell>
      <TableCell align="right">Final Score</TableCell>
    </TableRow>
  );
}
