import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';

import { getPartnerships } from '~/services/LoyaltyService';

import getEarnOrderPartnerships from '~/utils/getEarnOrderPartnerships';

type Props = {
  user: App.User;
};

const loyaltyPageUri = (userId: string, code: string) => {
  return `/users/${userId}/loyalty/${code}`;
};

export default function PartnershipButtons(props: Props) {
  const { user } = props;

  const [partnerships, setPartnerships] = useState<App.Partnership[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getPartnerships()
      .then<App.Partnership[]>((response) => getEarnOrderPartnerships(response.result))
      .then(setPartnerships)
      .finally(() => setLoading(false));
  }, []);

  if (isLoading) {
    return <CircularProgress size="24px" />;
  }

  return (
    <>
      {partnerships.map((partnership) => (
        <Fragment key={partnership.code}>
          <Button
            component={Link}
            className="T-view-qantas-btn"
            variant="contained"
            to={loyaltyPageUri(user.id_member, partnership.code)}
          >
            {partnership.brandName}
          </Button>
        </Fragment>
      ))}
    </>
  );
}
