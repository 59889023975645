import React from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogTitle, Stack, Typography } from '@mui/material';

import { deleteCommission } from '~/services/AgentHub/AgentService';

import { Commission } from '~/types/services/agentHub';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  listCommissions: () => void;
  data: Commission;
}

export default function DeleteCommissionDialog({ isOpen, onClose, data, listCommissions }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleDeleteCommission(id: string) {
    try {
      await deleteCommission(id);
      onClose();
      listCommissions();
    } catch (err) {
      enqueueSnackbar(err?.message || 'Unknown error', { variant: 'error' });
    }
  }

  return (
    <Dialog open={isOpen} fullWidth onClose={onClose}>
      <DialogTitle>Confirm deletion</DialogTitle>
      <Stack direction="column" paddingX={3} paddingBottom={3} spacing={2}>
        <Typography>
          Are you sure you wish to delete {data?.description}? You'll need to set up this commission rule again if
          required.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleDeleteCommission(data.id)}>
            Yes, delete
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
