import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import { getAgenciesById, getCommissions } from '~/services/AgentHub/AgentService';
import UsersService from '~/services/UsersService';

import { Commission } from '~/types/services/agentHub';

import { capitalise } from '~/utils/stringUtils';

import { AgentHubCommissionRegions } from './AgentHubCommissionRegions';
import { AgentHubCommissionRules } from './AgentHubCommissionRules';
import CommissionDialog from './AgentHubCommissions/CommissionDialog';
import DeleteCommissionDialog from './AgentHubCommissions/DeleteCommissionDialog';

const DEFAULT_SIZE_PER_PAGE = 10;

function AgentCommissions() {
  const queryFilters = useQuery();

  const filter = useMemo(() => {
    return {
      page: parseInt(queryFilters.get('page') ?? '0'),
      search: queryFilters.get('search'),
      status: queryFilters.get('status'),
    };
  }, [queryFilters]);

  const [commissionList, setCommissionList] = useState<Array<Commission>>([]);
  const [total, setTotal] = useState<number>(0);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [commissionData, setCommissionData] = useState<Commission | undefined>();
  const [agents, setAgents] = useState<Record<string, string>>({});
  const [agencies, setAgencies] = useState<Record<string, string>>({});
  const [mode, setMode] = useState<'create' | 'edit'>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { push: setQueryString } = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const onPageChange = useCallback(
    (page: number) => {
      queryFilters.set('page', page.toString());
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const fetchAgents = useCallback(async (agentsIds: Array<string>) => {
    const agents = await UsersService.getUsersSummaryByIds(agentsIds, 'leagenthub');
    const agentsArray = Array.from(agents.values());
    return agentsArray.map((agent) => ({ value: agent.id_member, label: `${agent.full_name} [${agent.email}]` }));
  }, []);

  const fetchAgencies = useCallback(async (agenciesIds: Array<string>) => {
    const agencies = await getAgenciesById(agenciesIds);
    return agencies.map((agency) => ({ value: agency.id, label: agency.name }));
  }, []);

  const listCommissions = useCallback(() => {
    setIsListLoading(true);
    getCommissions({
      page: filter.page.toString(),
      pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
    })
      .then((res) => {
        if (res) {
          setCommissionList(res.rows);
          const allAgencies = res.rows.flatMap((row) => row.rules.agency || []);
          const allAgents = res.rows.flatMap((row) => row.rules.agent || []);

          Promise.all([
            allAgents.length > 0 && fetchAgents(allAgents),
            allAgencies.length > 0 && fetchAgencies(allAgencies),
          ]).then(([agents, agencies]) => {
            const reduceToLabelFn = (acc: Record<string, string>, item: { value: string; label: string }) => ({
              ...acc,
              [item.value]: item.label,
            });
            if (agents) {
              const agentsMap = agents.reduce(reduceToLabelFn, {});
              setAgents((agents) => ({ ...agents, ...agentsMap }));
            }
            if (agencies) {
              const agenciesMap = agencies.reduce(reduceToLabelFn, {});
              setAgencies((agencies) => ({ ...agencies, ...agenciesMap }));
            }
          });
          setTotal(res.total);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  }, [enqueueSnackbar, filter.page, fetchAgents, fetchAgencies]);

  useEffect(() => {
    listCommissions();
  }, [listCommissions]);

  const sharedOpts: Partial<GridColDef> = {
    editable: false,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 2,
  };

  const columns: Array<GridColDef> = [
    {
      ...sharedOpts,
      field: 'description',
      headerName: 'Description',
      renderHeader: () => <Typography variant="button">Description</Typography>,
      flex: 1.5,
    },
    {
      ...sharedOpts,
      field: 'commissionPercentage',
      headerName: 'Commission',
      renderHeader: () => <Typography variant="button">Commission</Typography>,
      flex: 1,
      renderCell: (params) => `${params.value}%`,
    },
    {
      ...sharedOpts,
      field: 'commissionType',
      headerName: 'Commission Type',
      flex: 1,
      renderHeader: () => <Typography variant="button">Commission Type</Typography>,
      renderCell: (params) => capitalise(params.value),
    },
    {
      ...sharedOpts,
      field: 'regions',
      headerName: 'Regions',
      flex: 1,
      renderCell: (params) => <AgentHubCommissionRegions regions={params.value} />,
      renderHeader: () => <Typography variant="button">Regions</Typography>,
    },
    {
      ...sharedOpts,
      field: 'rules',
      headerName: 'Rules',
      renderCell: (params) => <AgentHubCommissionRules rules={params.value} agents={agents} agencies={agencies} />,
      renderHeader: () => <Typography variant="button">Rules</Typography>,
    },
    {
      ...sharedOpts,
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderHeader: () => <Typography variant="button">Actions</Typography>,
      renderCell: (params) => (
        <Stack spacing={1} alignItems="center" direction="row">
          <Button
            sx={{ padding: 0, margin: 0, display: 'inline-block', minHeight: 0, minWidth: 0 }}
            onClick={() => {
              openModal('edit');
              setCommissionData(params.row);
            }}
          >
            <ModeEditIcon sx={{ color: 'text.primary' }} />
          </Button>
          <Button
            sx={{ padding: 0, margin: 0, display: 'inline-block', minHeight: 0, minWidth: 0 }}
            onClick={() => {
              openDeleteModal();
              setCommissionData(params.row);
            }}
          >
            <DeleteIcon sx={{ color: 'text.primary' }} />
          </Button>
        </Stack>
      ),
    },
  ];

  const openModal = (mode: 'create' | 'edit') => {
    setMode(mode);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setCommissionData(null);
    setIsModalOpen(false);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Agent Commissions</title>
      </Helmet>

      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <PageSubheader title="Commission rules" />
          <Button
            sx={{ textTransform: 'none' }}
            startIcon={<Add />}
            variant="contained"
            onClick={() => openModal('create')}
          >
            Create new rule
          </Button>
        </Stack>

        <DataGrid
          loading={isListLoading}
          columns={columns}
          getRowId={(row) => row.id}
          rows={commissionList}
          autoHeight
          rowCount={total}
          paginationMode="server"
          paginationModel={{ page: filter.page, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          getRowHeight={() => 'auto'}
        />
      </Grid>

      <CommissionDialog
        mode={mode}
        isOpen={isModalOpen}
        onClose={closeModal}
        data={commissionData}
        listCommissions={listCommissions}
      />
      <DeleteCommissionDialog
        listCommissions={listCommissions}
        data={commissionData}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      />
    </Container>
  );
}

export default AgentCommissions;
