import React, { useCallback, useMemo } from 'react';

import { Button } from '@mui/material';

import { Brand } from '@luxuryescapes/lib-regions/lib/regions';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR } from '~/consts/roles';

import useToggleState from '~/hooks/useToggleState';

import ScheduleBulkUpdate from './ScheduleBulkUpdate';

interface Props {
  brand: Brand;
  offerId: string;
  schedules: Array<App.Schedule>;
  type: string;
  shouldMigrateSchedule: boolean;
  onOpen: () => void;
  onSaveFinished: () => void;
  onError: () => void;
  title?: string;
}

export default function AgentHubScheduleBulkUpdateContainer({
  brand = 'luxuryescapes',
  schedules: allSchedules,
  offerId,
  type,
  shouldMigrateSchedule,
  onOpen,
  onSaveFinished,
  onError,
  title = 'Bulk Update',
}: Props) {
  const { isToggled: isOpen, toggleOn: open, toggleOff: close } = useToggleState();

  const openWithCallback = useCallback(() => {
    onOpen();
    open();
  }, [onOpen, open]);

  const currentSchedules = useMemo(
    () => allSchedules.filter((schedule) => type && schedule.type === type && schedule.brand === brand),
    [allSchedules, type],
  );

  return (
    <>
      <ScheduleBulkUpdate
        brand={brand}
        isOpen={isOpen}
        currentSchedules={currentSchedules}
        offerId={offerId}
        type={type}
        onClose={close}
        onSaveFinished={onSaveFinished}
        onError={onError}
        title={title}
      />

      <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR]}>
        <Button
          type="button"
          className="add-schedule"
          variant="contained"
          color="secondary"
          onClick={openWithCallback}
          disabled={shouldMigrateSchedule}
        >
          {title}
        </Button>
      </PermissionedComponent>
    </>
  );
}
