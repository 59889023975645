import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { CommissionOption, RuleCondition } from '~/consts/agentHub';

import SearchService, { TypeaheadType } from '~/services/SearchService';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  existingConditions: RuleCondition['value'];
}

const SEARCH_TYPES: Record<TypeaheadType, boolean> = {
  airport: false,
  province_state: true,
  neighborhood: true,
  city: true,
  high_level_region: true,
  country: true,
  multi_city_vicinity: true,
  metro_station: false,
  continent: true,
  train_station: false,
  point_of_interest: true,
  colloquial_area: true,
  le_property: true,
  le_property_unique_stays: true,
  bedbank_property: true,
  bedbank_property_unique_stays: true,
  le_experience: false,
  channel_experience: false,
  car_hire_location: false,
};

function AgentHubCommissionsLocationInput({ setCreatedConditions, existingConditions }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<CommissionOption>([]);
  const [selectedLocations, setSelectedLocations] = useState<CommissionOption>(existingConditions || []);
  const [noOptionsText, setNoOptionsText] = useState<string>('Start typing to search for locations');

  const selectedTypes: Array<TypeaheadType> = Object.keys(SEARCH_TYPES).filter(
    (type) => SEARCH_TYPES[type],
  ) as Array<TypeaheadType>;

  const debounceGetLocations = useMemo(
    () =>
      debounce((search: string) => {
        if (!search) {
          setResult([]);
          return;
        }
        setLoadingState('loading');
        SearchService.typeahead(search, {
          type: selectedTypes,
          brand: 'leagenthub',
          region: 'AU',
          limit: 10,
        })
          .then((response) => {
            setResult(
              response.result.results.map((location) => ({
                value: location.primary_text,
                label: location.primary_text,
              })),
            );
            setLoadingState('success');
          })
          .catch(() => {
            setResult([]);
            setNoOptionsText('Failed to fetch location');
          });
      }, 300),
    [selectedTypes],
  );

  const handleSaveCondition = () => {
    setCreatedConditions(
      {
        type: 'location',
        value: selectedLocations,
      },
      true,
    );
  };

  useEffect(() => {
    handleSaveCondition();
  }, [selectedLocations]);

  const handleInputChange = (_: React.ChangeEvent, newInputValue: string) => {
    if (!newInputValue) {
      setNoOptionsText('Start typing to search for locations');
      return;
    }
    debounceGetLocations(newInputValue);
  };

  const handleChange = (_: React.ChangeEvent, value: CommissionOption) => {
    setSelectedLocations(value);
  };

  const defaultProps = {
    options: result,
    getOptionLabel: (option: CommissionOption[0]) => `${option.label}`,
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          {...defaultProps}
          multiple
          loading={loadingState === 'loading'}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Location" placeholder="Location" />}
          onInputChange={handleInputChange}
          value={selectedLocations}
          onChange={handleChange}
          noOptionsText={noOptionsText}
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for locations
        </Typography>
      </div>
    </Stack>
  );
}

export default AgentHubCommissionsLocationInput;
