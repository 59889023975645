import React from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

import { VARIANT_OF_REFUND_ALL, VARIANT_OF_REFUND_PARTIAL, VARIANT_OF_REFUND_SURCHARGE } from '~/consts/refund';

import currencyFormatter from '~/utils/currencyFormatter';

import RefundSurcharge from '../Common/RefundSurcharge';
import UpdateVCCBalanceForm from '../Common/UpdateVCCBalanceForm';

interface Props {
  defaultVccChecked: boolean;
  hasSurcharge: boolean;
  currencyCode: string;
  surchargeAmount?: number;
  setVariantOfRefund: (newVariant: string) => void;
  variantOfRefund: string;
}

export default function SelectRefundVariantWithSurcharge({
  defaultVccChecked,
  hasSurcharge,
  currencyCode,
  surchargeAmount,
  setVariantOfRefund,
  variantOfRefund,
}: Props) {
  return (
    <Stack>
      <FormControl>
        <RadioGroup
          name="variantOfRefund"
          row
          value={variantOfRefund}
          onChange={(e) => {
            setVariantOfRefund(e.target.value);
          }}
        >
          <FormControlLabel
            key="full-refund"
            value={VARIANT_OF_REFUND_ALL}
            control={
              <Radio
                inputProps={{
                  required: true,
                }}
              />
            }
            data-cy="Full Package Refund"
            label="Full Package Refund"
          />
          <FormControlLabel
            key="partial-refund"
            value={VARIANT_OF_REFUND_PARTIAL}
            data-cy="Partial Refund"
            control={
              <Radio
                inputProps={{
                  required: true,
                }}
              />
            }
            label="Partial Refund"
          />
          {hasSurcharge && (
            <FormControlLabel
              key="surcharge-refund"
              value={VARIANT_OF_REFUND_SURCHARGE}
              data-cy="Surcharge Refund"
              control={
                <Radio
                  inputProps={{
                    required: true,
                  }}
                />
              }
              label="Surcharge Only"
            />
          )}
        </RadioGroup>
      </FormControl>
      {variantOfRefund === VARIANT_OF_REFUND_ALL && hasSurcharge && (
        <RefundSurcharge currencyCode={currencyCode} surchargeAmount={surchargeAmount} />
      )}
      {variantOfRefund === VARIANT_OF_REFUND_PARTIAL && (
        <Stack spacing={1}>
          <TextField
            label="Refund Amount to Customer"
            required
            type="number"
            data-cy="RefundAmountToCustomer"
            name="refundAmountToCustomer"
            inputProps={{
              step: 0.01,
            }}
          />
          <UpdateVCCBalanceForm defaultVccChecked={defaultVccChecked} />
        </Stack>
      )}
      {variantOfRefund === VARIANT_OF_REFUND_SURCHARGE && (
        <Typography>
          The surcharge of {currencyFormatter(currencyCode, surchargeAmount, 2, 'code')} will be refunded to customer
        </Typography>
      )}
    </Stack>
  );
}
