import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import { reportError } from '~/utils/reportError';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  userId: string;
};

const columns: GridColDef[] = [
  {
    field: 'login_date',
    headerName: 'Last Logins',
    flex: 2,
    valueGetter: (_value, row) => formatDateOrdinalWithClock(row.login_date),
    display: 'flex',
  },
  {
    field: 'ip_address',
    headerName: 'IP Address',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'login_method',
    headerName: 'Login Method',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'geo_city',
    headerName: 'City',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'geo_region',
    headerName: 'Region',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'geo_country',
    headerName: 'Country',
    flex: 2,
    display: 'flex',
  },
];

const UserLoginTable: React.FC<Props> = ({ userId }: Props) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    try {
      UsersService.getUserLogins(userId)
        .then((response) => setResult(response))
        .finally(() => setLoading(false));
    } catch (error) {
      reportError(error);
    }
  }, [userId]);

  return (
    <Box>
      <DataGrid
        rows={result || []}
        columns={columns}
        getRowId={() => uuid()}
        autoHeight
        loading={loading}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5]}
        paginationModel={{ pageSize: 5, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        disableColumnMenu
        disableRowSelectionOnClick
        density="compact"
        slots={{ pagination: GridPagination }}
      />
    </Box>
  );
};

export default UserLoginTable;
