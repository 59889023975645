import React, { useCallback } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

export default function FinePrint({ finePrint }) {
  const { isToggled: open, toggle: toggleOpen } = useToggleState(false);

  const handleChange = useCallback((_, isExpanded) => toggleOpen(isExpanded), [toggleOpen]);

  return (
    <Accordion expanded={open} onChange={handleChange} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Fine Print</AccordionSummary>

      <AccordionDetails>
        <div className="bedbank-fineprint">
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Check-in</div>
            <div className="bedbank-fineprint-value">Check-in starts at {finePrint.checkIn?.beginTime}</div>
            <div className="bedbank-fineprint-value">Check-in ends at {finePrint.checkIn?.endTime}</div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Check-out</div>
            <div className="bedbank-fineprint-value">Check-out before at {finePrint.checkOut?.time}</div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Special instructions</div>
            <div className="bedbank-fineprint-value">{finePrint.checkIn?.instructions}</div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Special check-in instructions</div>
            <div className="bedbank-fineprint-value">{finePrint.checkIn?.specialInstructions}</div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Pets</div>
            <div className="bedbank-fineprint-value">
              <ul>
                {finePrint.pets.map((p, index) => (
                  <li key={index}>{p}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Fees</div>
            <div className="bedbank-fineprint-value">
              <dl>
                <dt> - optional</dt>
                <dd>
                  <ul>
                    <li>{finePrint.fees?.optional}</li>
                  </ul>
                </dd>
                <dt> - mandatory</dt>
                <dd>
                  <ul>
                    <li>{finePrint.fees?.mandatory}</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div className="bedbank-fineprint-group">
            <div className="bedbank-fineprint-group-name">Know Before You Go</div>
            <div className="bedbank-fineprint-value">{finePrint.policies?.knowBeforeYouGo}</div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
