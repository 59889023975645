import React, { useCallback, useEffect, useMemo, useState } from 'react';

import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';

import {
  Alert,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import { Field, FlexWrapper, Spinner, Text } from '~/components/Experiences/components';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { EXP_ANY_DATE, EXP_PROVIDER_LED, ORIGINAL_EXPERIENCE_CONTENT_TAB, themes } from '~/consts/experiences';

import {
  BookingOptions,
  ChildSeatType,
  ExperienceOffer,
  TransferType,
  getAvailableTimes,
} from '~/services/ExperiencesService';
import { formatDateWithClock } from '~/services/TimeService';

interface Props {
  experience: ExperienceOffer;
  tenant: App.Tenant;
}

interface TicketView {
  id: string;
  fareType: string;
  price: number;
  providerPrice: number;
  margin?: number;
  bookingOptions?: Experiences.TimeTicket['bookingOptions'];
  imageUrl?: string;
  currencyCode: string;
  ticketUnitLabel?: string;
  rateStartDate?: string;
  rateEndDate?: string;
  isExtra?: boolean;
  isHidden?: boolean;
  appDiscountPercentage: number;
  appPrice: number;
  image?: File;
  maxAppDiscountPercentage: number;
}

const TicketImage = styled.img`
  height: 50px;
  width: 60px;
`;

const calculatePrice = (price: number, margin?: number) => {
  const newMargin = !margin || isNaN(margin) ? 0 : margin;
  return parseFloat((price + (price / 100) * newMargin).toFixed(2));
};

const transferTypesOptions = [
  { value: TransferType.AIRPORT_TO_HOTEL, label: 'Airport to Hotel' },
  { value: TransferType.HOTEL_TO_AIRPORT, label: 'Hotel to Airport' },
];

const childSeatTypesOptions = [
  { value: ChildSeatType.INFANT_SEAT, label: 'Infant Seat' },
  { value: ChildSeatType.BOOSTER_SEAT, label: 'Booster Seat' },
];

function ExperienceCurationTickets({ experience, tenant }: Props) {
  const [tickets, setTickets] = useState<Array<TicketView>>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { updateValues, contentTab, values, payload } = useUpdateExperienceValues();

  const changedTickets = useMemo(() => {
    return payload?.tickets?.reduce((acc, ticket) => ({ ...acc, [ticket.id]: ticket }), {});
  }, [payload]);

  useEffect(() => {
    return () => {
      setTickets([]);
      setLoading(false);
      setError('');
    };
  }, []);

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const fetchAvailableTickets = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await getAvailableTimes(experience.id, tenant.brand, {
        day: EXP_ANY_DATE,
      });

      const dealOptions: Array<TicketView> = uniqBy(
        res.result.flatMap((group) =>
          group.slots.flatMap((slot) =>
            slot.tickets.map((ticket) => {
              const fareType = !areOriginalValues ? ticket.fareType : ticket.providerFareType;
              const currencyCode = ticket.salesPrices[0].currencyCode;
              const margin = !areOriginalValues && ticket.margin ? ticket.margin : 0;
              let bookingOptions: Partial<BookingOptions> = {
                childSeatTypes: [],
                transferTypes: [],
              };
              const ticketUnitLabel = ticket.ticketUnitLabel;
              if (ticket.bookingOptions) {
                bookingOptions = ticket.bookingOptions;
              }

              const ticketPrice = calculatePrice(ticket.providerPrice.amount, margin);

              return {
                id: ticket.productId,
                fareType,
                price: ticketPrice,
                providerPrice: ticket.providerPrice.amount,
                margin: margin,
                bookingOptions,
                imageUrl: ticket.imageUrl,
                currencyCode,
                ticketUnitLabel,
                rateStartDate: ticket.rateStartDate,
                rateEndDate: ticket.rateEndDate,
                isExtra: ticket.isExtra,
                isHidden: ticket.isHidden,
                appDiscountPercentage: ticket.discounts.app.percentage,
                appPrice: parseFloat((ticketPrice - ticket.discounts.app.amount).toFixed(2)),
                maxAppDiscountPercentage: ticket.discounts.app.maxPercentage,
              };
            }),
          ),
        ),
        'id',
      );

      const orderedDealOptions = orderBy(dealOptions, ['id']);

      setTickets(orderedDealOptions);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [areOriginalValues, experience.id, tenant.brand]);

  useEffect(() => {
    if (experience) {
      fetchAvailableTickets();
    }
  }, [experience, fetchAvailableTickets]);

  const handleTicket = (index: number, value: Partial<TicketView>) => {
    setTickets((tickets: Array<TicketView>) => {
      const key = Object.keys(value)[0] as keyof TicketView;

      if (key === 'appDiscountPercentage') {
        // min 0 and max maxAppDiscountPercentage
        value[key] = isNaN(value[key]) ? 0 : Math.min(Math.max(0, value[key]), tickets[index].maxAppDiscountPercentage);
      }

      if (key === 'margin') {
        // If margin changed, app discount percentage should be reset to 0 and
        // updated after saving the changes
        value.appDiscountPercentage = 0;
      }

      tickets[index] = { ...tickets[index], ...value };

      tickets[index].price = calculatePrice(tickets[index].providerPrice ?? 0, tickets[index].margin);
      // eslint-disable-next-line prettier/prettier
      tickets[index].appPrice = parseFloat(
        (tickets[index].price - (tickets[index].appDiscountPercentage / 100) * tickets[index].price).toFixed(2),
      );

      const ticketsToStore = values.tickets ?? [];
      const indexToStore = ticketsToStore.findIndex((ticket) => tickets[index].id === ticket.id);
      if (indexToStore >= 0) {
        ticketsToStore[indexToStore] = {
          ...ticketsToStore[indexToStore],
          ...value,
        };
      } else {
        ticketsToStore.push({ id: tickets[index].id, ...value });
      }

      if (key === 'fareType' && !value[key]) {
        delete ticketsToStore[index][key];
      }

      updateValues({
        tickets: ticketsToStore,
      });

      return orderBy(tickets, ['id']);
    });
  };

  const handleTicketBookingOptions = (index: number, value: { [key: string]: string | number }) => {
    setTickets((tickets) => {
      tickets[index].bookingOptions = {
        ...tickets[index].bookingOptions,
        ...value,
      };

      const ticketsToStore = values.tickets ?? [];
      const indexToStore = ticketsToStore.findIndex((ticket) => tickets[index].id === ticket.id);
      if (indexToStore >= 0) {
        ticketsToStore[indexToStore].bookingOptions = {
          ...ticketsToStore[indexToStore].bookingOptions,
          ...value,
        };
      } else {
        ticketsToStore.push({
          id: tickets[index].id,
          bookingOptions: value,
        });
      }

      updateValues({
        tickets: ticketsToStore,
      });

      return orderBy(tickets, ['id']);
    });
  };

  const handleSelectedTransferTypes = (index, transferTypes) => {
    const transferTypesValues = transferTypes.map((type) => {
      if (typeof type === 'string') {
        return type;
      }

      return type.value;
    });

    handleTicketBookingOptions(index, { transferTypes: transferTypesValues });
  };

  const handleSelectedChildSeatTypes = (index, childSeatTypes) => {
    const childSeatTypesValues = childSeatTypes.map((type) => {
      if (typeof type === 'string') {
        return type;
      }

      return type.value;
    });

    handleTicketBookingOptions(index, { childSeatTypes: childSeatTypesValues });
  };

  const isMarginDisabled = areOriginalValues || experience?.provider === EXP_PROVIDER_LED;

  const hasTransferFeature = !!experience?.features?.transfer || !!values?.features?.transfer;

  const hasPriceOverridden = experience?.tickets?.some((ticket) => ticket.margin);

  return (
    <>
      {loading && (
        <FlexWrapper align="center" justify="center">
          <Spinner size={25} />
        </FlexWrapper>
      )}

      {error && <Alert severity="error">{error}</Alert>}

      {!loading && !error && (
        <Grid container spacing={2} columns={4}>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked={hasPriceOverridden} disabled />}
                label="Price Overridden"
              />
            </FormGroup>
          </Grid>
          {tickets.map((ticket, index) => (
            <Grid key={`${ticket.id}-container`} item md={4} lg={2}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={2} rowSpacing={4} columns={6}>
                    <Grid item xs={6}>
                      <Grid container spacing={2} columns={4}>
                        <Grid item xs={6} sm={6} md={2}>
                          <Field
                            fullWidth
                            name="fareType"
                            onChange={({ value }) => handleTicket(index, { fareType: value })}
                            label="Title"
                            value={ticket.fareType}
                            disabled={areOriginalValues}
                          />
                        </Grid>

                        <Grid item sm={6} md={2}>
                          <Stack direction="row">
                            <FormGroup title="Complement or add-on to the main tickets. If this option is checked, this ticket cannot be sold stand alone">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!ticket.isExtra}
                                    onChange={(event) => handleTicket(index, { isExtra: event.target.checked })}
                                  />
                                }
                                label="Extra / Complement"
                              />
                            </FormGroup>
                            <FormGroup title="Hide this ticket from listing at Customer Portal">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!ticket.isHidden}
                                    onChange={(event) => handleTicket(index, { isHidden: event.target.checked })}
                                  />
                                }
                                label="Hide Ticket"
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Field
                        fullWidth
                        type="number"
                        name="margin"
                        onChange={({ value }) => handleTicket(index, { margin: parseInt(value) })}
                        label="Margin %"
                        value={ticket.margin}
                        disabled={isMarginDisabled}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Field
                        fullWidth
                        type="number"
                        name="appDiscountPercentage"
                        onChange={({ value }) => handleTicket(index, { appDiscountPercentage: parseInt(value) })}
                        label="App Discount %"
                        value={ticket.appDiscountPercentage}
                        inputMode="numeric"
                        inputProps={{
                          min: 0,
                          step: 1,
                          max: 100,
                        }}
                        disabled={
                          !experience.isAppDiscountEnabled || (!!changedTickets && !!changedTickets[ticket.id]?.margin)
                        }
                      />
                      {experience.isAppDiscountEnabled && !!changedTickets && !!changedTickets[ticket.id]?.margin && (
                        <Text size="small" color="orange">
                          Please save your changes before changing the app discount percentage
                        </Text>
                      )}
                    </Grid>

                    <Grid item xs={1.2}>
                      <Typography title={experience.title}>Provider price</Typography>
                      <Text weight={600} title={experience.title} size="large">
                        {ticket.currencyCode}&nbsp;{ticket.providerPrice}
                      </Text>
                    </Grid>

                    <Grid item xs={1.2}>
                      <Typography title={experience.title}>Sale price</Typography>
                      <Text weight={600} title={experience.title} size="large">
                        {ticket.currencyCode}&nbsp;{ticket.price}
                      </Text>
                    </Grid>

                    <Grid item xs={1.2}>
                      <Typography title={experience.title}>App price</Typography>
                      <Text weight={600} title={experience.title} size="large">
                        {ticket.currencyCode}&nbsp;{ticket.appPrice}
                      </Text>
                    </Grid>

                    <Grid item xs={1.2}>
                      <Typography title="Minimum date that can be selected by the customer at the time of booking">
                        Min date
                      </Typography>
                      <Text weight={600} title={experience.title} size="large">
                        {ticket.rateStartDate ? formatDateWithClock(ticket.rateStartDate) : 'Any date'}
                      </Text>
                    </Grid>

                    <Grid item xs={1.2}>
                      <Typography title="Maximum date that can be selected by the customer at the time of booking for">
                        Max date
                      </Typography>
                      <Text weight={600} title={experience.title} size="large">
                        {ticket.rateStartDate ? formatDateWithClock(ticket.rateEndDate) : 'Any date'}
                      </Text>
                    </Grid>

                    {hasTransferFeature && (
                      <>
                        <Grid item xs={6}>
                          <Divider />
                        </Grid>

                        <Grid item xs={6}>
                          <Grid container spacing={2} rowSpacing={4} columns={6}>
                            <Grid item xs={6}>
                              <Text weight={600} size="large">
                                Transfer details
                              </Text>
                            </Grid>
                            <Grid item sm={3} md={1.5}>
                              <Field
                                fullWidth
                                type="number"
                                name="maxPassengers"
                                onChange={({ value }) =>
                                  handleTicketBookingOptions(index, {
                                    maxPassengers: parseInt(value),
                                  })
                                }
                                label="Max Passengers"
                                value={ticket.bookingOptions.maxPassengers}
                                disabled={areOriginalValues}
                              />
                            </Grid>

                            <Grid item sm={3} md={1.5}>
                              <Field
                                fullWidth
                                type="number"
                                name="maxCheckedBags"
                                onChange={({ value }) =>
                                  handleTicketBookingOptions(index, {
                                    maxCheckedBags: parseInt(value),
                                  })
                                }
                                label="Max Checked Bags"
                                value={ticket.bookingOptions.maxCheckedBags}
                                disabled={areOriginalValues}
                              />
                            </Grid>

                            <Grid item sm={3} md={1.5}>
                              <Field
                                fullWidth
                                type="number"
                                name="maxCarryonBags"
                                onChange={({ value }) =>
                                  handleTicketBookingOptions(index, {
                                    maxCarryOnBags: parseInt(value),
                                  })
                                }
                                label="Max CarryOn Bags"
                                value={ticket.bookingOptions.maxCarryOnBags}
                                disabled={areOriginalValues}
                              />
                            </Grid>

                            <Grid item sm={3} md={1.5}>
                              <Field
                                fullWidth
                                type="number"
                                name="distanceCovered"
                                onChange={({ value }) =>
                                  handleTicketBookingOptions(index, {
                                    distanceCovered: parseInt(value),
                                  })
                                }
                                label="Distance covered (KM)"
                                value={ticket.bookingOptions.distanceCovered}
                                disabled={areOriginalValues}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                options={childSeatTypesOptions.map((type) => type.value)}
                                onChange={(_, values) => handleSelectedChildSeatTypes(index, values)}
                                value={ticket.bookingOptions.childSeatTypes ?? []}
                                renderInput={(params) => <TextField {...params} label="Child Seat Type" />}
                                getOptionLabel={(option) =>
                                  childSeatTypesOptions.find((type) => type.value === option)?.label
                                }
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <Box flexGrow={1}>
                                <Autocomplete
                                  multiple
                                  disableCloseOnSelect
                                  options={transferTypesOptions.map((type) => type.value)}
                                  onChange={(_, values) => handleSelectedTransferTypes(index, values)}
                                  value={ticket.bookingOptions.transferTypes ?? []}
                                  renderInput={(params) => <TextField {...params} label="Transfer Type" />}
                                  getOptionLabel={(option) =>
                                    transferTypesOptions.find((type) => type.value === option)?.label
                                  }
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={6}>
                              <Text weight={700} lineHeight="32px" color={themes.primaryBlue}>
                                Upload an image
                              </Text>

                              <Stack direction="row" spacing={4}>
                                <TextField
                                  inputProps={{ accept: 'image/*' }}
                                  type="file"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleTicket(index, { image: e.target.files[0] })
                                  }
                                  disabled={areOriginalValues}
                                  fullWidth
                                />
                                <TicketImage src={ticket.imageUrl} />
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default ExperienceCurationTickets;
