import React, { useCallback, useState } from 'react';

import { CheckCircleOutline, DeleteForever } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import {
  ExtendedMappedInternalRoom,
  InternalRoom,
  MappedInternalRoom,
  Room,
  RoomMapping,
  RoomMappingPayload,
} from '~/services/AccommodationService';

import { titleCase } from '~/utils/stringUtils';

import MappingLogTable from './MappingLogTable';
import SupplierRoomListElement from './SupplierRoomListElement';
import ToBeMappedRoomElement from './ToBeMappedRoomElement';

interface Props {
  room: Room;
  onSelect: (room: Room) => void;
  isSelected: boolean;
  onRemoveMappedRoom: (room: ExtendedMappedInternalRoom) => void;
  onVerifyMappedRoom: (room: MappedInternalRoom) => void;
  clearNewMappings: () => void;
  removePayload: RoomMappingPayload;
  unsavedInternalRooms: Array<InternalRoom>;
  newMappings?: RoomMapping;
}

export default function ControlRoomElement({
  room,
  onSelect,
  isSelected,
  onRemoveMappedRoom,
  onVerifyMappedRoom,
  clearNewMappings,
  unsavedInternalRooms,
  newMappings,
}: Props) {
  const [selectedMappedRoom, setSelectedMappedRoom] = useState<ExtendedMappedInternalRoom | null>(null);
  const [verifyMappedRoom, setVerifyMappedRoom] = useState<ExtendedMappedInternalRoom | null>(null);
  const [mappingLogsRoom, setMappingLogsRoom] = useState<MappedInternalRoom | null>(null);
  const [logsRoom, setLogsRoom] = useState<Room | null>(null);
  const {
    isToggled: isDeleteConfirmationModalOpen,
    toggleOn: setOpenDeleteConfirmationModal,
    toggleOff: setCloseDeleteConfirmationModal,
  } = useToggleState(false);

  const {
    isToggled: isVerifyConfirmationModalOpen,
    toggleOn: setOpenVerifyConfirmationModal,
    toggleOff: setCloseVerifyConfirmationModal,
  } = useToggleState(false);

  const {
    isToggled: isMappingLogModalOpen,
    toggleOn: setOpenMappingLogModal,
    toggleOff: setCloseMappingLogModal,
  } = useToggleState(false);

  const onRemoveExistingMappingsClick = useCallback(
    (mappedRoom: ExtendedMappedInternalRoom) => {
      setOpenDeleteConfirmationModal();
      setSelectedMappedRoom(mappedRoom);
    },
    [setOpenDeleteConfirmationModal],
  );

  const confirmMappedRoomDelete = useCallback(() => {
    onRemoveMappedRoom(selectedMappedRoom);
    setCloseDeleteConfirmationModal();
  }, [onRemoveMappedRoom, selectedMappedRoom, setCloseDeleteConfirmationModal]);

  const showConfirmVerifyRoomMapping = useCallback(
    (mappedRoom: ExtendedMappedInternalRoom) => {
      setVerifyMappedRoom(mappedRoom);
      setOpenVerifyConfirmationModal();
    },
    [setOpenVerifyConfirmationModal],
  );

  const confirmMappedRoomVerify = useCallback(async () => {
    if (verifyMappedRoom) {
      onVerifyMappedRoom(verifyMappedRoom);
      setCloseVerifyConfirmationModal();
    }
  }, [onVerifyMappedRoom, setCloseVerifyConfirmationModal, verifyMappedRoom]);

  const showMappingLogs = useCallback(
    async (room: Room, mappedRoom: MappedInternalRoom) => {
      setLogsRoom(room);
      setMappingLogsRoom(mappedRoom);
      setOpenMappingLogModal();
    },
    [setOpenMappingLogModal],
  );

  const hasBedbankMappings = room.mappedRooms.some((mr) => mr.internalService.toLowerCase() === 'bedbank');
  const hasReservationMappings = room.mappedRooms.some((mr) => mr.internalService.toLowerCase() === 'reservation');

  const flattenedMappedRooms: Array<ExtendedMappedInternalRoom> = room.mappedRooms.map((mr) => ({
    mappedRoomId: mr.mappedRoomId,
    name: mr.name,
    internalRoomId: mr.internalRoomId,
    roomId: room.id,
    internalService: mr.internalService,
    internalId: mr.internalId,
    supplierRooms: mr.supplierRooms,
    verified: mr.verified,
  }));

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        backgroundColor: !isSelected ? 'grey.100' : undefined,
        borderColor: isSelected ? 'info.light' : undefined,
      }}
    >
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" width="100%" spacing={2} alignItems="center">
          <FormControlLabel
            checked={isSelected}
            onChange={() => onSelect(room)}
            control={<Radio />}
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6">{room.name}</Typography>
              </Stack>
            }
            componentsProps={{ typography: { variant: 'h6' } }}
          />

          <Stack direction="row" spacing={1} ml="auto">
            {hasBedbankMappings && <Chip label="Bedbank" color="primary" size="small" variant="outlined" />}
            {hasReservationMappings && <Chip label="Reservation" color="primary" size="small" variant="outlined" />}
          </Stack>
        </Stack>
        <Stack direction="column" gap={2}>
          <Stack direction="column">
            {flattenedMappedRooms.map((mappedRoom) => (
              <Accordion
                key={mappedRoom.internalRoomId}
                defaultExpanded={!mappedRoom.verified}
                sx={{
                  borderColor: mappedRoom.verified ? 'success.light' : 'warning.light',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" gap={1}>
                    {mappedRoom.verified && <Chip label="Verified" color="success" size="small" />}
                    {!mappedRoom.verified && <Chip label="Unverified" color="warning" size="small" />}
                    <span>
                      ({titleCase(mappedRoom.internalService)}) - {mappedRoom.name}
                      <br />
                      {mappedRoom.internalId}
                    </span>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {mappedRoom.supplierRooms.map((supplierRoom) => (
                    <SupplierRoomListElement key={supplierRoom.id} supplierRoom={supplierRoom} showNameInTitle />
                  ))}
                </AccordionDetails>
                <AccordionActions>
                  <Box>
                    <Tooltip title="Show verification history">
                      <Button
                        startIcon={<ListAltIcon />}
                        color="info"
                        onClick={() => showMappingLogs(room, mappedRoom)}
                      >
                        Mapping Logs
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box ml="auto">
                    <Tooltip title="Remove Existing Mapping For Source Room">
                      <Button
                        startIcon={<DeleteForever />}
                        onClick={() => onRemoveExistingMappingsClick(mappedRoom)}
                        variant="contained"
                        color="error"
                      >
                        Remove
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box ml="auto">
                    {!mappedRoom.verified && (
                      <Tooltip title="Verify Room Mapping">
                        <Button
                          startIcon={<CheckCircleOutline />}
                          onClick={() => showConfirmVerifyRoomMapping(mappedRoom)}
                          variant="contained"
                          color="success"
                        >
                          Verify
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </AccordionActions>
              </Accordion>
            ))}
          </Stack>
          {newMappings?.internalRoomIds?.length > 0 && (
            <>
              <Divider />
              <Stack direction="column" gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" color="info.main">
                    New Mappings
                  </Typography>
                  <Button startIcon={<DeleteForever />} onClick={clearNewMappings} variant="contained" color="error">
                    Clear new Mappings
                  </Button>
                </Stack>
                <Stack direction="column">
                  {newMappings.internalRoomIds.map((internalRoomId) => (
                    <ToBeMappedRoomElement
                      key={`${internalRoomId}`}
                      internalRoomId={internalRoomId}
                      unsavedInternalRooms={unsavedInternalRooms}
                    />
                  ))}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Dialog open={isDeleteConfirmationModalOpen} onClose={() => setCloseDeleteConfirmationModal()}>
        {isDeleteConfirmationModalOpen && (
          <>
            <DialogTitle>Are you sure you want to delete this verified mapping?</DialogTitle>
            <Stack direction="row" justifyContent="space-between" p={3}>
              <Button onClick={() => setCloseDeleteConfirmationModal()} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button onClick={confirmMappedRoomDelete} variant="contained" color="error">
                Confirm Delete
              </Button>
            </Stack>
          </>
        )}
      </Dialog>
      <Dialog open={isVerifyConfirmationModalOpen} onClose={() => setCloseVerifyConfirmationModal()}>
        {isVerifyConfirmationModalOpen && (
          <>
            <DialogTitle>Are you sure you want to verify this unverified mapping?</DialogTitle>
            <Stack direction="row" justifyContent="space-between" p={3}>
              <Button onClick={() => setCloseVerifyConfirmationModal()} variant="outlined" color="error">
                Cancel
              </Button>
              <Button onClick={confirmMappedRoomVerify} variant="contained" color="primary">
                Verify
              </Button>
            </Stack>
          </>
        )}
      </Dialog>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '100%',
            },
          },
        }}
        open={isMappingLogModalOpen}
        onClose={() => setCloseMappingLogModal()}
      >
        {isMappingLogModalOpen && (
          <>
            <DialogTitle>Mapping Logs</DialogTitle>
            <DialogContent>
              <MappingLogTable room={logsRoom} mappedRoom={mappingLogsRoom} />
            </DialogContent>
          </>
        )}
      </Dialog>
    </Paper>
  );
}
