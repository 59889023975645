import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import DownloadIcon from '@mui/icons-material/Download';
import { Alert, AlertTitle, Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';

import { Request as APIRequest } from '@luxuryescapes/contract-svc-tour/types/requests';
import { Response } from '@luxuryescapes/contract-svc-tour/types/response';

import Spinner from '~/components/Common/Spinner';
import TourPricingTableForm from '~/components/Tours/TourDetails/Forms/TourPricingTableForm';

import useToggleState from '~/hooks/useToggleState';

import { downloadTourOptionPriceHistory } from '~/services/ToursService';

interface TourOptionsDynamicPricing {
  tourOptionId: string;
  name: string;
}
interface Props {
  tourId: string;
}
const emptyPricing = { data: [] };

export default function TourDynamicPricingDetailsForm({ tourId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchRequestInstance,
    fetch: fetchTourPricing,
    patchRequestInstance,
    patch: patchTourPricing,
  } = useTourDetailsFormQuery(TourDetailsFormResources.TOUR_DYNAMIC_PRICINGS);

  const [result, setResult] = useState<Response.TourPricingDynamicFormResponse>(emptyPricing);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const tourOptions = useMemo<Array<TourOptionsDynamicPricing>>(() => {
    if (fetchRequestInstance.status === 'succeeded') {
      const tourOptionPricing = new Map<string, TourOptionsDynamicPricing>();
      fetchRequestInstance.result.data.forEach((pricing) => {
        if (!tourOptionPricing.has(pricing.tourOptionId)) {
          tourOptionPricing.set(pricing.tourOptionId, {
            tourOptionId: pricing.tourOptionId,
            name: pricing.tourOption.split('-')[0],
          });
        }
      });
      return Array.from(tourOptionPricing.values());
    }
  }, [fetchRequestInstance]);

  const downloadCSVPrices = async (tourOptionId) => {
    const response = await downloadTourOptionPriceHistory(tourOptionId);
    const filename = `prices-tour-option-${
      tourOptions.find((option) => option.tourOptionId === tourOptionId).name
    }.csv`;
    try {
      await fileDownload(response, filename);
      enqueueSnackbar('Price history downloaded successfully.', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Price history download failed.', {
        variant: 'error',
      });
    }
  };

  const { isToggled: isViewMode, toggle } = useToggleState(true);

  const onSubmit = useCallback(
    (formData: Array<APIRequest.TourPricingFormDataV2>) => {
      patchTourPricing(tourId, formData);
    },
    [patchTourPricing, tourId],
  );

  useEffect(() => {
    fetchTourPricing(tourId);
  }, [fetchTourPricing, tourId]);

  useEffect(() => {
    switch (patchRequestInstance.status) {
      case 'succeeded':
        enqueueSnackbar('Tour pricing updated successfully.', {
          variant: 'success',
        });
        break;
      case 'failed':
        enqueueSnackbar(`Submission failed! ${patchRequestInstance.error}`, {
          variant: 'error',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequestInstance.status, enqueueSnackbar]);

  useEffect(() => {
    if (patchRequestInstance.status === 'succeeded') {
      setResult(patchRequestInstance.result);
    }
    if (fetchRequestInstance.status === 'succeeded' && patchRequestInstance.status === 'uninitiated') {
      setResult(fetchRequestInstance.result);
    }
  }, [fetchRequestInstance, patchRequestInstance]);

  if (fetchRequestInstance.status === 'pending' || fetchRequestInstance.status === 'uninitiated') {
    return <Spinner />;
  }

  if (fetchRequestInstance.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" variant="outlined" onClick={() => fetch(tourId)}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the form.</AlertTitle>
        {fetchRequestInstance.error}
      </Alert>
    );
  }

  return (
    <Box display="flex" gap={4} flexDirection="column" sx={{ width: 'fit-content' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Tour pricing summary</Typography>
        <Button variant="outlined" onClick={toggle}>
          {isViewMode ? 'Edit dynamic pricing' : 'View pricing'}
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Button onClick={handleOpen}>
          <DownloadIcon />
          Download per tour option
        </Button>
        <Menu anchorEl={anchorEl} open={showMenu} onClose={handleClose}>
          {tourOptions?.map((data, index) => (
            <MenuItem key={index} onClick={() => downloadCSVPrices(data.tourOptionId)}>
              {data.name}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <TourPricingTableForm
        pricing={result.data}
        readonly={isViewMode}
        onUpdate={onSubmit}
        disableSave={patchRequestInstance.status === 'pending'}
      />
    </Box>
  );
}
