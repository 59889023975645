import React, { useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import { SupplierRoom } from '~/services/AccommodationService';

import { titleCase } from '~/utils/stringUtils';

interface Props {
  supplierRoom: SupplierRoom;
  showNameInTitle?: boolean;
}

export default function SupplierRoomListElement({ supplierRoom, showNameInTitle }: Props) {
  const title = useMemo(() => {
    if (showNameInTitle) {
      return `${titleCase(supplierRoom.supplier)} - ${supplierRoom.name} - ${supplierRoom.supplierId}`;
    }
    return `${titleCase(supplierRoom.supplier)} - ${supplierRoom.supplierId}`;
  }, [showNameInTitle, supplierRoom]);

  return (
    <Accordion
      key={supplierRoom.id}
      defaultExpanded={false}
      sx={{
        borderColor: 'grey.400',
        borderWidth: '2px',
        borderStyle: 'solid',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" gap={1}>
          <span>{title}</span>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" key={supplierRoom.id}>
          <Box>
            <Typography>
              <strong>Supplier:</strong> {titleCase(supplierRoom.supplier)}
            </Typography>
            <Typography>
              <strong>Supplier ID:</strong> {supplierRoom.supplierId}
            </Typography>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
