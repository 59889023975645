import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { ListItemText, MenuItem, Select, Stack } from '@mui/material';

import {
  COMMISSION_RULE_CONDITION_TYPES,
  CommissionOption,
  CommissionsConditionTypes,
  RuleCondition,
  ruleConditionsToTextMap,
} from '~/consts/agentHub';

import AgentHubCommissionsAffiliationInput from './AgentHubCommissionsAffiliationInput';
import AgentHubCommissionsAgencyInput from './AgentHubCommissionsAgencyInput';
import AgentHubCommissionsAgentInput from './AgentHubCommissionsAgentInput';
import AgentHubCommissionsLocationInput from './AgentHubCommissionsLocationInput';
import AgentHubCommissionsMarginInput from './AgentHubCommissionsMarginInput';
import AgentHubCommissionsProductTypeInput from './AgentHubCommissionsProductTypeInput';
import AgentHubCommissionsTextInput from './AgentHubCommissionsTextInput';
import AgentHubCommissionsTierInput from './AgentHubCommissionsTierInput';

interface Props {
  addCondition: (condition: RuleCondition, replace?: boolean) => void;
  ruleConditions: CommissionOption;
  conditionType: CommissionsConditionTypes;
  visibleConditions: Set<CommissionsConditionTypes>;
  handleChangeCondition: (prevType: CommissionsConditionTypes, type: CommissionsConditionTypes) => void;
}

export function AddNewConditionForm({
  conditionType,
  ruleConditions,
  addCondition,
  handleChangeCondition,
  visibleConditions,
}: Props) {
  const [selectedConditionType, setSelectedConditionType] = useState(conditionType || null);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Stack direction="column" spacing={1}>
      <Select
        value={selectedConditionType}
        name="condition-type"
        labelId="agent-hub-commissions-condition-type-input-label"
        placeholder="Condition Type"
        onChange={(event) => {
          const newConditionType = event.target.value as CommissionsConditionTypes;
          if (visibleConditions.has(newConditionType)) {
            return enqueueSnackbar('This condition is already set', { variant: 'warning' });
          }
          setSelectedConditionType(newConditionType);
          handleChangeCondition(conditionType, newConditionType);
        }}
        renderValue={(value) => ruleConditionsToTextMap.get(value)}
      >
        {COMMISSION_RULE_CONDITION_TYPES.map((type) => (
          <MenuItem key={type} value={type}>
            <ListItemText primary={ruleConditionsToTextMap.get(type)} />
          </MenuItem>
        ))}
      </Select>

      {selectedConditionType === 'agency' && (
        <AgentHubCommissionsAgencyInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'agent' && (
        <AgentHubCommissionsAgentInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'location' && (
        <AgentHubCommissionsLocationInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'productType' && (
        <AgentHubCommissionsProductTypeInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'vendor' && (
        <AgentHubCommissionsTextInput
          conditionType="vendor"
          existingConditions={ruleConditions}
          setCreatedConditions={addCondition}
        />
      )}

      {selectedConditionType === 'offer' && (
        <AgentHubCommissionsTextInput
          conditionType="offer"
          existingConditions={ruleConditions}
          setCreatedConditions={addCondition}
        />
      )}

      {selectedConditionType === 'affiliation' && (
        <AgentHubCommissionsAffiliationInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'margin' && (
        <AgentHubCommissionsMarginInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}

      {selectedConditionType === 'tier' && (
        <AgentHubCommissionsTierInput existingConditions={ruleConditions} setCreatedConditions={addCondition} />
      )}
    </Stack>
  );
}
