import { sortBy } from 'lodash';

import { getRegions } from '@luxuryescapes/lib-regions';

import { CommissionOption, REGION_SORT_ORDER, RuleConditions } from '~/consts/agentHub';

export function buildRuleParam(rules: RuleConditions) {
  return Object.entries(rules).reduce((acc, [key, conditions]) => {
    if (conditions.length <= 0) {
      return acc;
    }
    return { ...acc, [key]: conditions.map((condition) => condition.value) };
  }, {});
}

export const groupByValue = (acc: Map<string | number, string>, curr: { value: string; label: string }) => {
  acc.set(curr.value, curr.label);
  return acc;
};

export const getSortedRegions = () =>
  sortBy(
    getRegions(),
    (region) => {
      const regionOrder = REGION_SORT_ORDER.indexOf(region.name);
      if (regionOrder === -1) {
        // Put it at the bottom of the list if it's not in the desired order
        return 999;
      }
      return regionOrder;
    },
    'asc',
  );

export function renderConditionText(condition: keyof RuleConditions, value: CommissionOption) {
  if (typeof value === 'string') {
    return value;
  }
  switch (condition) {
    case 'agency':
    case 'agent':
    case 'offer':
    case 'vendor':
    case 'location':
    case 'productType':
    case 'affiliation':
    case 'tier':
      return value.map((item) => item.label).join(', ');
    case 'margin':
      return `Min: ${value[0].value}%, Max: ${value[1].value}%`;
    default:
      return '';
  }
}
