import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';

interface Props {
  sppEnabled: boolean;
}

export default function AccommodationStatusIndicatorIcon({ sppEnabled }: Props) {
  return (
    <>
      {sppEnabled && (
        <Tooltip title="Live">
          <CircleIcon color="success" />
        </Tooltip>
      )}
      {!sppEnabled && (
        <Tooltip title="Unavailable">
          <CircleIcon color="disabled" />
        </Tooltip>
      )}
    </>
  );
}
