import { getRegions } from '@luxuryescapes/lib-regions';

const MEMBERSHIP_BRANDS: Array<App.MembershipBrand> = ['luxuryescapes'];

const MEMBERSHIP_REGIONS = getRegions()
  .filter((region) => region.code !== 'IN')
  .map((region) => region.code as App.MembershipRegion);

export const LUX_PLUS = {
  PROGRAM_NAME: 'LuxPlus+',
  REGIONS: MEMBERSHIP_REGIONS,
  BRANDS: MEMBERSHIP_BRANDS,
} as const;
