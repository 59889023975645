import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import currencyFormatter from '~/utils/currencyFormatter';

interface Props {
  surchargeAmount: number;
  currencyCode: string;
  onChange?: any;
}

export default function RefundSurcharge({ surchargeAmount, currencyCode, onChange }: Props) {
  return (
    <FormControl required>
      <FormLabel
        sx={{
          fontWeight: 'bold',
        }}
      >
        Should the surcharge {currencyFormatter(currencyCode, surchargeAmount, 2, 'code')} be refunded to customer?
      </FormLabel>
      <RadioGroup name="shouldSurchargeBeRefunded" row onChange={onChange ? onChange : undefined}>
        <FormControlLabel
          data-cy="refund-surcharge-true"
          key="should-surcharge-be-refunded-yes"
          className="T-is-refunded-surcharge-yes"
          value
          control={
            <Radio
              inputProps={{
                required: true,
              }}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          data-cy="refund-surcharge-false"
          key="should-surcharge-be-refunded-no"
          className="T-is-refunded-surcharge-yes"
          value={false}
          control={
            <Radio
              inputProps={{
                required: true,
              }}
            />
          }
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
}
