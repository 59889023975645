import React, { useCallback, useMemo } from 'react';

import { Button } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import useToggleState from '~/hooks/useToggleState';

import { ledBrands } from '../../../../consts/brands';

import LEDScheduleBulkUpdate from './LEDScheduleBulkUpdate';

const ledBrandValues = new Set(ledBrands.map((brand) => brand.value));

interface Props {
  offerId: string;
  schedules: App.Schedule[];
  type: string;
  shouldMigrateSchedule: boolean;
  onOpen: () => void;
  onSaveFinished: () => void;
  onError: () => void;
}

export default function LEDScheduleBulkUpdateContainer({
  schedules: allSchedules,
  offerId,
  type,
  shouldMigrateSchedule,
  onOpen,
  onSaveFinished,
  onError,
}: Props) {
  const { isToggled: isOpen, toggleOn: open, toggleOff: close } = useToggleState();

  const openWithCallback = useCallback(() => {
    onOpen();
    open();
  }, [onOpen, open]);

  const currentSchedules = useMemo(
    () => allSchedules.filter((schedule) => type && schedule.type === type && ledBrandValues.has(schedule.brand)),
    [allSchedules, type],
  );

  return (
    <>
      <LEDScheduleBulkUpdate
        isOpen={isOpen}
        currentSchedules={currentSchedules}
        offerId={offerId}
        type={type}
        onClose={close}
        onSaveFinished={onSaveFinished}
        onError={onError}
      />

      <PermissionedComponent>
        <Button
          type="button"
          className="add-schedule"
          variant="contained"
          color="secondary"
          onClick={openWithCallback}
          disabled={shouldMigrateSchedule}
        >
          Bulk Update
        </Button>
      </PermissionedComponent>
    </>
  );
}
