import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import { reportError } from '~/utils/reportError';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  userId: string;
};

interface PhoneNumberChangeHistory {
  old_phone_prefix: string;
  old_phone_number: string;
  new_phone_prefix: string;
  new_phone_number: string;
  completed_at: string;
  requested_at: string;
}

const columns: Array<GridColDef> = [
  {
    field: 'old_phone_prefix',
    headerName: 'Old Phone Prefix',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'old_phone_number',
    headerName: 'Old Phone Number',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'new_phone_prefix',
    headerName: 'New Phone Prefix',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'new_phone_number',
    headerName: 'New Phone Number',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'completed_at',
    headerName: 'Completed At',
    flex: 2,
    valueFormatter: (value) => formatDateOrdinalWithClock(value),
    display: 'flex',
  },
  {
    field: 'requested_at',
    headerName: 'Requested At',
    flex: 2,
    valueFormatter: (value) => formatDateOrdinalWithClock(value),
    display: 'flex',
  },
];

function UserPhoneNumberChangeTable({ userId }: Props) {
  const [result, setResult] = useState<Array<PhoneNumberChangeHistory> | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    UsersService.getPhoneNumberChangeHistory(userId)
      .then((response) => setResult(response.result))
      .catch((error) => reportError(error))
      .finally(() => setLoading(false));
  }, [userId]);

  return (
    <Box>
      <DataGrid
        rows={result || []}
        columns={columns}
        getRowId={() => uuid()}
        autoHeight
        loading={loading}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5]}
        paginationModel={{ pageSize: 5, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        disableColumnMenu
        disableRowSelectionOnClick
        density="compact"
        slots={{ pagination: GridPagination }}
      />
    </Box>
  );
}

export default UserPhoneNumberChangeTable;
