import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import PublicOfferService from '~/services/PublicOfferService';
import { isAfter, isBefore } from '~/services/TimeService';

import getDateDayDiff from '~/utils/getDateDayDiff';

import BaseOfferBox from './BaseOfferBox';

interface Props {
  bk: string;
  region: string;
  index: number;
  onRemove?: () => void;
}

function LEOfferBox({ bk, region, index, onRemove }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: App.State) => state.tenant);
  const [offer, setOffer] = useState<Awaited<ReturnType<typeof PublicOfferService.getOfferById>>>();

  useEffect(() => {
    PublicOfferService.getOfferById(bk, region, tenant.brand)
      .then((offer) => {
        const endOfOnlinePurchaseSchedule = offer?.listSchedule?.end;
        if (endOfOnlinePurchaseSchedule) {
          const offerValid = isAfter(endOfOnlinePurchaseSchedule);

          if (!offerValid) {
            onRemove && onRemove();
            return;
          }
        }
        setOffer(offer);
      })
      .catch((err) => {
        if (err.status === 404) {
          onRemove && onRemove();
          return;
        }
        enqueueSnackbar(`Offer ${bk} could not be found`, { variant: 'error' });
      });
  }, [bk, enqueueSnackbar, onRemove, region, tenant.brand]);

  if (!offer) {
    return <BaseOfferBox tenant={tenant} index={index} />;
  }

  const isInSchedule =
    (isAfter(offer?.listSchedule?.end) && isBefore(offer?.listSchedule?.start)) ||
    (isAfter(offer?.luxPlusSchedule?.end) && isBefore(offer?.luxPlusSchedule?.start));

  return (
    <BaseOfferBox
      tenant={tenant}
      title={offer.location.description}
      imageId={offer.images[0]?.id}
      subtitle={
        offer.subjectLine ||
        ('property' in offer
          ? [offer.property.geoData.administrativeAreaLevel1, offer.property.geoData.country].filter(Boolean).join(', ')
          : undefined)
      }
      path={`/offer/${offer.slug}/${bk}`}
      vertical={'property' in offer ? 'hotel' : 'tour'}
      index={index}
      remainingDays={isInSchedule ? getDateDayDiff(offer.listSchedule?.end) : 'OUT_OF_SCHEDULE'}
    />
  );
}

export default LEOfferBox;
