import React, { FormEventHandler, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Spinner from '~/components/Common/Spinner';
import PricingSummary from '~/components/Purchases/OrderDetail/OrderItem/PricingSummary';
import ReservationInfoCancellationPolicies from '~/components/Purchases/OrderDetail/OrderItem/ReservationInfo/ReservationInfoCancellationPolicies';

import {
  CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD,
  COLLECTION_REASONS,
  CUSTOM_REASON_SOURCE,
  EXPIRED_BNBL_PACKAGE,
  FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
  FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  INSURANCE_BREAKAGE,
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
  PACKAGE_AMENDMENT,
  REFUND_TEST_PURCHASE,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_SURCHARGE,
} from '~/consts/refund';

import useCurrentUser from '~/hooks/useCurrentUser';

import { cancelOrder, getOrderRefundInfo, refundStatus } from '~/services/OrdersService';

import asyncPoll from '~/utils/asyncPoll';
import isOrderRefundable from '~/utils/isOrderRefundable';

import { CollectionReason, EnrichedRefundMetadata, RefundMetadata, RefundPayload, VendorRefundTypes } from '../types';

import ChangeOfMindWithinRefundGuaranteePeriod from './Pages/ChangeOfMindWithinRefundGuaranteePeriod';
import ExpiredBNBLPackage from './Pages/ExpiredBNBLPackage';
import FailedRefundFromPaymentGateways from './Pages/FailedRefundFromPaymentGateways';
import FraudComplianceAndDispute from './Pages/FraudComplianceAndDispute';
import InsuranceBreakage from './Pages/InsuranceBreakage';
import LateChangeOfMindBadExperienceOrUnfortunateEvents, {
  RefundTabs,
} from './Pages/LateChangeOfMindBadExperienceOrUnfortunateEvents';
import MisleadingContentIncorrectPricingAndCustomerRetention from './Pages/MisleadingContentIncorrectPricingAndCustomerRetention';
import PackageAmendment from './Pages/PackageAmendment';
import RefundConfirmation from './Pages/RefundConfirmation';
import RefundReasons from './Pages/RefundReasons';
import RefundTestPurchase from './Pages/RefundTestPurchase';
import {
  buildRefundPayload,
  convertStrOrBoolToBool,
  formatModalHeader,
  getDefaultValues,
  getItemType,
  getVendorContribution,
  handleCustomFee,
  handleSelectNights,
  handleVendorPercentage,
} from './Utils/RefundUtils';

interface Props {
  order: Partial<App.Order> | any;
  item: App.OrderItem;
  closeRefundModal: () => void;
  roomId?: string;
  approver?: string;
  showModal: boolean;
}

export enum RefundSteps {
  Reason,
  Detail,
  Confirm,
}

interface RefundSubmitFormData {
  refundMethod: string;
  customerRefundNotification: string;
}

interface ChangeOfMindWithinRefundGuaranteePeriodFormData {
  comment: string;
  caseId: string;
  refundSubtype?: string;
}

interface InsuranceBreakageFormData {
  comment: string;
  caseId: string;
  refundSubtype?: string;
}

interface PackageAmendmentFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVCCBalance: string;
  shouldSurchargeBeRefunded: string;
  refundSubtype?: string;
}

interface MisleadingContentFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVCCBalance: string;
  shouldSurchargeBeRefunded: string;
  refundSubtype?: string;
}

interface FraudComplianceAndDisputeFormData {
  comment: string;
  caseId: string;
  markCancelled: string;
  liabilitySource: string;
  refundSubtype?: string;
}

interface FailedRefundFromPaymentGatewaysFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVCCBalance: string;
  shouldSurchargeBeRefunded: string;
  refundSubtype?: string;
}

interface RefundTestPurchaseFormData {
  comment: string;
  caseId: string;
  refundSubtype?: string;
}

interface ExpiredBNBLPackageFormData {
  comment: string;
  caseId: string;
  refundSubtype?: string;
}

interface LateChangeOfMindFormData {
  comment: string;
  caseId: string;
  feeOrCustom: string;
  percentageOrNumber: string;
  refundFee?: string;
  percentageRefund?: string;
  percentageRefundToCustomer?: string;
  numberOfNights?: string;
  totalRefundedToCustomer?: string;
  markCancelled: string;
  shouldSurchargeBeRefunded?: string;
  refundSubtype?: string;
  customVendorContribution?: string;
}

function generateLateChangeOfMindPayload(
  formData: LateChangeOfMindFormData,
  refundPayload: RefundPayload,
  refundMetadata: RefundMetadata,
) {
  const {
    comment,
    caseId,
    feeOrCustom,
    percentageOrNumber,
    refundFee,
    markCancelled,
    // percentage values
    percentageRefundToCustomer,
    customVendorContribution,
    // select nights values
    numberOfNights,
    totalRefundedToCustomer,
    shouldSurchargeBeRefunded,
    refundSubtype,
  } = formData;

  let updatedPayload = {
    ...refundPayload,
    ticket_id: caseId,
    comment,
  };

  if (parseInt(feeOrCustom) === RefundTabs.FULL_REFUND_WITH_FEE) {
    updatedPayload = handleCustomFee({
      inputPayload: updatedPayload,
      refundFee,
      refundMetadata,
      markCancelled,
      shouldSurchargeBeRefunded,
    });
  } else {
    // custom refund
    updatedPayload.variant_of_refund = VARIANT_OF_REFUND_PARTIAL;
    updatedPayload.accounting_metadata[0].source = CUSTOM_REASON_SOURCE;
    // we never consider surcharges in the custom scenario
    updatedPayload = removeSurchargeFromPayload(updatedPayload, refundMetadata.has_surcharge);
    if (percentageOrNumber === VendorRefundTypes.percentage) {
      updatedPayload = handleVendorPercentage({
        inputPayload: updatedPayload,
        customVendorContribution,
        percentageRefundToCustomer,
        refundMetadata,
      });
    } else {
      // select nights
      updatedPayload = handleSelectNights({
        inputPayload: updatedPayload,
        totalRefundedToCustomer,
        numberOfNights,
      });
    }
  }

  if (refundSubtype) {
    updatedPayload.additional_info.refund_subtype = refundSubtype;
  }
  updatedPayload.mark_cancelled = convertStrOrBoolToBool(markCancelled);
  return updatedPayload;
}

function removeSurchargeFromPayload(refundPayload, hasSurcharge: boolean) {
  if (!hasSurcharge) {
    return refundPayload;
  }
  const refundPayloadCopy = { ...refundPayload };
  const surchargeRefundMeta = refundPayloadCopy.accounting_metadata.find((data) =>
    data.charge_component_key.includes('surcharge'),
  );

  if (!surchargeRefundMeta) {
    // occurs when user goes backwards, after surcharge already removed
    return refundPayload;
  }

  refundPayloadCopy.amount -= surchargeRefundMeta.cash_amount;
  refundPayloadCopy.accounting_metadata = refundPayloadCopy.accounting_metadata.filter(
    (data) => !data.charge_component_key.includes('surcharge'),
  );
  return refundPayloadCopy;
}

function removeNonSurchargeFromPayload(refundPayload) {
  const refundPayloadCopy = { ...refundPayload };
  const nonSurchargeRefundMeta = refundPayloadCopy.accounting_metadata.find(
    (data) => !data.charge_component_key.includes('surcharge'),
  );
  refundPayloadCopy.amount -= nonSurchargeRefundMeta.cash_amount;
  refundPayloadCopy.accounting_metadata = refundPayloadCopy.accounting_metadata.filter((data) =>
    data.charge_component_key.includes('surcharge'),
  );
  return refundPayloadCopy;
}

const SidebarToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  width: 40,
  height: 40,
  zIndex: 1200,
  transition: 'right 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const SidebarDrawer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: 'absolute',
  top: 0,
  left: '100%',
  height: '100%',
  width: isOpen ? 300 : 0,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  borderLeft: `1px solid ${theme.palette.divider}`,
  transition: 'width 0.3s ease',
  overflow: 'hidden',
  boxShadow: theme.shadows[1],
}));

const SidebarContent = styled(Box)({
  width: 300,
  height: '100%',
  overflow: 'auto',
});

export default function AccommodationRefundV2({
  order,
  item,
  closeRefundModal,
  roomId = undefined,
  approver = '',
  showModal,
}: Props) {
  // Accounting Metadata Guide:
  // [0] = Item Metadata
  // [1] = Surcharge Metadata

  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const [refundPayload, setRefundPayload] = useState<RefundPayload>();
  const [step, setStep] = useState(RefundSteps.Reason);
  const [isLoading, setIsLoading] = useState(false);
  const [refundMetadata, setRefundMetadata] = useState<EnrichedRefundMetadata>(undefined);
  const [reasonInfo, setReasonInfo] = useState<CollectionReason>();
  const [vendorContribution, setVendorContribution] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // its objecting to LE but lE looks worse
  // eslint-disable-next-line react/hook-use-state
  const [LEProfit, setLEProfit] = useState(0);
  // eslint-disable-next-line react/hook-use-state
  const [LEContribution, setLEContribution] = useState(0);
  const isAccommodationRefundable = isOrderRefundable(order, {});
  const customerName = order.customer_full_name ?? `${order.customer_given_name} ${order.customer_surname}`;
  const currentUserName = user.user.fullName;
  const hasSurcharge = refundMetadata ? convertStrOrBoolToBool(refundMetadata.has_surcharge) : false;
  const [sidebarOpen, setSidebarOpen] = useState(true);

  function updateVendorContribution(finalRefundAmount: number, updatedVendorContribution: number) {
    const roundedVendorContribution = Number(updatedVendorContribution.toFixed(2));
    const difference = finalRefundAmount - roundedVendorContribution;
    setVendorContribution(roundedVendorContribution);
    if (difference >= 0) {
      setLEContribution(Number(difference.toFixed(2)));
      setLEProfit(0);
    } else {
      setLEProfit(Number(Math.abs(difference).toFixed(2)));
      setLEContribution(0);
    }
  }

  const handleReasonSubmission = useCallback(
    async (selectedReason) => {
      setIsLoading(true);
      const refundMetadataResponse = await getOrderRefundInfo({
        orderId: order.id_orders,
        itemId: item.id,
        roomId: roomId,
        enriched: true,
        approver: approver,
        selectedReasonKey: selectedReason,
      });

      // update the default
      setReasonInfo(COLLECTION_REASONS[selectedReason]);
      setRefundMetadata(refundMetadataResponse);
      const defaultPayload = getDefaultValues(refundMetadataResponse, selectedReason, currentUserName, approver);
      // some default flash payload logic moved here
      if (refundMetadataResponse.has_reservation && COLLECTION_REASONS[selectedReason].key !== INSURANCE_BREAKAGE) {
        defaultPayload.send_refund_notification = true;
        defaultPayload.send_customer_refund_notification = true;
      }

      setRefundPayload(defaultPayload);
      // set a default vendor contribution amount
      const updatedVendorContribution = getVendorContribution(refundMetadataResponse, defaultPayload, item);
      updateVendorContribution(refundMetadataResponse.total_sale_price, updatedVendorContribution);
      setStep(step + 1);
      setIsLoading(false);
    },
    [order.id_orders, item, roomId, approver, currentUserName, step],
  );

  const handleClose = useCallback(() => {
    const confirmedClose = window.confirm('Are you sure? All data will be reset');
    if (confirmedClose) {
      closeRefundModal();
    }
  }, [closeRefundModal]);

  const incrementStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const decrementStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleChangeOfMindWithinRefundGuaranteePeriodSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment, refundSubtype } = Object.fromEntries([
        ...formData,
      ]) as unknown as ChangeOfMindWithinRefundGuaranteePeriodFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleInsuranceBreakageSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment, refundSubtype } = Object.fromEntries([
        ...formData,
      ]) as unknown as InsuranceBreakageFormData;

      let updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
        amount: 0,
        merchant_fee_amount: 0,
      };

      // we don't consider surcharge in insurance breakage
      updatedPayload = removeSurchargeFromPayload(updatedPayload, refundMetadata.has_surcharge);

      updatedPayload.accounting_metadata[0].cash_amount = 0;
      updatedPayload.amount = 0;

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handlePackageAmendmentSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const {
        refundAmountToCustomer,
        variantOfRefund,
        markCancelled,
        caseId,
        comment,
        updateVCCBalance,
        shouldSurchargeBeRefunded,
        refundSubtype,
      } = Object.fromEntries([...formData]) as unknown as PackageAmendmentFormData;

      let filteredPayload = { ...refundPayload };

      // by default, if there is surcharge information it will be included, so we need to filter it out
      // for the partial cases, or the Don't Include Surcharge option
      if (!convertStrOrBoolToBool(shouldSurchargeBeRefunded) || refundAmountToCustomer) {
        filteredPayload = removeSurchargeFromPayload(refundPayload, refundMetadata.has_surcharge);
      }

      if (variantOfRefund === VARIANT_OF_REFUND_SURCHARGE) {
        filteredPayload = removeNonSurchargeFromPayload(refundPayload);
        filteredPayload.amount -= filteredPayload.merchant_fee_amount;
      }

      const finalRefundAmount = refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : filteredPayload.amount;
      const updatedPayload: RefundPayload = {
        ...filteredPayload,
        amount: finalRefundAmount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVCCBalance),
      };

      // these should be defaulted to amount of item
      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleMisleadingContentSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const {
        refundAmountToCustomer,
        variantOfRefund,
        markCancelled,
        caseId,
        comment,
        updateVCCBalance,
        shouldSurchargeBeRefunded,
        refundSubtype,
      } = Object.fromEntries([...formData]) as unknown as MisleadingContentFormData;

      let filteredPayload = { ...refundPayload };

      // by default, if there is surcharge information it will be included, so we need to filter it out
      // for the partial cases, or the Don't Include Surcharge option
      if (!convertStrOrBoolToBool(shouldSurchargeBeRefunded) || refundAmountToCustomer) {
        filteredPayload = removeSurchargeFromPayload(refundPayload, refundMetadata.has_surcharge);
      }

      if (variantOfRefund === VARIANT_OF_REFUND_SURCHARGE) {
        filteredPayload = removeNonSurchargeFromPayload(refundPayload);
        filteredPayload.amount -= filteredPayload.merchant_fee_amount;
      }

      const finalRefundAmount = refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : filteredPayload.amount;
      const updatedPayload: RefundPayload = {
        ...filteredPayload,
        amount: finalRefundAmount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVCCBalance),
      };

      // these should be defaulted to amount of item
      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleFraudComplianceAndDisputeSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { comment, caseId, markCancelled, liabilitySource, refundSubtype } = Object.fromEntries([
        ...formData,
      ]) as unknown as FraudComplianceAndDisputeFormData;

      const updatedPayload: RefundPayload = {
        ...refundPayload,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
      };

      const metadataWithSource = updatedPayload.accounting_metadata.map((metadata) => {
        return {
          ...metadata,
          source: liabilitySource,
        };
      });

      updatedPayload.accounting_metadata = metadataWithSource;

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);
      incrementStep();
    },
    [incrementStep, refundPayload, setRefundPayload],
  );

  const handleFailedRefundFromPaymentGatewaysSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const {
        refundAmountToCustomer,
        variantOfRefund,
        markCancelled,
        caseId,
        comment,
        updateVCCBalance,
        shouldSurchargeBeRefunded,
        refundSubtype,
      } = Object.fromEntries([...formData]) as unknown as FailedRefundFromPaymentGatewaysFormData;

      let filteredPayload = { ...refundPayload };

      // by default, if there is surcharge information it will be included, so we need to filter it out
      // for the partial cases, or the Don't Include Surcharge option
      if (!convertStrOrBoolToBool(shouldSurchargeBeRefunded) || refundAmountToCustomer) {
        filteredPayload = removeSurchargeFromPayload(refundPayload, refundMetadata.has_surcharge);
      }

      if (variantOfRefund === VARIANT_OF_REFUND_SURCHARGE) {
        filteredPayload = removeNonSurchargeFromPayload(refundPayload);
        filteredPayload.amount -= filteredPayload.merchant_fee_amount;
      }

      const finalRefundAmount = refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : filteredPayload.amount;
      const updatedPayload: RefundPayload = {
        ...filteredPayload,
        amount: finalRefundAmount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVCCBalance),
      };

      // these should be defaulted to amount of item
      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : updatedPayload.accounting_metadata[0].cash_amount;

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleRefundTestPurchaseSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { comment, caseId, refundSubtype } = Object.fromEntries([
        ...formData,
      ]) as unknown as RefundTestPurchaseFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleExpiredBNBLPackageSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment, refundSubtype } = Object.fromEntries([
        ...formData,
      ]) as unknown as ExpiredBNBLPackageFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };

      if (refundSubtype) {
        updatedPayload.additional_info = {
          refund_subtype: refundSubtype,
        };
      }
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleLateChangeOfMindSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const formObject = Object.fromEntries([...formData]) as unknown as LateChangeOfMindFormData;
      const updatedPayload = generateLateChangeOfMindPayload(formObject, refundPayload, refundMetadata);

      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleRefundSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { refundMethod, customerRefundNotification } = Object.fromEntries([
        ...formData,
      ]) as unknown as RefundSubmitFormData;
      const updatedPayload = {
        ...refundPayload,
        refund_method: refundMethod,
        send_customer_refund_notification: customerRefundNotification,
        room_id: null, // always null for flash
      };

      const mappedRefund = buildRefundPayload(updatedPayload);

      try {
        setIsSubmitting(true);
        await cancelOrder(order.id_orders, item.id, mappedRefund);

        try {
          await asyncPoll({
            apiCall: () => refundStatus(order.id_orders, item.id),
            validateFunction: async ({ jobState }) => {
              return jobState === undefined;
            },
            maxTime: 30000,
          });
        } catch (e) {
          // We need to continue process whether refund checking request failed or not
        } finally {
          closeRefundModal();
          location.reload();
        }
      } catch (err) {
        enqueueSnackbar(`Failed to process refund: ${err.message}`);
      } finally {
        setIsSubmitting(false);
      }
    },
    [enqueueSnackbar, item.id, order.id_orders, refundPayload, closeRefundModal],
  );

  const handleLateChangeOfMindFieldChange = useCallback(
    (formData) => {
      // try and update the refund payload, if it hits any errors we are assuming its because we don't have enough information
      try {
        const formObject = Object.fromEntries([...formData]) as unknown as LateChangeOfMindFormData;
        const updatedPayload = generateLateChangeOfMindPayload(formObject, refundPayload, refundMetadata);

        let customerRefundAmount: undefined | string = undefined;
        // we don't want agents to be able to set vendor contribution higher than customer refund amount
        // this only works for percentage though, ignore it for nights
        if (formObject.percentageOrNumber === VendorRefundTypes.percentage) {
          customerRefundAmount = formObject.percentageRefundToCustomer;
        }

        const updatedVendorContribution = getVendorContribution(
          refundMetadata,
          updatedPayload,
          item,
          customerRefundAmount,
        );
        updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      } catch (e) {
        // assume this means not enough of the form has been filled out
        // to successfully generate this value e.g comment, caseId
        // but we don't want this to show to users
        console.warn('Could not update form', e);
      }
    },
    [item, refundMetadata, refundPayload],
  );

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Dialog
      open={showModal}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{
        sx: {
          position: 'relative',
          overflow: 'visible',
        },
      }}
    >
      <SidebarToggleButton onClick={toggleSidebar} sx={{ right: sidebarOpen ? -360 : -60 }}>
        {sidebarOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </SidebarToggleButton>

      <DialogTitle>{formatModalHeader(step, refundMetadata)}</DialogTitle>

      {/* Main Content */}
      {!isLoading && step === RefundSteps.Reason && (
        <RefundReasons
          itemType={getItemType(item)}
          handleReasonSubmission={handleReasonSubmission}
          isRefundable={isAccommodationRefundable}
          order={order}
        />
      )}
      {isLoading && <Spinner />}
      {!isLoading && step === RefundSteps.Detail && refundPayload && (
        <>
          {refundPayload?.reason === CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD && (
            <ChangeOfMindWithinRefundGuaranteePeriod
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              onSubmit={handleChangeOfMindWithinRefundGuaranteePeriodSubmit}
              reason={reasonInfo}
            />
          )}
          {refundPayload?.reason === PACKAGE_AMENDMENT && (
            <PackageAmendment
              currencyCode={refundMetadata.currency_code}
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              defaultVccChecked={refundPayload.update_vcc_balance}
              hasSurcharge={hasSurcharge}
              onSubmit={handlePackageAmendmentSubmit}
              reason={reasonInfo}
              surchargeAmount={refundMetadata.surcharge_metadata.cash_amount ?? undefined}
            />
          )}
          {refundPayload?.reason === MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION && (
            <MisleadingContentIncorrectPricingAndCustomerRetention
              currencyCode={refundMetadata.currency_code}
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              defaultVccChecked={refundPayload.update_vcc_balance}
              hasSurcharge={hasSurcharge}
              onSubmit={handleMisleadingContentSubmit}
              reason={reasonInfo}
              surchargeAmount={refundMetadata.surcharge_metadata.cash_amount ?? undefined}
            />
          )}
          {refundPayload?.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS && (
            <LateChangeOfMindBadExperienceOrUnfortunateEvents
              checkIn={item?.reservation?.check_in}
              checkOut={item?.reservation?.check_out}
              reservationId={item?.reservation?.id}
              currencyCode={order.currency_code}
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
              hasSurcharge={hasSurcharge}
              LEContribution={LEContribution}
              onSubmit={handleLateChangeOfMindSubmit}
              reason={reasonInfo}
              refundMetadata={refundMetadata}
              surchargeAmount={refundMetadata.surcharge_metadata.cash_amount ?? undefined}
              vendorContribution={vendorContribution}
            />
          )}
          {refundPayload?.reason === FRAUD_AND_COMPLIANCE_AND_DISPUTE && (
            <FraudComplianceAndDispute
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              onSubmit={handleFraudComplianceAndDisputeSubmit}
              reason={reasonInfo}
            />
          )}
          {refundPayload?.reason === FAILED_REFUND_FROM_PAYMENT_GATEWAYS && (
            <FailedRefundFromPaymentGateways
              currencyCode={refundMetadata.currency_code}
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              defaultVccChecked={refundPayload.update_vcc_balance}
              hasSurcharge={hasSurcharge}
              onSubmit={handleFailedRefundFromPaymentGatewaysSubmit}
              reason={reasonInfo}
              surchargeAmount={refundMetadata.surcharge_metadata.cash_amount ?? undefined}
            />
          )}
          {refundPayload?.reason === REFUND_TEST_PURCHASE && (
            <RefundTestPurchase
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              onSubmit={handleRefundTestPurchaseSubmit}
              reason={reasonInfo}
            />
          )}
          {refundPayload?.reason === EXPIRED_BNBL_PACKAGE && (
            <ExpiredBNBLPackage
              currencyCode={refundMetadata.currency_code}
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              hasSurcharge={hasSurcharge}
              onSubmit={handleExpiredBNBLPackageSubmit}
              reason={reasonInfo}
            />
          )}
          {refundPayload?.reason === INSURANCE_BREAKAGE && (
            <InsuranceBreakage
              decrementStep={decrementStep}
              defaultCaseId={refundPayload.ticket_id}
              defaultComment={refundPayload.comment}
              onSubmit={handleInsuranceBreakageSubmit}
              reason={reasonInfo}
            />
          )}
        </>
      )}
      {!isLoading && step === RefundSteps.Confirm && (
        <RefundConfirmation
          currencyCode={order.currency_code}
          customerName={customerName}
          decrementStep={decrementStep}
          defaultCustomerRefundNotification={convertStrOrBoolToBool(refundPayload.send_customer_refund_notification)}
          LEContribution={LEContribution}
          LEProfit={LEProfit}
          onSubmit={handleRefundSubmit}
          refundMethods={reasonInfo.defaults.methods}
          refundPayload={refundPayload}
          vendorContribution={vendorContribution}
          isSubmitting={isSubmitting}
        />
      )}

      {/* Sidebar Drawer */}
      <SidebarDrawer isOpen={sidebarOpen}>
        <SidebarContent>
          <Box width={300} p={2}>
            <PricingSummary item={item} currencyCode={order.currency_code} />
            <Typography variant="subtitle1" fontWeight="bold">
              Cancellation Policy
            </Typography>
            <ReservationInfoCancellationPolicies item={item} />
          </Box>
        </SidebarContent>
      </SidebarDrawer>
    </Dialog>
  );
}
