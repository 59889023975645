import React from 'react';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';

import { SearchExperienceHead } from '~/components/Content/SearchRanking/SearchExperienceHead';
import { SearchHotelHead } from '~/components/Content/SearchRanking/SearchHotelHead';
import SearchHotelRow from '~/components/Content/SearchRanking/SearchHotelRow';
import { SearchUltraLuxHead } from '~/components/Content/SearchRanking/SearchOtherHead';
import SearchOtherRow from '~/components/Content/SearchRanking/SearchOtherRow';
import { SearchTourHead } from '~/components/Content/SearchRanking/SearchTourHead';
import SearchTourRow from '~/components/Content/SearchRanking/SearchTourRow';
import { SearchUnifiedHead } from '~/components/Content/SearchRanking/SearchUnifiedHead';
import SearchUnifiedRow from '~/components/Content/SearchRanking/SearchUnifiedRow';

import { EvVariant, HotelSearchList, OrderOffer, TourSearchList, UnifiedSearchList } from '~/services/SearchService';

import { SearchCruiseHead } from './SearchCruiseHead';
import SearchCruiseRow from './SearchCruiseRow';

interface CommonProps {
  region: string;
  segments: Array<App.Segment>;
  listOffers: Array<OrderOffer>;
  variant: EvVariant;
  onPositionChanged: () => void;
}

interface HotelTableProps extends CommonProps {
  vertical: 'hotel';
  offers: HotelSearchList;
}

interface TourTableProps extends CommonProps {
  vertical: 'tour';
  offers: TourSearchList;
}

interface CruiseTableProps extends CommonProps {
  vertical: 'cruise';
  offers: UnifiedSearchList;
}

interface ExperienceTableProps extends CommonProps {
  vertical: 'experience';
  offers: UnifiedSearchList;
}

interface UnifiedTableProps extends CommonProps {
  vertical: 'unified' | 'ultra lux';
  offers: UnifiedSearchList;
}

export type SearchRankingTableProps =
  | HotelTableProps
  | TourTableProps
  | CruiseTableProps
  | ExperienceTableProps
  | UnifiedTableProps;

function SearchRankingTable({
  offers,
  vertical,
  region,
  variant,
  listOffers,
  segments,
  onPositionChanged,
}: SearchRankingTableProps) {
  const listedOfferIds = new Set(listOffers.map((offer) => offer.bk));
  const isOther = vertical === 'ultra lux' || vertical === 'experience';

  return (
    <Paper sx={{ marginTop: 2 }} variant="outlined">
      <TableContainer sx={{ maxHeight: '85vh' }}>
        <Table stickyHeader>
          <TableHead>
            {vertical === 'hotel' && <SearchHotelHead segments={segments} />}
            {vertical === 'tour' && <SearchTourHead />}
            {vertical === 'cruise' && <SearchCruiseHead />}
            {vertical === 'experience' && <SearchExperienceHead />}
            {vertical === 'unified' && <SearchUnifiedHead />}
            {vertical === 'ultra lux' && <SearchUltraLuxHead />}
          </TableHead>
          <TableBody>
            {vertical === 'hotel' &&
              offers.map((offer) => (
                <SearchHotelRow
                  key={offer.id}
                  offerId={offer.id}
                  region={region}
                  segments={segments}
                  variant={variant}
                  onPositionChanged={onPositionChanged}
                />
              ))}
            {vertical === 'tour' &&
              offers.map((offer) => {
                const offerId = typeof offer === 'string' ? offer : offer.tourId;

                return (
                  <SearchTourRow
                    key={offerId}
                    offerId={offerId}
                    region={region}
                    variant={variant}
                    isManuallySorted={listedOfferIds.has(offerId)}
                  />
                );
              })}
            {vertical === 'cruise' &&
              offers.map((offer) => (
                <SearchCruiseRow
                  key={offer.bk}
                  offer={offer}
                  region={region}
                  variant={variant}
                  isManuallySorted={listedOfferIds.has(offer.bk)}
                />
              ))}
            {vertical === 'unified' &&
              offers.map((offer) => (
                <SearchUnifiedRow
                  key={offer.bk}
                  offer={offer}
                  region={region}
                  variant={variant}
                  isManuallySorted={listedOfferIds.has(offer.bk)}
                />
              ))}
            {isOther &&
              offers.map((offer) => (
                <SearchOtherRow
                  key={offer.bk}
                  offer={offer}
                  region={region}
                  variant={variant}
                  vertical={vertical}
                  isManuallySorted={listedOfferIds.has(offer.bk)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SearchRankingTable;
