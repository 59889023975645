import React, { useState } from 'react';

import pluralize from 'pluralize';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';
import { Brand } from '@luxuryescapes/lib-regions/lib/regions';

import { buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';

import { LUX_PLUS } from '~/consts/membership';
import { LUX_PLUS_SCHEDULE } from '~/consts/schedule';

import { asiaRegions } from '../../../../consts/allRegions';
import OffersService from '../../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../../utils/DefaultDate';
import NewScheduleFormBase from '../../../Common/Forms/NewScheduleFormBase';
import useScheduleUpdate from '../common/useScheduleUpdate';
import { extractErrorMessages, getRegionName } from '../common/utils';

type RegionSelections = { [regionCode: string]: boolean };

function getBulkUpdateRegions(brand: Brand, type: string) {
  if (type === LUX_PLUS_SCHEDULE) {
    return libRegions
      .getRegions(brand)
      .filter((region) => LUX_PLUS.REGIONS.includes(region.code as App.MembershipRegion));
  }

  return libRegions.getRegions(brand);
}

function createRegionSelections(value: boolean, brand: Brand, type: string): RegionSelections {
  const regionSelections = {};
  getBulkUpdateRegions(brand, type).forEach((region) => {
    regionSelections[region.code] = value;
  });
  return regionSelections;
}

interface Props {
  brand: Brand;
  isOpen: boolean;
  offerId: string;
  type: string;
  currentSchedules: Array<App.Schedule>;
  onClose: () => void;
  onSaveFinished: () => void;
  onError: () => void;
  title?: string;
}

interface ErrorState {
  regions: Array<string>;
  errors: Array<string>;
}

export default function ScheduleBulkUpdate({
  brand = 'luxuryescapes',
  isOpen,
  offerId,
  type,
  currentSchedules,
  onClose,
  onSaveFinished,
  onError,
  title = 'Bulk Update',
}: Props) {
  const {
    schedule,
    setSchedule,
    startUpdated,
    setStartUpdated,
    endUpdated,
    setEndUpdated,
    saveState,
    setSaveState,
    updateStart,
    updateEnd,
    onReset,
    updateSchedule,
  } = useScheduleUpdate({ offerId });
  const [regionSelections, setRegionSelections] = useState<RegionSelections>(
    createRegionSelections(false, brand, type),
  );
  const [updateErrors, setUpdateErrors] = useState<ErrorState | null>(null);
  const [createErrors, setCreateErrors] = useState<ErrorState | null>(null);

  const onRegionToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const region = e.target.value;
    const isChecked = e.target.checked;

    setRegionSelections((prevState) => ({
      ...prevState,
      [region]: isChecked,
    }));
  };

  const changeSelection = (isChecked: boolean) => {
    setRegionSelections(createRegionSelections(isChecked, brand, type));
  };

  const selectAsiaOnly = () => {
    const regionSelections = {};
    libRegions.getRegions(brand).forEach((region) => {
      regionSelections[region.code] = asiaRegions.includes(region.name);
    });
    setRegionSelections(regionSelections);
  };

  const createSchedules = async (regions: Array<string>) => {
    const payload = {
      regions,
      start: schedule.start,
      end: schedule.end,
      brands: [brand],
      type,
    };

    return OffersService.createScheduleList(payload, offerId);
  };

  const saveSchedule = async () => {
    const currentlyScheduledRegions = currentSchedules.map((s) => s.region);
    const selectedRegions = filterCheckedRegions();

    const updatedSchedules = currentSchedules.filter((s) => selectedRegions.includes(s.region));
    const updatedRegions = updatedSchedules.map((s) => s.region);
    const addedRegions = selectedRegions.filter((r) => !currentlyScheduledRegions.includes(r));

    setSaveState(buttonStates.saving);

    const updates: Array<Promise<any>> = [Promise.resolve(), Promise.resolve()];
    if (updatedSchedules.length) {
      updates[0] = updateSchedule(updatedSchedules);
    }
    if (addedRegions.length) {
      updates[1] = createSchedules(addedRegions);
    }
    const updateResults = await Promise.allSettled(updates);

    if (updateResults.some((r) => r.status === 'rejected')) {
      setSaveState(buttonStates.failed);
      setUpdateErrors(
        updateResults[0].status === 'rejected'
          ? {
              regions: updatedRegions,
              errors: extractErrorMessages(updateResults[0].reason),
            }
          : null,
      );
      setCreateErrors(
        updateResults[1].status === 'rejected'
          ? {
              regions: addedRegions,
              errors: extractErrorMessages(updateResults[1].reason),
            }
          : null,
      );
      onError();
    } else {
      reset();
      onSaveFinished();
      onClose();
    }
  };

  const filterCheckedRegions = () => {
    return Object.entries(regionSelections)
      .filter(([region, isSelected]) => isSelected)
      .map(([region]) => region);
  };

  const anyRegionsChecked = () => {
    return filterCheckedRegions().length > 0;
  };

  const reset = () => {
    setSchedule({
      start: defaultStartDate(),
      end: defaultEndDate(),
    });
    setRegionSelections(createRegionSelections(false, brand, type));
    setStartUpdated(false);
    setEndUpdated(false);
    setSaveState(buttonStates.default);
    setUpdateErrors(null);
    setCreateErrors(null);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="fp-schedule-edit">
        {updateErrors && (
          <Alert severity="error">
            Error updating {pluralize('schedule', updateErrors.regions.length)} for{' '}
            {updateErrors.regions.map(getRegionName).join(', ')}:
            <Box component="ul">
              {updateErrors.errors.map((error, index) => (
                <Box component="li" key={index}>
                  {error}
                </Box>
              ))}
            </Box>
          </Alert>
        )}
        {createErrors && (
          <Alert severity="error">
            Error creating {pluralize('schedule', createErrors.regions.length)} for{' '}
            {createErrors.regions.map(getRegionName).join(', ')}:
            <Box component="ul">
              {createErrors.errors.map((error, index) => (
                <Box component="li" key={index}>
                  {error}
                </Box>
              ))}
            </Box>
          </Alert>
        )}

        <NewScheduleFormBase
          schedule={schedule}
          updateStart={updateStart}
          updateEnd={updateEnd}
          startUpdated={startUpdated}
          endUpdated={endUpdated}
          onReset={onReset}
          disabled={saveState === buttonStates.saving}
        />
        <Stack direction="row" spacing={1} alignItems="baseline">
          <Typography>Select Countries: </Typography>
          <Button variant="text" onClick={() => changeSelection(true)}>
            Select All
          </Button>
          <span className="bar">|</span>
          <Button variant="text" onClick={() => changeSelection(false)}>
            Deselect All
          </Button>
          {type !== LUX_PLUS_SCHEDULE && (
            <>
              <span className="bar">|</span>
              <Button variant="text" onClick={selectAsiaOnly}>
                Asia only
              </Button>
            </>
          )}
        </Stack>

        <Grid container>
          {getBulkUpdateRegions(brand, type).map((region) => (
            <Grid item xs={6} sm={2} key={region.code}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={region.name}
                      value={region.code}
                      checked={regionSelections[region.code]}
                      onChange={onRegionToggle}
                      disabled={saveState === buttonStates.saving}
                    />
                  }
                  label={region.name}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel} disabled={saveState === buttonStates.saving}>
          Cancel
        </Button>
        <Button
          onClick={saveSchedule}
          disabled={saveState === buttonStates.saving || !anyRegionsChecked()}
          color={saveState}
        >
          {saveState === buttonStates.saving ? 'Saving...' : 'Save Schedule'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
