/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { WithSnackbarProps } from 'notistack';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import OrderDetailAccountingData from '~/components/Purchases/OrderDetail/OrderDetailAccountingData';
import CancellationPolicyWarningModal from '~/components/Refund/CancellationPolicyWarningModal';
import NewRefundModal from '~/components/Refund/NewModal';
import RefundItemsReminderModal from '~/components/Refund/RefundItemsReminderModal';
import OrderDetailVirtualCreditCards from '~/components/VirtualCreditCards/OrderDetail/OrderDetailPage';
import { withTenant } from '~/components/hoc';

import { ITEM_STATUS_CANCELLED } from '~/consts/order';

import BedbankService from '~/services/BedbankService';
import { getBusiness, getEmployee } from '~/services/BusinessTraveller/BusinessTravellerService';
import { determineLoyaltyStatus, getEarnOrder, getPartnerships } from '~/services/LoyaltyService';
import { getFlightDetailsByOrderAndItem, getReservationByOrder, getTaxInvoiceDetails } from '~/services/OrdersService';
import { getTaxInvoicePDF } from '~/services/PDFService';
import { getPaymentTotalsByDate } from '~/services/PaymentHelper';
import {
  PaymentScheduleDetailsResponse,
  getDepositDetails,
  getInstalmentDetails,
  getPaymentPlans,
  getPaymentScheduleDetails,
  getReserveForZeroDetails,
} from '~/services/PaymentsService';
import { formatDateShortDD, formatDateShortDateAndMonth } from '~/services/TimeService';
import * as TourService from '~/services/ToursService';
import UsersService from '~/services/UsersService';

import featureToggle from '~/utils/featureToggle';
import getEarnOrderPartnerships from '~/utils/getEarnOrderPartnerships';
import isOrderRefundable from '~/utils/isOrderRefundable';
import { isAllCancelled } from '~/utils/order';
import { calcOrderPayments } from '~/utils/refund';
import { reportError } from '~/utils/reportError';

import AddonsList from './AddonsList/list';
import BookingProtectionItem from './BookingProtection/BookingProtectionItem';
import BookingRequests from './BookingRequests/BookingRequests';
import OrderItemCredit from './BusinessTraveller/OrderItemBusinessCredit';
import CarHireOrderListDetails from './CarHire/CarHireOrderListDetails';
import CruiseOrderListDetails from './Cruises/CruiseOrderListDetails';
import CustomOfferOrderList from './CustomOffer/CustomOfferOrderList';
import ExperiencesList from './ExperiencesList/ExperiencesList';
import OrderInstalmentDetails from './Instalments/OrderInstalmentDetails';
import OrderDepositDetails from './OrderDepositDetails';
import OrderDetailBedbank from './OrderDetailBedbank';
import OrderDetailBusinessCredits from './OrderDetailBusinessCredits';
import OrderDetailNotes from './OrderDetailNotes';
import OrderDetailOffer from './OrderDetailOffer';
import OrderDetailPartnerships from './OrderDetailPartnerships';
import OrderDetailPaymentErrorLogs from './OrderDetailPaymentErrorLogs';
import OrderDetailPaymentPlanLogs from './OrderDetailPaymentPlanLogs';
import OrderDetailPayments from './OrderDetailPayments';
import OrderDetailRewards from './OrderDetailRewards';
import OrderDetailTour from './OrderDetailTour';
import OrderFlightItemContainer from './OrderFlightItems';
import OrderFlightFees from './OrderFlightItems/OrderFlightFees';
import OrderGift from './OrderGift';
import OrderInsuranceItemList from './OrderInsuranceItemList';
import OrderMerchantFeeDetails from './OrderMerchantFeeDetails';
import OrderMeta from './OrderMeta';
import OrderOfflineFlightItemContainer from './OrderOfflineFlightItemContainer';
import OrderPaymentScheduleDetails from './OrderPaymentScheduleDetails';
import OrderServiceFeeItem from './OrderServiceFeeItem';
import OrderTermsAndConditions from './OrderTermsAndConditions';
import PaymentChargeSimulation from './PaymentChargeSimulation';
import RebookModal from './RebookModal';
import ReconfirmModal from './ReconfirmModal';
import OrderReserveForZeroDetails from './ReserveForZero/OrderReserveForZeroDetails';
import SubscriptionItem from './SubscriptionOffer/SubscriptionItem';

interface Props {
  addOfferToCart: (pkg?: App.Package, currencyCode?: string, offer?: App.Offer, orderItem?: App.OrderItem) => void;
  allowToggleReservationType: (allow: boolean) => void;
  customer: App.User;
  customerEmailToAgentState: any;
  emailToCustomerState: any;
  emailToVendorState: any;
  flightsCreditReservationId: string;
  getTravellers: () => void;
  history: Array<string>;
  isBookingDates: boolean;
  isChangingDates: boolean;
  isConvertingToBNBL: boolean;
  offers: Array<{ items: Array<App.OrderItem>; offer: App.AccommodationOffer }>;
  onEmptyCart: () => void;
  order: App.Order;
  refreshData: () => void;
  refunds: any;
  resendCustomerEmail: () => void;
  resendCustomerEmailToAgent: () => void;
  resendOrderGiftDeliveryEmail: () => void;
  resendVendorEmail: () => void;
  resendVendorEmailToAgent: () => void;
  selectOffer: (offerId: string) => void;
  setIsBookingDates: (isBookingDates: boolean) => void;
  setIsChangingDates: (isChangingDates: boolean) => void;
  setIsConvertingToBNBL: (isConvertingToBNBL: boolean) => void;
  setIsPuttingOnHold: (isPuttingOnHold: boolean) => void;
  setOrderId: (orderId: string) => void;
  setReservationType: (reservationType: string) => void;
  travellers: Array<App.OrderTourTraveller>;
  vendorEmailToAgentState: any;
  tenant: App.Tenant;
  subscriptions: App.MembershipSubscriptions | null;
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

interface State {
  // App state
  showingRefundDetail: boolean;
  showingRebookDetail: string | null;
  showingReconfirmDetail: string | null;
  loyaltyStatus: any;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      reservationStatus: string;
      reservationRoomStatus: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
    };
  };
  tourRoomsInfo: any;
  hasAllowedRefund: boolean;
  paymentPlanLoaded: boolean;
  paymentPlanData: any;
  pointsRequestStatus: string;
  purchaser: string;
  partnerships: any;
  invoiceDownloading: boolean;
  downloadFailed: boolean;
  showingRefundReminderModal: boolean;
  depositDetails: any;
  instalmentDetails: any;
  reserveForZeroDetails: any;
  paymentScheduleDetails: PaymentScheduleDetailsResponse;
  itemType: string;
  accountingData: Array<any>;
  refundItemId: any;
  reservationsFetched: boolean;
  reservations: IReservations;
  earnOrder: any;
  refundAutomatic: any;
  refundRoomId: any;
  expendedTransactionHistory: boolean;
  isForceBundleAlertVisible: boolean;
  isAfterChangeDate: boolean;
  orderCreditItem: any;
  businessInfo: {
    businessName: string;
    businessCreditCustomerId: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
  isAccommodationRefundable: boolean;
  shouldShowRefundWarningModal: boolean;
  refundApprover: string;
  rebookableLoading: boolean;
  bookingJourneysByItemId: Record<string, App.BookingJourney>;
}

class OrderDetailPage extends React.Component<Props & WithSnackbarProps, State> {
  static defaultProps: Partial<Props> = {
    emailToCustomerState: {},
    emailToVendorState: {},
    customerEmailToAgentState: {},
    vendorEmailToAgentState: {},
  };

  constructor(props) {
    super(props);

    const hasAllowedRefund =
      calcOrderPayments(props.order.payments).sum > 0 ||
      props.order.payments[0]?.deferredPaymentDetails?.is_active ||
      props.order.car_hire_items.length > 0;

    this.state = {
      showingRefundDetail: false,
      showingRebookDetail: null,
      showingReconfirmDetail: null,
      loyaltyStatus: {},
      bedbankRoomsInfo: {},
      tourRoomsInfo: {},
      hasAllowedRefund,
      paymentPlanLoaded: false,
      paymentPlanData: null,
      pointsRequestStatus: '',
      purchaser: '',
      partnerships: null,
      invoiceDownloading: false,
      downloadFailed: false,
      showingRefundReminderModal: false,
      depositDetails: {},
      instalmentDetails: {},
      reserveForZeroDetails: {},
      paymentScheduleDetails: {},
      itemType: null,
      accountingData: [],
      refundItemId: null,
      reservations: null,
      reservationsFetched: false,
      earnOrder: null,
      refundAutomatic: null,
      refundRoomId: null,
      expendedTransactionHistory: true,
      isForceBundleAlertVisible: this.props.offers.some((offer) => offer.offer.bundled_with_flights_only),
      isAfterChangeDate: false,
      orderCreditItem: null,
      businessInfo: {
        businessName: undefined,
        businessCreditCustomerId: undefined,
        employeePersonalAccountInfo: undefined,
        personalName: undefined,
        personalEmail: undefined,
      },
      isAccommodationRefundable: true,
      shouldShowRefundWarningModal: false,
      refundApprover: undefined,
      rebookableLoading: false,
      bookingJourneysByItemId: {},
    };
  }

  async componentDidMount() {
    const purchaser = await this.fetchPurchaserData();
    this.setState({ purchaser: purchaser.fullName });
    try {
      getReservationByOrder(this.props.order).then((reservations) => {
        const isAccommodationRefundable = isOrderRefundable(this.props.order, reservations);

        this.setState({
          reservationsFetched: true,
          reservations,
          isAccommodationRefundable: isAccommodationRefundable,
        });
      });

      let data = await getPaymentPlans(this.props.order.id_orders);

      if (data.plans.length == 0) {
        data = null;
      }

      let bedbankRoomsInfo = {};

      if (this.props.order.bedbank_items.length) {
        const bedbankItemsInfoData = await Promise.all(
          this.props.order.bedbank_items.flatMap((bedbankItem) =>
            BedbankService.getBookingInfo(bedbankItem.id_reservation),
          ),
        );

        const bedbankItemsRebookInfoData = await Promise.all(
          this.props.order.bedbank_items.map((bedbankItem) =>
            BedbankService.getRebookableInfo([bedbankItem.id_reservation]),
          ),
        );

        bedbankRoomsInfo = bedbankItemsInfoData.reduce((acc, { result }) => {
          const rooms = result.rooms;

          for (const room of rooms) {
            acc[room.id] = {
              id: room.id,
              status: room.status,
              reservationStatus: result.reservationStatus,
              reservationRoomStatus: room.reservationRoomStatus,
            };
          }

          return acc;
        }, {});

        bedbankRoomsInfo = bedbankItemsRebookInfoData.reduce((acc, { result }) => {
          const rooms = result;

          for (const room of rooms) {
            if (acc[room.reservationRoomId] && !room.newReservationId) {
              acc[room.reservationRoomId] = {
                ...acc[room.reservationRoomId],
                rebook: room,
              };
            }
          }

          return acc;
        }, bedbankRoomsInfo);
      }

      let tourRoomsInfo = {};
      if (this.props.order.tour_items?.length) {
        const tourItemsInfoData = await Promise.all(
          this.props.order.tour_items.map((tourItem) => TourService.getBookingInfo(tourItem.reservation_id)),
        );

        tourRoomsInfo = tourItemsInfoData.reduce((acc, { result }) => {
          acc[result.reservationId] = result;

          return acc;
        }, {});
      }

      const partnershipsData = await getPartnerships(this.props.order.region_code, this.props.order.brand);
      const allPartnerships = (partnershipsData && partnershipsData.result) || [];
      const loyaltyStatus = determineLoyaltyStatus(this.props.order, allPartnerships);

      const [depositDetails, instalmentDetails, reserveForZeroDetails, paymentScheduleDetails] = await Promise.all([
        getDepositDetails(this.props.order.id),
        getInstalmentDetails(this.props.order.id),
        getReserveForZeroDetails(this.props.order.id),
        getPaymentScheduleDetails(this.props.order.id),
      ]);

      if (this.props.order.business_id !== null) {
        const business = await getBusiness(this.props.order.business_id);
        this.setState({
          businessInfo: { ...this.state.businessInfo, businessName: business.name },
        });
        const employeeInfo = await getEmployee(this.props.order.fk_customer_id);
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            businessCreditCustomerId: employeeInfo.creditCustomerId ?? employeeInfo.customerId,
          },
        });
        const employeeCreditCustomerInfo: App.User = await UsersService.getUser(
          employeeInfo.creditCustomerId ?? employeeInfo.customerId,
        );
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            personalName: employeeCreditCustomerInfo.fullName,
            personalEmail: employeeCreditCustomerInfo.email,
          },
        });
      }

      const bookingJourneys = await Promise.all(
        this.props.order.flight_items.map(async (item) => {
          const flightDetails = await getFlightDetailsByOrderAndItem(item.fk_order_id, item.id);
          return [item.id, flightDetails.booking_journey];
        }),
      );
      const bookingJourneysByItemId = Object.fromEntries(bookingJourneys);

      const earnOrder = await Promise.all(getEarnOrder(this.props.order, getEarnOrderPartnerships(allPartnerships)));
      this.setState({
        paymentPlanLoaded: true,
        paymentPlanData: data,
        partnerships: allPartnerships,
        loyaltyStatus: loyaltyStatus,
        bedbankRoomsInfo,
        tourRoomsInfo,
        depositDetails,
        instalmentDetails,
        reserveForZeroDetails,
        paymentScheduleDetails,
        earnOrder,
        bookingJourneysByItemId,
      });
    } catch (error) {
      reportError(error);
    }
  }

  debugFormatter(cell) {
    return JSON.stringify(cell);
  }

  fetchPurchaserData = () => {
    const { order } = this.props;

    return UsersService.getUser(order.fk_purchaser_id);
  };

  triggerRefund = () => {
    this.setState({
      showingRefundDetail: true,
    });
    window.hj =
      window.hj ||
      function () {
        // I honestly have no idea what this line does.
        // eslint-disable-next-line prefer-rest-params
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    //hotjar recording trigger
    window.hj('trigger', 'refund');
  };

  setRefundComment = (comment: string) => {
    this.setState({ refundApprover: comment });
  };

  closeRefundWarningModal = () => {
    this.setState({
      shouldShowRefundWarningModal: false,
    });
  };

  showRefundModal = async ({ itemId, roomId = null, auto = null, itemType = null }) => {
    this.setState({
      refundItemId: itemId,
      refundRoomId: roomId,
      refundAutomatic: auto,
      itemType: itemType,
    });
    if (!this.state.isAccommodationRefundable) {
      this.setState({
        shouldShowRefundWarningModal: true,
      });
    } else {
      this.triggerRefund();
    }
  };

  showRebookingModal = ({ itemId }) => {
    this.setState({
      showingRebookDetail: itemId,
    });
  };

  checkRebookable = async () => {
    if (this.props.order.bedbank_items.length) {
      try {
        this.setState({ rebookableLoading: true });
        const response = await Promise.all(
          this.props.order.bedbank_items.map((bedbankItem) =>
            BedbankService.checkRebookable(bedbankItem.id_reservation),
          ),
        );
        if (response?.length && response.some((r) => r.result?.isRebookable)) {
          const bedbankItemsRebookInfoData = await Promise.all(
            this.props.order.bedbank_items.map((bedbankItem) =>
              BedbankService.getRebookableInfo([bedbankItem.id_reservation]),
            ),
          );

          const bedbankRoomsInfo = bedbankItemsRebookInfoData.reduce((acc, { result }) => {
            const rooms = result;

            for (const room of rooms) {
              if (acc[room.reservationRoomId] && !room.newReservationId) {
                acc[room.reservationRoomId] = {
                  ...acc[room.reservationRoomId],
                  rebook: room,
                };
              }
            }

            return acc;
          }, this.state.bedbankRoomsInfo);

          this.setState({ bedbankRoomsInfo });
        }
      } finally {
        this.setState({ rebookableLoading: false });
      }
    }
  };

  showReconfirmModal = ({ itemId }) => {
    this.setState({
      showingReconfirmDetail: itemId,
    });
  };

  hasRemindingItem = (key) => {
    const { order } = this.props;
    return !!order[key]?.some((item) => item.status !== ITEM_STATUS_CANCELLED);
  };

  showWarningModal = () => {
    const hasExperiencesToBeReminded =
      this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasCruiseToBeReminded = this.hasRemindingItem('cruise_items');
    const hasInsuranceToBeReminded = this.hasRemindingItem('insurance_items');
    const hasFlightsToBeReminded = this.hasRemindingItem('flight_items');

    if (hasExperiencesToBeReminded || hasInsuranceToBeReminded || hasFlightsToBeReminded || hasCruiseToBeReminded) {
      // postpones refreshData to when modal is dismissed
      this.setState({
        showingRefundReminderModal: true,
        showingRefundDetail: false,
      });
    } else {
      this.props.refreshData();
    }
  };

  onRefund = () => {
    const { order } = this.props;
    const isAccommodationItem = order.accommodation_items.find((item) => item.id === this.state.refundItemId);

    if (!isAccommodationItem) {
      this.props.refreshData();
      return;
    }

    this.showWarningModal();
  };

  hideRefundDetail = () => {
    if (window.confirm(`Are you sure? Data will be wiped out`)) {
      this.setState({
        showingRefundDetail: false,
      });

      this.props.refreshData();
    }
  };

  downloadTax = async () => {
    this.setState({
      invoiceDownloading: true,
      downloadFailed: false,
    });

    try {
      const invoiceList = await this.createTaxInvoiceDetails();
      const pdf = await getTaxInvoicePDF(invoiceList);
      const fileName = `LuxuryEscapes-Tax-Invoice-${this.props.order.id}.pdf`;
      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      this.setState({
        downloadFailed: true,
        invoiceDownloading: false,
      });
    } finally {
      this.setState({
        invoiceDownloading: false,
      });
    }
  };

  newPointsRequest = (status) => {
    this.setState({
      pointsRequestStatus: status,
    });
  };

  packagesInvoiceDetailsList = (billingCountry, isLEBT) => {
    const { offers } = this.props;
    const result = offers.reduce(
      (acc, { offer, items }) =>
        acc.concat(
          items
            .filter((item) => isLEBT || item.status !== 'cancelled')
            .map((item) => {
              const itemPackage = offer.packages.find(
                (p) =>
                  p.id_salesforce_external === item.offer_package.id_salesforce_external ||
                  p.le_package_id === item.offer_package.le_package_id,
              );
              return {
                description: [
                  `Offer Details: ${item.offer_package.name}, ${itemPackage.deal_option_name}`,
                  `Location: ${offer.location}`,
                  `Booking Number: ${item.booking_number}`,
                ],
                total_price: Number(item.total),
                billing_country: billingCountry,
                status: item.status,
              };
            }),
        ),
      [],
    );
    return result;
  };

  getFlightBillingCountry = (): string => {
    const { order } = this.props;
    const { bookingJourneysByItemId } = this.state;
    const flights = bookingJourneysByItemId?.[order.flight_items[0].id]?.legs[0]?.flights;
    if (flights?.length >= 1 && flights[0].departureCountry === flights[flights.length - 1].arrivalCountry) {
      return flights[0].departureCountry;
    } else {
      return 'International';
    }
  };

  getInsuranceDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledInsuranceDetails = order.insurance_items.filter((item) => isLEBT || item.status !== 'cancelled');

    return (nonCancelledInsuranceDetails || []).map((item) => ({
      description: [
        `Travel Insurance: Insurance ${item.product_name}`,
        `Coverage dates: ${formatDateShortDateAndMonth(new Date(item.start_date))} - ${formatDateShortDD(
          new Date(item.end_date),
        )}`,
        `Policy ID: ${item.contract_number}`,
      ],
      total_price: Number(item.total),
      billing_country: billingCountry,
      status: item.status,
      claim_status: item.claim_status,
    }));
  };

  // Not sure do we have addons with price more than 0
  // if no can remove this part
  getAddonsDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledAddonDetails = order.addon_items.filter(
      (item) => isLEBT || (item.status !== 'cancelled' && Number(item.total) > 0),
    );
    const addonDetailsList = [];
    (nonCancelledAddonDetails || []).forEach((item) =>
      addonDetailsList.push({
        description: [`Experiences: ${item.name}`],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      }),
    );

    return addonDetailsList;
  };

  getTourDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledTourDetails = order.tour_items.filter((item) => isLEBT || item.status !== 'cancelled');
    const nonCancelledServiceFeeDetails = order.service_fee_items.filter(
      (item) => isLEBT || item.status !== 'cancelled',
    );

    const tourDetails = [];
    (nonCancelledTourDetails || []).forEach((item) => {
      tourDetails.push({
        description: [`Tours: ${item.tour_name}`],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });
    (nonCancelledServiceFeeDetails || []).forEach((item) => {
      const description = item.name ? [`Tours Service Fee: ${item.name}`] : ['Tours Service Fee'];
      tourDetails.push({
        description,
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });
    return tourDetails;
  };

  getExperienceDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledExperienceDetails = [];
    (order.experience_items || [])
      .filter((item) => isLEBT || item.status !== 'cancelled')
      .forEach((item) => {
        nonCancelledExperienceDetails.push({
          description: [
            `Experiences: ${item.title}`,
            `Fare Type: ${item.ticket.fareType}`,
            `Booking Number: ${item.booking_number}`,
          ],
          total_price: Number(item.total),
          billing_country: billingCountry,
          status: item.status,
        });
      });
    return nonCancelledExperienceDetails;
  };

  getPdfTotals = async (allItems, billingCountry, isLEBT) => {
    const totals = getPaymentTotalsByDate(
      this.props.order,
      this.props.refunds.result,
      this.state.reserveForZeroDetails,
      this.state.instalmentDetails,
    );

    // We can have some items in order to which gst is not applies, mainly flights.
    let totalNonGst = 0;
    allItems.flat().forEach((item) => {
      if (item.billing_country !== 'Australia') {
        totalNonGst += item.total_price;
      }
    });

    const grandTotal = isLEBT ? totals.subTotal : totals.subTotal - totals.refundedTotal;
    const totalPaid = isLEBT
      ? totals.subTotal + totals.totalBusinessTravellerCredits
      : totals.grandTotal - totals.refundedTotal;
    const gst = billingCountry === 'Australia' ? Number((grandTotal - totalNonGst) / 11) : 0;
    const subtotal = isLEBT ? Number(grandTotal - gst - totals.refundedTotal) : Number(grandTotal - gst);

    return {
      subtotal,
      GST: gst,
      total_amount: grandTotal,
      promo_used: totals.promoTotal,
      credit_used: totals.creditsTotal,
      total_paid: totalPaid,
      le_credit: totals.totalBusinessTravellerCredits,
      total_refunded: totals.refundedTotal,
    };
  };

  getBedbankDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledBedbankDetails = order.bedbank_items.filter((item) => isLEBT || item.status !== 'cancelled');
    const bedbankDetailsList = [];
    (nonCancelledBedbankDetails || []).forEach((item) => {
      bedbankDetailsList.push({
        description: [
          `Accommodation: ${item.offer.name}`,
          `Location: ${item.offer.address.countryName}, ${item.offer.address.stateProvinceName} ${item.offer.address.city}`,
          `Booking Number: ${item.booking_number}`,
        ],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });

    return bedbankDetailsList;
  };

  createTaxInvoiceDetails = async () => {
    const { order } = this.props;
    const result = getTaxInvoiceDetails(order.id_orders);
    return result;
  };

  showForceBundleAlert = () => {
    this.setState({ isForceBundleAlertVisible: true });
  };

  render() {
    const {
      order,
      customer,
      offers,
      refreshData,
      isBookingDates,
      isChangingDates,
      emailToCustomerState,
      emailToVendorState,
      resendOrderGiftDeliveryEmail,
      customerEmailToAgentState,
      vendorEmailToAgentState,
      flightsCreditReservationId,
      subscriptions,
    } = this.props;
    const { showingRefundDetail, loyaltyStatus } = this.state;
    const earnOrderPartnerships = getEarnOrderPartnerships(this.state.partnerships);
    const {
      invoiceDownloading,
      downloadFailed,
      depositDetails,
      paymentScheduleDetails,
      instalmentDetails,
      reserveForZeroDetails,
      reservationsFetched,
      reservations,
      isForceBundleAlertVisible,
    } = this.state;

    const showVirtualCreditCardsDetails =
      order.items.length > 0 || (order.bedbank_items && order.bedbank_items.length > 0);

    // In case of refund we need to pass an offer to refund modal
    const getAccommodationOfferFromItemId = (itemId) => {
      let offerSalesforceId = null;
      let item: any = order.items.find((i) => i.id === itemId);
      if (item) {
        offerSalesforceId = item.offer.id_salesforce_external;
      } else {
        item = order.addon_items.find((i) => i.id === itemId);
        if (item) {
          offerSalesforceId = item.offer_salesforce_id;
        }
      }

      // If not an accommodation or addon item, i.e. flights etc - this will just return the first offer
      const result = this.props.offers.find(
        ({ offer }) => !offerSalesforceId || offer.id_salesforce_external == offerSalesforceId,
      );
      return result?.offer;
    };

    const showDownloadInvoice =
      (!order.offline_flight_items || order.offline_flight_items.length === 0) &&
      (order.payments[0]?.instalmentDetails
        ? ['payment_due_balance_manual_debit_taken', 'payment_due_balance_auto_debit_taken'].includes(
            order.payments[0]?.instalmentDetails?.instalment_status,
          )
        : true) &&
      order.status !== 'cancelled' &&
      order.status !== 'abandoned';

    const hasRemindingExperiences = this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasRemindingInsurances = this.hasRemindingItem('insurance_items');
    const hasRemindingFlights = this.hasRemindingItem('flight_items');
    const hasRemindingCruises = this.hasRemindingItem('cruise_items');
    const hasRemindingCarHire = this.hasRemindingItem('car_hire_items');

    const hasCruisesToShow = featureToggle.availableToShow('SHOW_CRUISES') && order.cruise_items?.length > 0;

    const hasCarhireToShow = featureToggle.availableToShow('SHOW_CAR_HIRE') && order.car_hire_items?.length > 0;

    const hasExperiencesToShow = window.configs.SHOW_EXPERIENCES === 'true' && order.experience_items?.length > 0;

    const flightItem = order?.flight_items?.[0];
    const showBookingDetails = flightItem?.provider === 'amadeus' && flightItem?.pnr_id !== null;

    // We need to get the last flight item for LEBT.
    // The credits and budget for a flight is stored on the last flight_item in svc-business
    const lastFlightItem = order?.flight_items?.[order?.flight_items?.length - 1];

    const showAtolDetails = order?.region_code === 'GB' && flightItem?.pnr_id !== null;

    const showCreditReservation = !!flightsCreditReservationId;

    const allAccommodationsCancelled =
      order.items.length > 0 || order.bedbank_items.length > 0
        ? isAllCancelled(order.items.concat(order.bedbank_items))
        : false;

    const hasServiceFee = Array.isArray(order?.service_fee_items) && order.service_fee_items.length > 0;
    const hasSubscriptions = Array.isArray(order?.subscription_items) && order.subscription_items.length > 0;

    // For OpenJaw flights, we want to display a single flight item twice
    // with each element representing a leg of the journey
    const sortedFlightItems = order.flight_items?.sort((a, b) => a.leg_number - b.leg_number);
    const journeysByItemId = this.state.bookingJourneysByItemId;

    return (
      <Stack px={2} className="order-detail">
        <Helmet>
          <title>Orders |{customer.fullName ? ` ${customer.fullName} |` : ''} Order Summary</title>
        </Helmet>

        <PageHeader title="Order details">
          <Stack direction="row" spacing={2} alignItems="center">
            {showDownloadInvoice && (
              <Button
                variant="text"
                disabled={invoiceDownloading}
                onClick={this.downloadTax}
                startIcon={invoiceDownloading ? <CircularProgress size={16} color="inherit" /> : undefined}
              >
                {!invoiceDownloading && !downloadFailed && <span>Download Invoice</span>}
                {invoiceDownloading && <span>Downloading Invoice</span>}
                {!invoiceDownloading && downloadFailed && <span>Download Failed! Try Again</span>}
              </Button>
            )}

            <PermissionedComponent>
              <Button variant="text" component={RouterLink} to={`/edit-purchases/${order.id_orders}`}>
                Edit
              </Button>
            </PermissionedComponent>
          </Stack>
        </PageHeader>

        <Box>
          {emailToVendorState.successSending && <Alert severity="success">{emailToVendorState.successSending}</Alert>}
          {emailToVendorState.errorSending && <Alert severity="error">{emailToVendorState.errorSending}</Alert>}
          {emailToCustomerState.successSending && (
            <Alert severity="success">{emailToCustomerState.successSending}</Alert>
          )}
          {emailToCustomerState.errorSending && <Alert severity="error">{emailToCustomerState.errorSending}</Alert>}
          {customerEmailToAgentState.successSending && (
            <Alert severity="success">{customerEmailToAgentState.successSending}</Alert>
          )}
          {customerEmailToAgentState.errorSending && (
            <Alert severity="error">{customerEmailToAgentState.errorSending}</Alert>
          )}
          {vendorEmailToAgentState.successSending && (
            <Alert severity="success">{vendorEmailToAgentState.successSending}</Alert>
          )}
          {vendorEmailToAgentState.errorSending && (
            <Alert severity="error">{vendorEmailToAgentState.errorSending}</Alert>
          )}

          {allAccommodationsCancelled && hasRemindingExperiences && (
            <Alert severity="warning">
              This order has all accommodation items cancelled but some addons/experiences still active. Please check
              them below and cancel if needed.
            </Alert>
          )}

          {isForceBundleAlertVisible && (
            <Alert severity="warning">
              <p>This is a force bundle purchase</p>
            </Alert>
          )}

          <OrderMeta
            customer={customer}
            order={order}
            purchaser={this.state.purchaser}
            subscriptions={subscriptions}
            businessInfo={this.state.businessInfo}
          />

          {/* Order details section */}
          {offers && (
            <OrderDetailOffer
              addOfferToCart={this.props.addOfferToCart}
              allowToggleReservationType={this.props.allowToggleReservationType}
              customer={this.props.customer}
              flightsCreditReservationId={this.props.flightsCreditReservationId}
              history={this.props.history}
              isBookingDates={this.props.isBookingDates}
              isChangingDates={this.props.isChangingDates}
              offers={this.props.offers}
              onEmptyCart={this.props.onEmptyCart}
              order={this.props.order}
              refreshData={this.props.refreshData}
              refunds={this.props.refunds}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              selectOffer={this.props.selectOffer}
              setOrderId={this.props.setOrderId}
              setReservationType={this.props.setReservationType}
              tenant={this.props.tenant}
              travellers={this.props.travellers}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              showWarningModal={this.showWarningModal}
              getTravellers={this.props.getTravellers}
              isConvertingToBNBL={this.props.isConvertingToBNBL}
              setIsBookingDates={this.props.setIsBookingDates}
              setIsChangingDates={this.props.setIsChangingDates}
              setIsConvertingToBNBL={this.props.setIsConvertingToBNBL}
              setIsPuttingOnHold={this.props.setIsPuttingOnHold}
            />
          )}

          {order.custom_offer_items &&
            order.custom_offer_items.length > 0 &&
            order.custom_offer_items.map((customItem) => (
              <Accordion defaultExpanded key={customItem.id} variant="outlined">
                <AccordionSummary>Custom Offer item</AccordionSummary>
                <AccordionDetails>
                  <CustomOfferOrderList customOfferItem={customItem} showRefundModal={this.showRefundModal} />
                </AccordionDetails>
              </Accordion>
            ))}

          {order.has_booking_protection && (
            <BookingProtectionItem
              order={order}
              bookingProtectionItems={order.booking_protection_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasSubscriptions && <SubscriptionItem order={order} />}

          {order.bedbank_items &&
            order.bedbank_items.length > 0 &&
            order.bedbank_items.map((bedbankItem) => (
              <OrderDetailBedbank
                key={bedbankItem.id}
                item={bedbankItem}
                order={order}
                showRebookingModal={this.showRebookingModal}
                checkRebookable={this.checkRebookable}
                rebookableLoading={this.state.rebookableLoading}
                showReconfirmModal={this.showReconfirmModal}
                showRefundModal={this.showRefundModal}
                hasAllowedRefund={this.state.hasAllowedRefund}
                bedbankRoomsInfo={this.state.bedbankRoomsInfo}
                customerEmailToAgentState={this.props.customerEmailToAgentState}
                emailToCustomerState={this.props.emailToCustomerState}
                resendCustomerEmail={this.props.resendCustomerEmail}
                resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
                shouldShowCancellationWarning={!this.state.isAccommodationRefundable}
              />
            ))}

          {order.tour_items?.map((tourItem) => (
            <OrderDetailTour
              key={tourItem.id}
              item={tourItem}
              order={order}
              tourRoomInfo={this.state.tourRoomsInfo[tourItem.reservation_id]}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              showRefundModal={this.showRefundModal}
            />
          ))}

          {order.has_flight && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                  <Typography>Flights</Typography>
                  {showBookingDetails && (
                    <Link
                      href={`/purchases/${order.id_orders}/booking-details`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PNR
                    </Link>
                  )}
                  {showAtolDetails && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/purchases/${order.id_orders}/atol-details`}
                    >
                      ATOL
                    </Link>
                  )}
                  {showCreditReservation && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/flights/credit-reservation/${flightsCreditReservationId}/details`}
                    >
                      Credit Reservation
                    </Link>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {sortedFlightItems.map((flightItem) => (
                  <OrderFlightItemContainer
                    key={flightItem.id}
                    item={flightItem}
                    refreshData={refreshData}
                    showForceBundleAlert={this.showForceBundleAlert}
                  />
                ))}

                <OrderFlightFees
                  flightItems={order.flight_items}
                  journeysByItemId={journeysByItemId}
                  currencyCode={order.currency_code}
                />

                {this.props.tenant.brand === 'lebusinesstraveller' && order.status !== 'abandoned' && (
                  <>
                    <Divider>
                      <Typography fontWeight="bold">LEBT Credits</Typography>
                    </Divider>
                    <OrderItemCredit
                      orderId={order.id_orders}
                      orderItemId={lastFlightItem.id}
                      customerId={order.fk_customer_id}
                      location={
                        journeysByItemId[flightItem.id]?.legs[0]?.flights[
                          journeysByItemId[flightItem.id]?.legs[0]?.flights?.length - 1
                        ]?.arrivalAirportName
                      }
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {order.has_offline_flight && (
            <OrderOfflineFlightItemContainer item={order.offline_flight_items[0]} refreshData={refreshData} />
          )}

          {order.insurance_items && order.insurance_items.length > 0 && (
            <OrderInsuranceItemList
              order={order}
              items={order.insurance_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              purchaseDate={order.created_at}
              refreshData={refreshData}
              refunds={this.props.refunds}
            />
          )}

          {hasCruisesToShow && (
            <CruiseOrderListDetails
              order={order}
              cruiseItems={order.cruise_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              depositDetails={
                depositDetails && {
                  depositAmount: depositDetails?.paid_amount,
                  balanceAmount: depositDetails?.balance_amount,
                  balanceDueDate: depositDetails?.balance_due_date,
                  depositCurrencyCode: depositDetails?.currency,
                }
              }
            />
          )}

          {hasCarhireToShow && (
            <CarHireOrderListDetails
              carHireItems={order.car_hire_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              refreshData={refreshData}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasExperiencesToShow && (
            <ExperiencesList
              hasAllowedRefund={this.state.hasAllowedRefund}
              order={order}
              showRefundModal={this.showRefundModal}
              refreshData={this.props.refreshData}
            />
          )}

          {order.addon_items?.length > 0 && (
            <AddonsList
              payments={order.payments}
              addons={order.addon_items}
              refunds={this.props.refunds}
              isBookingDates={isBookingDates}
              isChangingDates={isChangingDates}
              showRefundModal={this.showRefundModal}
              checkInStatuses={offers.reduce(
                (acc, { items }) => acc.concat(items.map((i) => i?.checkInStatus?.addons)),
                [],
              )}
            />
          )}

          {hasServiceFee && (
            <OrderServiceFeeItem
              order={order}
              paymentScheduleDetails={paymentScheduleDetails}
              showRefundModal={this.showRefundModal}
              refunds={this.props.refunds}
            />
          )}

          {depositDetails?.id_deposit && <OrderDepositDetails depositDetails={depositDetails} />}
          {paymentScheduleDetails?.result?.id && (
            <OrderPaymentScheduleDetails paymentScheduleDetails={paymentScheduleDetails} payments={order.payments} />
          )}
          {instalmentDetails?.id_instalments && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                Instalment Details
              </AccordionSummary>
              <AccordionDetails>
                <OrderInstalmentDetails instalmentDetails={instalmentDetails} payments={order.payments} />
              </AccordionDetails>
            </Accordion>
          )}

          {reserveForZeroDetails?.id_deferred_payment && (
            <OrderReserveForZeroDetails reserveForZeroDetails={reserveForZeroDetails} />
          )}

          {order.merchant_fees?.length > 0 && <OrderMerchantFeeDetails order={order} />}

          <OrderDetailNotes orderId={order.id_orders} refreshData={this.props.refreshData} customer={customer} />

          {this.state.showingRebookDetail && (
            <RebookModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingRebookDetail}
              bedbankRoomsInfo={this.state.bedbankRoomsInfo}
              onClose={() => this.showRebookingModal({ itemId: null })}
            />
          )}

          {this.state.showingReconfirmDetail && (
            <ReconfirmModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingReconfirmDetail}
              onClose={() => this.showReconfirmModal({ itemId: null })}
            />
          )}

          {order.payments.length > 0 && (
            <Box mt={2}>
              <OrderDetailPayments payments={order.payments} />

              <NewRefundModal
                show={showingRefundDetail}
                onHide={this.hideRefundDetail}
                refreshData={this.onRefund}
                order={order}
                offer={getAccommodationOfferFromItemId(this.state.refundItemId)}
                itemId={this.state.refundItemId}
                roomId={this.state.refundRoomId}
                automatic={this.state.refundAutomatic}
                itemType={this.state.itemType}
                isAfterChangeDate={this.state.isAfterChangeDate}
                refundApprover={this.state.refundApprover}
                isRefundable={this.state.isAccommodationRefundable}
              />

              <RefundItemsReminderModal
                show={this.state.showingRefundReminderModal}
                hasCruises={hasRemindingCruises}
                hasExperiences={hasRemindingExperiences}
                hasInsurance={hasRemindingInsurances}
                hasFlights={hasRemindingFlights}
                hasCarHire={hasRemindingCarHire}
                onClose={this.props.refreshData}
              />
            </Box>
          )}

          <PaymentChargeSimulation order={order} paymentScheduleDetails={this.state.paymentScheduleDetails.result} />

          <BookingRequests order={order} />

          <OrderTermsAndConditions order={order} />

          {this.props.refunds?.result && this.props.refunds.result.length > 0 && (
            <OrderDetailAccountingData
              order={order}
              offers={offers}
              refunds={this.props.refunds}
              refreshData={this.props.refreshData}
              reservationsFetched={reservationsFetched}
              reservations={reservations as unknown as Array<App.OrderItemReservation>}
            />
          )}

          {this.state.partnerships && (
            <OrderDetailPartnerships customer={customer} partnerships={this.state.partnerships} order={order} />
          )}

          {earnOrderPartnerships && earnOrderPartnerships.length > 0 && this.state.earnOrder.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography> Rewards History</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.earnOrder?.map((reward) => (
                  <OrderDetailRewards
                    key={reward.prefix}
                    newPointsRequest={this.newPointsRequest}
                    customer={customer}
                    partnership={this.state.partnerships.find((partnership) => partnership.prefix === reward.prefix)}
                    order={order}
                    data={reward}
                    status={loyaltyStatus[reward.prefix]}
                    showForm={
                      order.items.length &&
                      loyaltyStatus[reward.prefix].isPostThirtyDays &&
                      loyaltyStatus[reward.prefix].isEligible
                    }
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {showVirtualCreditCardsDetails && (
            <OrderDetailVirtualCreditCards
              items={order.items}
              reservationsFetched={reservationsFetched}
              reservations={reservations}
            />
          )}
        </Box>

        {this.state.paymentPlanLoaded && this.state.paymentPlanData && (
          <>
            <OrderDetailPaymentPlanLogs data={this.state.paymentPlanData.plans} />

            {this.state.paymentPlanData.error_logs?.length > 0 && (
              <OrderDetailPaymentErrorLogs data={this.state.paymentPlanData.error_logs} />
            )}
          </>
        )}

        {order.order_gift && <OrderGift order={order} resendRedemptionEmail={resendOrderGiftDeliveryEmail} />}

        {this.props.order.business_id !== null && (
          <OrderDetailBusinessCredits businessCreditItems={order.business_credit_items} />
        )}
        <CancellationPolicyWarningModal
          showModal={this.state.shouldShowRefundWarningModal}
          closeModal={this.closeRefundWarningModal}
          showRefundModal={this.triggerRefund}
          setRefundComment={this.setRefundComment}
        />
      </Stack>
    );
  }
}

// Exported without router & redux HOC for testing
export const OrderDetailPageComponent = OrderDetailPage;

export default withRouter(withTenant(OrderDetailPage));
