import React from 'react';

import { Link, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import TENANTS from '~/consts/tenant';

import { formatDateOrdinalWithClock, unixToDate } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  isLoading?: boolean;
  carts: Array<{
    transactionKey: string;
    brand: string;
  }>;
  total: number;
  page: number;
  onPageChange: (page: number) => void;
};

type Cart = {
  transactionKey: string;
  brand: string;
};

const tenantMap = Object.fromEntries(Object.values(TENANTS).map((tenant) => [tenant.brand, tenant]));

const columns: Array<GridColDef> = [
  {
    field: 'transactionKey',
    headerName: 'Transaction key',
    sortable: false,
    width: 350,
    display: 'flex',
    renderCell: (params: GridRenderCellParams<Cart>) => (
      <Typography variant="body1">
        <Link
          href={`${customerPortalHost(tenantMap[params.row.brand])}/checkout-restore-cart?cartId=${params.value}`}
          fontWeight={500}
          target="_blank"
          rel="noreferrer"
          underline="hover"
        >
          {params.value}
        </Link>
      </Typography>
    ),
  },
  {
    field: 'user.fullName',
    headerName: 'Customer name',
    sortable: false,
    flex: 1,
    renderCell: (params) => params.row.user.fullName,
    display: 'flex',
  },
  {
    field: 'user.email',
    headerName: 'Customer email',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography title={params.row.user.email} noWrap>
        {params.row.user.email}
      </Typography>
    ),
    display: 'flex',
  },
  {
    field: 'offer_name',
    headerName: 'Offer name',
    sortable: false,
    flex: 1,
    renderCell: (params) => (params.row.rawItems?.length > 0 ? params.row.rawItems[0].offerSlug : null),
    display: 'flex',
  },
  {
    field: 'item_count',
    headerName: 'Packages',
    sortable: false,
    renderCell: (params) => params.row.rawItems?.length ?? 0,
    display: 'flex',
  },
  {
    field: 'updatedAt',
    headerName: 'Date',
    sortable: false,
    width: 200,
    renderCell: (params) => formatDateOrdinalWithClock(unixToDate(params.row.updatedAt)),
    display: 'flex',
  },
];

export default function CartPane({ carts, total, page, isLoading, onPageChange }: Props) {
  return (
    <DataGrid
      rows={carts}
      rowCount={total}
      columns={columns}
      pageSizeOptions={[10]}
      pagination
      paginationMode="server"
      paginationModel={{
        page: page,
        pageSize: 10,
      }}
      loading={isLoading}
      slots={{ pagination: GridPagination }}
      onPaginationModelChange={({ page }) => onPageChange(page)}
      getRowId={(row) => row.transactionKey}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
