import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import useCurrentUser from '~/hooks/useCurrentUser';

import UserSearchWidget from '../Common/UserSearchWidget';

export default function UsersPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSearchString = queryParams.get('q') || '';

  const [searchQuerySubmit, setSearchQuerySubmit] = useState(initialSearchString ?? '');
  const [formErrors] = useState<Array<string> | null>(null);
  const [recentUsers, setRecentUsers] = useState<Array<{ id: string; label: string; date: string }>>(() => {
    const saved = localStorage.getItem('recentlyViewedUsers');
    return saved ? JSON.parse(saved) : [];
  });

  const history = useHistory();

  const handleSubmit = (id, searchQuery: string, label: string) => {
    setSearchQuerySubmit(searchQuery);

    // Add the searched user ID and label to recent users
    if (searchQuery && label) {
      const userEntry = { id: searchQuery, label, date: new Date().toISOString() };
      const updatedUsers = [userEntry, ...recentUsers.filter((u) => u.id !== searchQuery)].slice(0, 20);
      setRecentUsers(updatedUsers);
      localStorage.setItem('recentlyViewedUsers', JSON.stringify(updatedUsers));
    }
  };

  useEffect(() => {
    if (!searchQuerySubmit.length) {
      return;
    }

    history.push({
      pathname: '/users/' + searchQuerySubmit,
    });
  }, [searchQuerySubmit, history]);

  const { user } = useCurrentUser();

  return (
    <>
      <UserSearchWidget
        id="fk_customer_id"
        label="Name / Email / QFF / Customer Support Code / User ID"
        formErrors={formErrors}
        loading={false}
        onChange={handleSubmit}
      />
      <div>
        <h2>Recent users</h2>
        <div>
          <a href={`/users/${user?.id_member}`}>{user?.email}</a> (you)
          {recentUsers.map((user) => (
            <div key={user.id}>
              {new Date(user.date).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short' })} -{' '}
              <a href={`/users/${user.id}`}>{user.label}</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
