import React, { useCallback, useEffect, useState } from 'react';

import { theme } from '~/theme';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import useQuery from '~/hooks/useQuery';

import UsersService from '~/services/UsersService';

import { updateChat } from '../../../services/SupportService';
import { Chat, ChatDeflection, ChatQuality } from '../types/Chat';

import ChatMessage from './ChatMessage';
import { QualityAssuranceModal } from './QualityAssuranceModal';

type Props = {
  onClose: () => void;
  loading: boolean;
  open: boolean;
  chat: Chat | null;
  messageId?: string;
  allChatTopics: Array<string>;
};

export function ChatModal({ open, onClose, loading, chat, messageId, allChatTopics }: Props) {
  const [user, setUser] = useState<App.User>(null);
  const [chatQuality, setChatQuality] = useState<ChatQuality>();
  const [chatDeflection, setChatDeflection] = useState<ChatDeflection>();
  const [chatTopics, setChatTopics] = useState<Array<string>>(chat?.topics ?? []);
  const [updatingChat, setUpdatingChat] = useState(false);
  const isSupport = useQuery().get('origin') === 'support';

  const handleQualityToggleChange = async (_, newAlignment: ChatQuality) => {
    if (!newAlignment) {
      return;
    }
    setChatQuality(newAlignment);
    setUpdatingChat(true);
  };

  const handleDeflectionToggleChange = async (_, newAlignment: ChatDeflection) => {
    if (!newAlignment) {
      return;
    }

    const body = {
      id: chat.id,
      quality: chatQuality,
      deflection: newAlignment,
      topics: chatTopics,
    };

    await updateChat(body);
    setChatDeflection(newAlignment);
  };

  useEffect(() => {
    if (chat) {
      UsersService.getUser(chat.customerId).then((user) => setUser(user));
      setChatQuality(chat.quality);
      setChatDeflection(chat.deflection);
      setChatTopics(chat.topics);
    }
  }, [chat]);

  const getFollowUpIcon = useCallback(() => {
    if (chat?.followUp?.status === 'ACTIVE') {
      return <QuestionMarkIcon fontSize="inherit" />;
    } else if (chat?.followUp?.status === 'CLOSED') {
      return <CheckIcon fontSize="inherit" />;
    } else {
      return null;
    }
  }, [chat?.followUp?.status]);

  const handleTopicChange = async (event) => {
    const body = {
      id: chat.id,
      quality: chatQuality,
      deflection: chat.deflection,
      topics: [event.target.value],
    };

    await updateChat(body);
    setChatTopics([event.target.value]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      maxWidth="md"
      fullWidth
      fullScreen={isSupport}
      disableEscapeKeyDown={isSupport}
    >
      {!isSupport && (
        <DialogTitle id="scroll-dialog-title">
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={1} sx={{ flex: 2 }}>
              Chat history {user && `with ${user.email}`}
              <Typography>
                Topic:{' '}
                <Select labelId="select-chat-topic-label" value={chatTopics ?? []} onChange={handleTopicChange}>
                  {allChatTopics.map((topic) => (
                    <MenuItem key={topic} value={topic}>
                      {topic}
                    </MenuItem>
                  ))}
                </Select>
              </Typography>
              {!!chat?.agents?.length && (
                <Accordion sx={{ mt: 1, border: `1px solid ${theme.palette.divider}` }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Agents list</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 1 }}>
                    {chat?.agents?.map((agent) => (
                      <List dense key={agent.id} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt={agent?.name} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={agent?.name}
                            secondary={
                              <>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {agent?.title}{' '}
                                </Typography>
                                {agent.email}
                              </>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </Stack>
            <Stack direction="column" spacing={1}>
              <Button onClick={onClose} endIcon={<CloseIcon />}></Button>
            </Stack>
          </Stack>
        </DialogTitle>
      )}

      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {loading && (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
            {!loading && !chat?.messages?.length && (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography variant="body1">No messages</Typography>
                </Grid>
              </Grid>
            )}
            {!loading &&
              chat?.messages?.map((message) => (
                <ChatMessage key={message.id} chat={chat} message={message} messageId={messageId} />
              ))}
            {updatingChat && (
              <QualityAssuranceModal
                chat={chat}
                chatQuality={chatQuality}
                onUpdateChatQuality={setChatQuality}
                onUpdatingChat={setUpdatingChat}
              />
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      {!isSupport && (
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: 3 }}>
          <Stack direction="row" gap={5} alignItems="flex-end" flexWrap="wrap">
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={2} alignItems="left">
                <Typography variant="body1">Chat Quality:</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <ToggleButtonGroup
                  color="primary"
                  disabled={updatingChat}
                  exclusive
                  onChange={handleQualityToggleChange}
                  size="small"
                  value={chatQuality}
                >
                  {Object.values(ChatQuality).map((quality) => (
                    <ToggleButton key={quality} value={quality}>
                      {quality}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={2} alignItems="center">
                <ToggleButtonGroup
                  color="primary"
                  disabled={updatingChat}
                  exclusive
                  onChange={handleDeflectionToggleChange}
                  size="small"
                  value={chatDeflection}
                >
                  {Object.values(ChatDeflection).map((deflection) => (
                    <ToggleButton key={deflection} value={deflection}>
                      {deflection}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={() => setUpdatingChat(true)}
                  variant="outlined"
                  color={chat?.followUp?.status ? 'primary' : 'secondary'}
                  endIcon={getFollowUpIcon()}
                >
                  {chat?.followUp?.team} Follow Up
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
