import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Chip, IconButton, Link, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import { PropertyResponse, deleteProperty, listProperties } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';
import AccommodationStatusIndicatorIcon from './AccommodationStatusIndicatorIcon';

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export interface AccommodationPropertiesResultsTableRef {
  refresh: () => void;
}

const AccommodationPropertiesResultsTable = forwardRef<AccommodationPropertiesResultsTableRef, Props>(
  function AccommodationPropertiesResultsTable({ searchInput, pageIndex, onPageChange }, ref) {
    const { enqueueSnackbar } = useSnackbar();
    const getProperties = useCallback(
      () =>
        listProperties({
          name: searchInput.propertyName,
          id: searchInput.id,
          page: String((pageIndex ?? 0) + 1),
          limit: String(PAGE_SIZE),
        }),
      [pageIndex, searchInput],
    );

    const { fetchReqState, refetch } = useAccommodationServiceFetch({
      fetchFn: getProperties,
    });

    useImperativeHandle(ref, () => ({
      refresh: refetch,
    }));

    const handleDeleteProperty = async (propertyId: string) => {
      if (window.confirm('Are you sure you want to delete this property?')) {
        try {
          await deleteProperty(propertyId);
          enqueueSnackbar('Successfully deleted property', { variant: 'success' });
          refetch(); // Refresh the table after deletion
        } catch (error) {
          enqueueSnackbar('Failed to delete property', { variant: 'error' });
        }
      }
    };

    const columns: Array<GridColDef<PropertyResponse>> = [
      {
        field: 'statusIndicator',
        headerName: '',
        align: 'center',
        display: 'flex',
        width: 40,
        valueGetter: (_value, row) => row.sppEnabled,
        renderCell: (params) => <AccommodationStatusIndicatorIcon sppEnabled={params.value} />,
      },
      {
        field: 'name',
        headerName: 'Property Name',
        display: 'flex',
        minWidth: 260,
        flex: 1,
        renderCell: (params) => (
          <Link
            href={`/accommodation/properties/${params.row.id}`}
            target="_blank"
            className="accommodation-properties-search-result-link"
          >
            {params.value}
          </Link>
        ),
        hideable: false,
      },
      {
        field: 'suppliers',
        headerName: 'Suppliers',
        display: 'flex',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        renderCell: (params) => (
          <Stack direction="column" spacing={0.5}>
            {params.row.hasBedbankMappings && <Chip size="small" label="Bedbank" color="primary" variant="outlined" />}
            {params.row.hasReservationMappings && <Chip size="small" label="LPC" color="primary" variant="outlined" />}
          </Stack>
        ),
        hideable: false,
      },
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 350,
        display: 'flex',
        hideable: true,
      },
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteProperty(params.row.id);
            }}
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    ];

    return (
      <>
        {fetchReqState.status === 'failed' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {JSON.stringify(fetchReqState.error)}
          </Alert>
        )}
        <DataGrid
          className="accommodation-properties-search-results-table"
          columns={columns}
          rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.properties : []}
          rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
          getRowHeight={() => 70}
          pageSizeOptions={[PAGE_SIZE]}
          paginationMode="server"
          paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          loading={fetchReqState.status === 'pending'}
          autoHeight
          disableColumnFilter
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </>
    );
  },
);

export default AccommodationPropertiesResultsTable;
