import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getOrderDetail } from '../../../services/OrdersService';
import UsersService from '../../../services/UsersService';
import Spinner from '../../Common/Spinner';

import OrderAtolPage from './OrderAtolPage';

class OrderAtolPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      dataError: '',
    };
  }

  componentDidMount() {
    this.refreshData().catch((err) => {
      this.setState({ dataError: err });
    });
  }

  refreshData = async () => {
    this.setState({ isReady: false }, async () => {
      const {
        match: { params },
      } = this.props;

      let order = undefined;
      try {
        order = await getOrderDetail(params.id_orders);
      } catch (e) {
        this.setState({ dataError: e.message });
        return;
      }
      const customer = await UsersService.getUser(order.fk_customer_id);

      this.setState({
        isReady: true,
        order,
        customer,
      });
    });
  };

  render() {
    const { order, customer, isReady } = this.state;
    const { dataError } = this.state;

    if (dataError) {
      return <div className="alert alert-danger">Failed to load data: {dataError}</div>;
    }

    if (!isReady) {
      return <Spinner />;
    }

    return (
      <div className="container">
        <OrderAtolPage order={order} customer={customer} refreshData={this.refreshData} />
      </div>
    );
  }
}

OrderAtolPageContainer.contextTypes = {
  order: PropTypes.object,
  user: PropTypes.object,
};

export default connect(null, {})(OrderAtolPageContainer);
