import { components } from '@luxuryescapes/contract-public-offer';

import { getExperienceOfferById } from '~/services/ExperiencesService';
import PublicOfferService from '~/services/PublicOfferService';
import { OrderOffer } from '~/services/SearchService';
import CruiseService from '~/services/cruises/OfferService';

const makeOrderOffer = (
  bk,
  offer:
    | components['schemas']['leHotelOffer']
    | components['schemas']['v1TourOffer']
    | components['schemas']['v2TourOffer']
    | components['schemas']['bedbankOffer'],
): Exclude<OrderOffer, null> | undefined => {
  if (offer.type === 'tour' || offer.type === 'tour_v2') {
    return { bk: offer.id, offerType: offer.type, vertical: 'tour' as const };
  }

  return { bk: offer.id, offerType: offer.type, vertical: 'hotel' as const };
};

const identifyAndBuildOrderListItem = async (
  bk: string,
  brand: App.Brands,
  region: string,
): Promise<Exclude<OrderOffer, null>> => {
  if (bk.startsWith('led:') || bk.startsWith('rez:')) {
    const res = await getExperienceOfferById({ id: bk, brand });
    return {
      vertical: 'experience',
      bk: res.result.id,
      offerType: res.result.offerType,
    };
  }

  try {
    const publicOfferResponse = await PublicOfferService.getPublicOfferById(bk, region, brand);
    const orderOffer = makeOrderOffer(bk, publicOfferResponse.result);
    if (orderOffer) {
      return orderOffer;
    }
  } catch (e) {
    const result = await CruiseService.getById(bk);
    return {
      vertical: 'cruise',
      bk: result.result.id,
      offerType: 'cruise',
    };
  }

  throw new Error('Offer ID not found');
};

export const buildOrderListItems = async (
  bkList: Array<string>,
  brand: App.Brands,
  region: string,
): Promise<Array<Exclude<OrderOffer, null>>> => {
  const results = await Promise.allSettled(bkList.map((bk) => identifyAndBuildOrderListItem(bk, brand, region)));
  return results
    .filter((r): r is PromiseFulfilledResult<Exclude<OrderOffer, null>> => r.status === 'fulfilled')
    .map((r) => r.value);
};

export const doesListMatch = (a: Array<OrderOffer>, b: Array<OrderOffer>) => {
  return a.length === b.length && a.every((_, i) => a[i].bk === b[i].bk);
};
