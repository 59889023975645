import React, { useCallback } from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Alert, Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import {
  MappedInternalRoom,
  MappingLog,
  Room,
  SourceModel,
  TargetModel,
  getMappingLogs,
} from '~/services/AccommodationService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

const columns: Array<GridColDef<MappingLog>> = [
  {
    field: 'isVerified',
    headerName: 'Verified?',
    minWidth: 100,
    display: 'flex',
    hideable: true,
    renderCell: (params) => (params.value ? <CheckCircle color="success" /> : <Cancel color="error" />),
  },
  {
    field: 'actionedBy',
    headerName: 'Actioned By',
    minWidth: 350,
    display: 'flex',
    hideable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    minWidth: 200,
    display: 'flex',
    hideable: true,
  },
  {
    field: 'actionNotes',
    headerName: 'Comments',
    minWidth: 300,
    display: 'flex',
    hideable: true,
  },
];

interface Props {
  room: Room;
  mappedRoom: MappedInternalRoom;
}

export default function MappingLogTable({ room, mappedRoom }: Props) {
  const fetchMappingLogs = useCallback(
    () =>
      getMappingLogs({
        sourceModel: 'internal_rooms' as SourceModel,
        sourceId: mappedRoom.internalRoomId,
        targetModel: 'rooms' as TargetModel,
        targetId: room.id,
      }),
    [mappedRoom.internalRoomId, room.id],
  );

  const { fetchReqState } = useAccommodationServiceFetch({
    fetchFn: fetchMappingLogs,
  });

  if (fetchReqState.status === 'pending' || fetchReqState.status === 'uninitiated') {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (fetchReqState.status === 'failed') {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {JSON.stringify(fetchReqState.error)}
      </Alert>
    );
  }

  return (
    <DataGrid
      className="mapping-logs-table"
      columns={columns}
      rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
      getRowHeight={() => 'auto'}
      autoHeight
      disableRowSelectionOnClick
      hideFooterPagination
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      slots={{
        toolbar: GridToolbar,
      }}
    />
  );
}
