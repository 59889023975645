import React, { useCallback } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

const FacilitiesTable = ({ facilityGroups }) => {
  return facilityGroups.map(({ name, values }) => (
    <div key={name} className="bedbank-facility-group">
      <div className="bedbank-facility-group-name">{name}</div>
      {values.map((x) => (
        <div key={x.name} className="bedbank-facility-value">
          {x.name}
        </div>
      ))}
    </div>
  ));
};

export default function Facilities({ facilityGroups, name }) {
  const { isToggled: open, toggle: toggleOpen } = useToggleState(false);

  const handleChange = useCallback((_, isExpanded) => toggleOpen(isExpanded), [toggleOpen]);

  return (
    <Accordion expanded={open} onChange={handleChange} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{name}</AccordionSummary>
      <AccordionDetails>
        <div className="bedbank-facilities">
          <FacilitiesTable facilityGroups={facilityGroups} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
