import React from 'react';

import { Chip, Link, Stack, Tooltip } from '@mui/material';

interface Props {
  regions: Array<string>;
}

export function AgentHubCommissionRegions({ regions }: Props) {
  const regionsToShow = regions.slice(0, 4);
  const remaining = regions.length - regionsToShow.length;
  const remainingRegions = regions.slice(regionsToShow.length);
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {regionsToShow.map((region: string) => (
        <Chip key={region} label={region} variant="outlined" />
      ))}
      {remaining > 0 && (
        <Tooltip title={`${remainingRegions.join(' • ')}`}>
          <Link component="div" color="inherit" variant="caption">
            +{remaining} more
          </Link>
        </Tooltip>
      )}
    </Stack>
  );
}
