export const OPERATION_SOFT_DELETE = 'soft_delete';

export const BOOKING_INFORMATION = 'Booking Information';

export const FLIGHTS = 'Flights';

export const OPERATIONS_SUPPORT = 'Operations & Support';

export const OTHER = 'Other';

export const INVOICE = 'Invoice';

export const CUSTOM_OFFER_PDF = 'Custom Offer PDF';

export const PUBLIC_ATTACHMENT = 'Public PDF Attachment';

export const COMMENT_TYPES = [FLIGHTS, BOOKING_INFORMATION, OPERATIONS_SUPPORT, INVOICE, OTHER, PUBLIC_ATTACHMENT];

export const CUSTOM_OFFER_COMMENT_TYPES = [CUSTOM_OFFER_PDF, ...COMMENT_TYPES];

export const ATTACHMENT_COMMENT_TYPES = [INVOICE, CUSTOM_OFFER_PDF, PUBLIC_ATTACHMENT];

export const VISIBLE_TO_PUBLIC = [CUSTOM_OFFER_PDF, FLIGHTS, PUBLIC_ATTACHMENT];
export const INTERNAL = [BOOKING_INFORMATION, INVOICE, OTHER, OPERATIONS_SUPPORT];

export const isAttachmentComment = (commentType: string) => ATTACHMENT_COMMENT_TYPES.includes(commentType);
