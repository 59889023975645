import qs from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-accommodation';

import { json_headers, request } from './common';
import { HttpResponse } from './types';

export type PropertyResponse = definitions['AdminProperty'];
export type Room = definitions['AdminRoomDetail'];
export type PropertyDetailResponse = definitions['AdminPropertyDetail'];
export type ImportPropertyPayload = actions['importProperty']['parameters']['body']['payload'];
export type ImportPropertyResponse = definitions['StatusLookup'];
export type ImportSupplierPayload = actions['addSupplierToProperty']['parameters']['body']['payload'];
export type ProgressStatusResponse = definitions['StatusResponse'];
export type MappedSupplierProperty = definitions['AdminMappedSupplierProperty'];
export type SupplierProperty = definitions['AdminSupplierProperty'];
export type InternalService = definitions['InternalService'];
export type RoomResponse = definitions['AdminRoom'];
export type InternalRoom = definitions['AdminInternalRoom'];
export type SupplierRoom = definitions['AdminSupplierRoom'];
export type MappedInternalRoom = definitions['AdminMappedInternalRoom'];
export type RoomMappingResponse = definitions['AdminPropertyRoomMappings'];
export type ExternalSuppliers = definitions['ExternalSupplier'];
export type InternalServices = definitions['InternalService'];
export type RoomMapping = definitions['AdminRoomMapping'];
export type ExtendedMappedInternalRoom = MappedInternalRoom & { roomId: string };
export type RoomMappingPayload = actions['postAdminPropertyRoomMappings']['parameters']['body']['payload'];
export type ValidationErrorCodes = definitions['ValidationErrorCodes'];
export type ValidationErrorDetails = definitions['ValidationErrorDetails'];
export type VerifyMappedRoomPayload = actions['postAdminVerifyRoomMappings']['parameters']['body']['payload'];
export type SourceModel = definitions['SourceModel'];
export type TargetModel = definitions['TargetModel'];
export type MappingLog = definitions['AdminMappingLog'];

function basePath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation';
}

function baseAdminPath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation/admin';
}

export const listProperties = ({
  name,
  id,
  limit,
  page,
}: actions['listAdminProperties']['parameters']['query']): Promise<
  HttpResponse<{ total: number; properties: Array<PropertyResponse> }>
> => {
  const url = `${baseAdminPath()}/properties?${qs.stringify({ name, id, limit, page })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getProperty = ({
  propertyId,
}: actions['getAdminProperty']['parameters']['path']): Promise<HttpResponse<PropertyDetailResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}`;
  return request(url, {
    method: `GET`,
  });
};

export const listPropertySuppliers = ({
  propertyId,
}: actions['getAdminSupplierProperties']['parameters']['path']): Promise<
  HttpResponse<Array<MappedSupplierProperty>>
> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers`;
  return request(url, {
    method: 'GET',
  });
};

export const getUnmappedSuppliers = ({
  name,
  id,
  limit,
  page,
}: actions['getAdminUnmappedSupplierProperties']['parameters']['query']): Promise<
  HttpResponse<{ total: number; supplierProperties: Array<SupplierProperty> }>
> => {
  const url = `${baseAdminPath()}/properties/unmapped-suppliers?${qs.stringify({ name, id, limit, page })}`;
  return request(url, {
    method: 'GET',
  });
};

export const patchPropertySupplier = (
  { propertyId, supplierId }: actions['patchAdminSupplierProperty']['parameters']['path'],
  data: actions['patchAdminSupplierProperty']['parameters']['body']['AdminPatchSupplierProperty'],
): Promise<HttpResponse<MappedSupplierProperty>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers/${supplierId}`;
  return request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(data),
  });
};

export const listRooms = ({
  propertyId,
}: actions['getAdminRooms']['parameters']['path']): Promise<HttpResponse<Array<RoomResponse>>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms`;
  return request(url, {
    method: 'GET',
  });
};

export const importProperty = ({
  propertyId,
  internalService,
  validationOverrides,
}: ImportPropertyPayload): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/import-property`;
  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ propertyId, internalService, validationOverrides }),
  });
};

export const importSupplier = (
  basePropertyId: string,
  { propertyId, internalService, validationOverrides }: ImportSupplierPayload,
): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/properties/${basePropertyId}/suppliers`;
  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ propertyId, internalService, validationOverrides }),
  });
};

export const getProgressStatuses = (id: string): Promise<HttpResponse<Array<ProgressStatusResponse>>> => {
  const url = `${basePath()}/progress/statuses?${qs.stringify({ id })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getRoomMappings = (propertyId: string): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `GET`,
  });
};

export const postRoomMappings = (
  propertyId: string,
  mappings: RoomMappingPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify(mappings),
  });
};

export const deleteRoomMappings = (
  propertyId: string,
  mappings: RoomMappingPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `DELETE`,
    headers: json_headers,
    body: JSON.stringify(mappings),
  });
};

export const createAndMapRoom = (
  propertyId: string,
  internalRoomIds: Array<string>,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/create-and-map`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify({ internalRoomIds }),
  });
};

export const verifyMappedRooms = (
  propertyId: string,
  payload: VerifyMappedRoomPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/verify-mappings`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify(payload),
  });
};

export const getMappingLogs = ({
  sourceModel,
  sourceId,
  targetModel,
  targetId,
}: actions['getMappingLogs']['parameters']['query']): Promise<HttpResponse<Array<definitions['AdminMappingLog']>>> => {
  const url = `${baseAdminPath()}/mapping-logs?${qs.stringify({ sourceModel, sourceId, targetModel, targetId })}`;
  return request(url, {
    method: `GET`,
  });
};

export const mapSupplierProperty = (propertyId: string, supplierId: string): Promise<HttpResponse<object>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers/${supplierId}`;
  return request(url, {
    method: `POST`,
  });
};

export const unmapSupplierProperty = (propertyId: string, supplierId: string): Promise<HttpResponse<object>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers/${supplierId}`;
  return request(url, {
    method: `DELETE`,
  });
};

export const deleteProperty = (propertyId: string): Promise<HttpResponse<void>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}`;
  return request(url, {
    method: 'DELETE',
  });
};
