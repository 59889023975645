import {
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_NONE,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_PARTIAL_CALCULATED,
} from '~/consts/refund';

import { Refund } from '../NewModal';

export function hasRefundItem(refund: Refund) {
  // Complimentary addons have cash_amount == 0
  // Other product types can also have cash_amount == 0 in case CS wants to charge the entire deposit amount as Customer Fee
  return (
    (refund.item_metadata.cash_amount ||
      (refund.item_metadata.cash_amount === 0 &&
        (refund.is_addon ||
          refund.is_experience ||
          refund.is_tour ||
          refund.is_accommodation ||
          refund.is_cruise ||
          refund.is_car_hire ||
          refund.is_bedbank ||
          refund.is_subscription ||
          refund.is_tour_optional_experience)) ||
      refund.item_metadata.promo_percentage === 1) &&
    (refund.variant_of_refund === VARIANT_OF_REFUND_ALL ||
      refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL ||
      refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL_CALCULATED ||
      refund.variant_of_refund === VARIANT_OF_REFUND_NONE)
  );
}
