/* eslint-disable react/display-name */

/* eslint-disable no-restricted-imports */
import React from 'react';

import Form from '@rjsf/mui';
import { ArrayFieldTemplateProps, RJSFSchema, TitleFieldProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import uniq from 'lodash/uniq';
import { WithSnackbarProps, withSnackbar } from 'notistack';

import { ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import ReactHookFormAdapter from '~/components/Common/Forms/widgets/ReactHookFormAdapter';

import bedbankService from '../../../../services/BedbankService';
import { reportError } from '../../../../utils/reportError';
import SearchOrderingPane from '../../../Offers/Detail/SearchOrdering';
import RateInfo from '../Home/RateInfo';

import ArchivedPromotionPane from './ArchivedPromotionPane';
import BedbankRoomManagement from './BedbankRoomManagement';
import BedbankRoomMapping from './BedbankRoomMapping';
import {
  getPromotionsSchema,
  getRoomsSchema,
  getSchema,
  getSellPricesSchema,
  getTagsSchema,
  getVideosSchema,
  uiSchemas,
} from './FormSchemas';
import ImagesForm from './ImagesForm';
import PromotionMultiSelect from './PromotionMultiSelect';
import PromotionsArrayFieldTemplate from './PromotionsArrayFieldTemplate';
import { isArchivedPromotion } from './utils/isArchivedPromotion';
import { mapPromotion } from './utils/mapPromotion';

interface EditFormProps {
  property: App.Bedbank.PropertyEdit;
  setProperty: (property: App.Bedbank.PropertyEdit) => void;
  onForceDecoupleRoomsClick: (ids: Array<string>) => void;
  isAdmin: boolean;
}

const buttonMessages = {
  primary: 'Save',
  info: 'Saving...',
  success: 'Saved',
  error: 'Failed to save',
};

const hideSaveButtonTabs = [10];

interface State {
  tabIndex: number;
  calendarFetching: boolean;
  calendar: {
    rooms: Array<{
      id: string;
      inclusive: number;
    }>;
  };
  property: App.Bedbank.PropertyEdit;
  activePromotions: Array<App.Bedbank.PromotionsEdit>;
  archivedPromotions: Array<App.Bedbank.PromotionsEdit>;
  saveState: 'success' | 'primary' | 'error' | 'info';
  alertState: {
    message: string;
  } | null;
  schemas: {
    main: RJSFSchema;
    sellPrices: any;
    promotions: any;
    rooms: any;
    roomMapping: any;
    videos: any;
    campaigns: any;
  };
  roomsExpansionStatuses: Array<boolean>;
  videos: Array<App.Bedbank.PropertyVideoResponse>;
  roomMappings: {
    update: App.Bedbank.SupplierRoomMappingPayload;
    remove: App.Bedbank.SupplierRoomMappingPayload;
    automappingFlagUpdate: App.Bedbank.SupplierRoomAutomappingFlagPayload;
  } | null;
  notistack: any;
  hash?: string;
}

const BedbankContext = React.createContext<State>({} as State);

function sellRoomTitleFieldTemplate(props: TitleFieldProps) {
  const { id } = props;
  const index = Number(id.match(/[0-9]+/i)[0]);
  const filteredRooms = this.state.property.rooms.filter((r) => r.promotionId);
  const room = filteredRooms[index];
  return <legend id={id}>{room.name}</legend>;
}

function roomTitleFieldTemplate(props: TitleFieldProps) {
  const { id } = props;
  const index = Number(id.match(/[0-9]+/i)[0]);
  const room = this.state.property.rooms[index];
  const roomCalendar = (this.state.calendar.rooms ?? []).find((x) => x.id === room.id);
  return (
    <legend id={id}>
      {room.name} {roomCalendar && <CheckIcon color="success" />}
    </legend>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `bedbank-tab-${index}`,
    'aria-controls': `bedbank-tabpanel-${index}`,
  };
}

function TabPanel({ children, value, index, ...rest }: TabPanelProps) {
  if (value !== index) {
    return null;
  }

  return (
    <Box py={2} role="tabpanel" id={`bedbank-tabpanel-${index}`} aria-labelledby={`bedbank-tab-${index}`} {...rest}>
      {children}
    </Box>
  );
}

function requestImageMapper(image) {
  return {
    id: image.id,
    hidden: image.hidden,
    order: image.order,
    title: image.title,
    is_hero: image.is_hero,
    is_deleted: image.is_deleted,
    source: image.source,
  };
}

function roomDescriptionUpdateContext(props) {
  const { markdownId } = props;
  const index = Number(markdownId.match(/[0-9]+/i)[0]);
  const room = this.state.property.rooms[index];

  return {
    propertyName: this.props.property.name,
    roomName: room.name,
    inclusions: this.props.property.inclusions,
    model: 'room-type',
    field: 'description',
  };
}

class BedbankPropertiesEditForm extends React.Component<EditFormProps & WithSnackbarProps, State> {
  constructor(props: EditFormProps & WithSnackbarProps) {
    super(props);

    const numberOfRoomAccordionElements = props.property.rooms.length;
    const arrayWithNumberOfAccordionElements = Array(numberOfRoomAccordionElements);
    const roomsExpansionElements = arrayWithNumberOfAccordionElements.fill(false);

    this.state = {
      tabIndex: Number(window.location.hash?.replace('#', '') ?? 0),
      calendarFetching: false,
      calendar: {
        rooms: [],
      },
      property: {
        ...props.property,
        promotions: props.property.promotions.map((promotion) =>
          mapPromotion(promotion, isArchivedPromotion(promotion)),
        ),
      },
      activePromotions: props.property.promotions.filter((promotion) => !isArchivedPromotion(promotion)),
      archivedPromotions: props.property.promotions.filter((promotion) => isArchivedPromotion(promotion)),
      saveState: 'primary',
      alertState: null,
      schemas: {
        main: getSchema(),
        sellPrices: getSellPricesSchema(),
        promotions: getPromotionsSchema(props.property),
        rooms: getRoomsSchema(props.property),
        roomMapping: null,
        videos: getVideosSchema(),
        campaigns: null,
      },
      roomsExpansionStatuses: roomsExpansionElements,
      videos: [],
      roomMappings: null,
      notistack: null,
    };
  }

  componentDidMount = async () => {
    this.fetchCalendarData();
    const tagsSchema = await getTagsSchema();
    this.setState({ schemas: { ...this.state.schemas, campaigns: tagsSchema } });
  };

  uiSchemas = (props: EditFormProps) => ({
    main: {
      ...uiSchemas(props).main,
    },
    sellPrices: {
      ...uiSchemas(props).sellPrices,
      rooms: {
        ...uiSchemas(props).sellPrices.rooms,
        items: {
          ...uiSchemas(props).sellPrices.rooms.items,
          'ui:TitleFieldTemplate': sellRoomTitleFieldTemplate.bind(this),
        },
      },
    },
    promotions: {
      ...uiSchemas(props).promotions,
      items: {
        ...uiSchemas(props).promotions.items,

        restrictToRates: {
          'ui:widget': ReactHookFormAdapter,
          'ui:options': {
            ReactHookFormComponent: PromotionMultiSelect,
            props: {
              property: this.state.property,
              setRates: this.selectRatesInForm,
              activePromotions: this.state.activePromotions,
            },
          },
        },
      },
    },
    rooms: {
      ...uiSchemas(props).rooms,
      items: {
        ...uiSchemas(props).rooms.items,
        descriptionOverride: {
          ...uiSchemas(props).rooms.items.descriptionOverride,
          'ui:generatorContext': {
            propertyName: props.property.name,
            roomName: '',
            model: 'room-type',
            field: 'description',
          },
          'ui:options': {
            updateContext: roomDescriptionUpdateContext.bind(this),
          },
        },
        'ui:TitleFieldTemplate': roomTitleFieldTemplate.bind(this),
      },
    },
    roomMapping: {
      ...uiSchemas(props).roomMapping,
      items: {
        ...uiSchemas(props).roomMapping.items,
      },
    },
    videos: uiSchemas(props).videos,
    campaigns: {
      ...uiSchemas(props).campaigns,
    },
  });

  selectRatesInForm = (index: number, selectedItems: Array<string>, append = false) => {
    const promoToBeChanged = this.state.activePromotions[index];
    const newRestrictions = append ? [...promoToBeChanged.restrictToRates, ...selectedItems] : selectedItems;

    const duplicateRates = newRestrictions.filter((item, index: number, array) => array.indexOf(item) !== index);

    if (duplicateRates.length > 0) {
      this.props.enqueueSnackbar(
        `Some duplicate rates were provided: ${duplicateRates.join(', ')}. They have been automatically deduped.`,
        {
          variant: 'warning',
        },
      );
    }

    const newPromotionsState = [
      ...this.state.property.promotions.map((p) => ({
        ...p,
        restrictToRates: p.promoId === promoToBeChanged.promoId ? uniq(newRestrictions) : p.restrictToRates,
      })),
    ];

    this.setState({
      property: {
        ...this.state.property,
        promotions: newPromotionsState,
      },
      activePromotions: newPromotionsState.filter((promotion) => !isArchivedPromotion(promotion)),
      archivedPromotions: newPromotionsState.filter((promotion) => isArchivedPromotion(promotion)),
      saveState: 'primary',
    });
  };

  handleMainChange = (form) => {
    this.setState({
      property: {
        ...this.state.property,
        type: form.formData.type,
        airport: form.formData.airport,
        promoteAsBundle: !!form.formData.promoteAsBundle,
        displayPricingAsPerPerson: !!form.formData.displayPricingAsPerPerson,
        displayDecoupledRooms: !!form.formData.displayDecoupledRooms,
        isFlightEnabled: !!form.formData.isFlightEnabled,
        isPreferred: !!form.formData.isPreferred,
        status: form.formData.status,
        profile: form.formData.profile,
        domain: form.formData.domain,
        tagline: form.formData.tagline,
        description: form.formData.description,
        additionalPromoContent: form.formData.additionalPromoContent,
        facilitiesOverride: form.formData.facilitiesOverride,
        attractionsOverride: form.formData.attractionsOverride,
        finePrintOverride: form.formData.finePrintOverride,
        locationTitleOverride: form.formData.locationTitleOverride || null,
        highlights: form.formData.highlights,
        videos: form.formData.videos,
        logoId: form.formData.logoId,
      },
      saveState: 'primary',
    });
  };

  handleSellPricesChange = (form) => {
    this.setState({
      property: {
        ...this.state.property,
        sellIsEnabled: !!form.formData.sellIsEnabled,
        sell: form.formData.sell,
        rooms: this.state.property.rooms.map((room) => {
          const roomFormData = form.formData.rooms.find((r) => r.id === room.id);
          if (roomFormData) {
            return roomFormData;
          }
          return room;
        }),
      },
      saveState: 'primary',
    });
  };

  handleRoomsChange = (form) => {
    this.setState({
      property: {
        ...this.state.property,
        rooms: form.formData,
      },
      saveState: 'primary',
    });
  };

  handlePromotionsChange = (form) => {
    const activePromotions = form.formData.map((promo) => ({
      ...promo,
      inclusions: (promo.inclusions ?? []).map((inclusion) => ({
        text: inclusion?.text ?? '',
        category: inclusion?.category ?? '',
        icon: inclusion?.icon ?? '',
        isHighlighted: inclusion?.isHighlighted ?? false,
      })),
    }));

    this.setState({
      activePromotions,
      property: {
        ...this.state.property,
        // since promotion form data only contains unarchived promotions, we need to merge the new form data with the archived promotion for the new state
        promotions: [...activePromotions, ...this.state.archivedPromotions],
      },
      saveState: 'primary',
    });
  };

  handleVideosChange = (form) => {
    form.formData.forEach((video) => {
      if (video.url && (!video.id || !video.type)) {
        switch (true) {
          case video.url.includes('youtube.com'):
          case video.url.includes('youtub.be'):
            video.type = 'youtube';
            video.id = video.url.includes('v=') ? video.url.split('v=')[1] : video.url.split('/').pop();
            break;

          case video.url.includes('vimeo.com'):
            video.type = 'vimeo';
            video.id = video.url.split('/').pop();
            break;
        }
      }
    });

    this.setState({
      property: {
        ...this.state.property,
        videos: form.formData,
      },
      saveState: 'primary',
    });
  };

  handleTagsChange = (form) => {
    this.setState({
      property: {
        ...this.state.property,
        campaigns: form.formData,
      },
      saveState: 'primary',
    });
  };

  handleImagesChange = (images) => {
    this.setState({
      property: {
        ...this.state.property,
        images,
      },
      saveState: 'primary',
    });
  };

  handleLogoChange = (logoId) => {
    this.setState({
      property: {
        ...this.state.property,
        logoId,
      },
      saveState: 'primary',
    });
  };

  handleRoomMappingChange = ({ update, remove, automappingFlagUpdate }) => {
    this.setState({
      roomMappings: {
        update,
        remove,
        automappingFlagUpdate,
      },
      saveState: 'primary',
    });
  };

  onSubmit = (form) => {
    form.errors = [];
    form.errorSchema = {};

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (this.state.saveState !== 'info') {
      const sellMap = (sell: App.Bedbank.PropertyEdit['sell']) => ({
        ...sell,
        taxesAndFees: Number(sell.taxesAndFees ?? 0),
        propertyFees: Number(sell.propertyFees ?? 0),
        price: Number(sell.price ?? 0),
        value: Number(sell.value ?? 0),
        valueAdd: Number(sell.valueAdd ?? 0),
      });

      if (this.state.roomMappings) {
        const { update, remove, automappingFlagUpdate } = this.state.roomMappings;
        this.setState(
          {
            saveState: 'info',
          },
          async () => {
            try {
              // Delete should happens before update, to account for the flow where an existing mapped room is deleted, then re-added to the same property room.
              if (remove?.length > 0) {
                await bedbankService.deleteMappedRooms(this.state.property.id, remove);
              }
              if (update?.length > 0) {
                await bedbankService.createMappedRooms(this.state.property.id, update);
              }
              if (automappingFlagUpdate?.length > 0) {
                await bedbankService.setAutomappingFlag(this.state.property.id, automappingFlagUpdate);
              }
              // everything works we reload to get latest data rather than trying to update the state and amend the UI
              window.location.reload();
            } catch (error) {
              this.setState({ saveState: 'error' });
              reportError(error);
            }
          },
        );
        return;
      }

      this.setState(
        {
          saveState: 'info',
        },
        () => {
          const updatedProperty = {
            type: self.state.property.type,
            airport: self.state.property.airport,
            promoteAsBundle: !!self.state.property.promoteAsBundle,
            displayPricingAsPerPerson: !!self.state.property.displayPricingAsPerPerson,
            displayDecoupledRooms: !!self.state.property.displayDecoupledRooms,
            isFlightEnabled: !!self.state.property.isFlightEnabled,
            isPreferred: !!self.state.property.isPreferred,
            status: self.state.property.status,
            profile: self.state.property.profile,
            domain: self.state.property.domain,
            tagline: self.state.property.tagline,
            description: self.state.property.description,
            additionalPromoContent: self.state.property.additionalPromoContent,
            promotions: self.state.property.promotions.map((promotion) =>
              mapPromotion(promotion, isArchivedPromotion(promotion)),
            ),
            sellIsEnabled: !!self.state.property.sellIsEnabled,
            sell: sellMap(self.state.property.sell),
            rooms: self.state.property.rooms.map((r) => ({
              ...r,
              sell: sellMap(r.sell),
            })),
            facilitiesOverride: self.state.property.facilitiesOverride,
            attractionsOverride: self.state.property.attractionsOverride,
            finePrintOverride: self.state.property.finePrintOverride,
            locationTitleOverride: self.state.property.locationTitleOverride,
            highlights: self.state.property.highlights,
            images: self.state.property.images.map(requestImageMapper),
            videos: self.state.property.videos,
            campaigns: self.state.property.campaigns,
            logoId: self.state.property.logoId,
            updatedAt: self.state.property.updatedAt,
          };

          bedbankService
            .patchPropertyEdit(this.props.property.id, updatedProperty)
            .then((response) => {
              self.setState({
                property: {
                  ...response.result,
                  promotions: response.result.promotions.map((promotion) =>
                    mapPromotion(promotion, isArchivedPromotion(promotion)),
                  ),
                },
                activePromotions: response.result.promotions.filter((promotion) => !isArchivedPromotion(promotion)),
                archivedPromotions: response.result.promotions.filter((promotion) => isArchivedPromotion(promotion)),
                schemas: {
                  ...this.state.schemas,
                  rooms: getRoomsSchema(response.result),
                },
                saveState: 'success',
              });
              self.props.setProperty(response.result);
            })
            .catch(function (e) {
              if (e.status === 409) {
                const errorMessage =
                  'The property has already been updated. Needs to refresh the page and try to save it again.';

                self.setState({
                  saveState: 'error',
                  alertState: {
                    message: errorMessage,
                  },
                });
                self.props.enqueueSnackbar(errorMessage, { variant: 'error' });
              } else {
                self.setState({ saveState: 'error' });
                self.props.enqueueSnackbar(e.message || 'Unable to save bedbank property!', {
                  variant: 'error',
                });
              }

              reportError(e);
            });
        },
      );
    }
  };

  fetchCalendarData = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    this.setState(
      {
        calendarFetching: true,
      },
      () => {
        bedbankService
          .getAllCalendar(this.props.property.id)
          .then((response) => {
            self.setState({
              calendarFetching: false,
              calendar: { rooms: response.result.rooms },
            });
          })
          .catch(function (e) {
            self.setState({ calendarFetching: false });
            reportError(e);
          });
      },
    );
  };

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    });
    window.location.hash = String(newValue);
  };

  createPropertyImage = async (imageServiceId) => {
    const { result: imageData } = await bedbankService.createPropertyImage(this.props.property.id, {
      imageServiceId,
    });

    return imageData;
  };

  createRoomImage = async (roomId, imageServiceId) => {
    const { result: imageData } = await bedbankService.createRoomImage(roomId, {
      imageServiceId,
    });

    return imageData;
  };

  handleRoomImagesChange = (roomId, images) => {
    this.setState({
      property: {
        ...this.state.property,
        rooms: this.state.property.rooms.map((room) => {
          if (room.id === roomId) {
            return {
              ...room,
              images,
            };
          }
          return room;
        }),
      },
      saveState: 'primary',
    });
  };

  toggleAllRoomImages = () => {
    const isAllExpanded = this.state.roomsExpansionStatuses.some((isExpanded) => isExpanded);
    this.setState({
      roomsExpansionStatuses: this.state.roomsExpansionStatuses.map(() => !isAllExpanded),
    });
  };

  toggleRoomImages = (index) => {
    const newState = [...this.state.roomsExpansionStatuses];
    newState[index] = !this.state.roomsExpansionStatuses[index];
    this.setState({
      roomsExpansionStatuses: newState,
    });
  };

  handlePromotionDelete = (index) => {
    const promoToBeDeleted = this.state.activePromotions[index];
    const newPromotionsState = [
      ...this.state.property.promotions.map((p) => ({
        ...p,
        deletedAt: p.promoId === promoToBeDeleted.promoId ? new Date().toISOString() : p.deletedAt,
      })),
    ];

    this.setState({
      property: {
        ...this.state.property,
        promotions: newPromotionsState,
      },
      activePromotions: newPromotionsState.filter((promotion) => !isArchivedPromotion(promotion)),
      archivedPromotions: newPromotionsState.filter((promotion) => isArchivedPromotion(promotion)),
    });
  };

  handleArchivedPromotionUpdate = (extendedPromotion: App.Bedbank.PromotionsEdit) => {
    const existingPromotions = this.state.archivedPromotions.filter(
      (promotion) => promotion.promoId !== extendedPromotion.promoId,
    );

    // check to make sure that new dates make the promotion active before moving to activePromotions.
    extendedPromotion.deletedAt = undefined;
    const isActivePromotion = !isArchivedPromotion(extendedPromotion);

    this.setState({
      property: {
        ...this.state.property,
        promotions: [extendedPromotion, ...existingPromotions].map((promotion) =>
          mapPromotion(promotion, isArchivedPromotion(promotion)),
        ),
      },
      activePromotions: isActivePromotion
        ? [...this.state.activePromotions, extendedPromotion]
        : this.state.activePromotions,
      archivedPromotions: isActivePromotion ? existingPromotions : [extendedPromotion, ...existingPromotions],
    });
  };

  /*
    Custom template for promotion tab. When deleting a promotion, the promotion is not deleted from the array, but instead deletedAt is set to the current date string.
    If there's an easier way to override the delete button's behaviour without having to override the entire template, please do so.
   */
  arrayFieldTemplate = (props: ArrayFieldTemplateProps) => (
    <PromotionsArrayFieldTemplate {...props} handleDelete={this.handlePromotionDelete} />
  );

  render() {
    const isAllRoomImagesExpanded = this.state.roomsExpansionStatuses.some((isExpanded) => isExpanded);
    const showSaveButton = !hideSaveButtonTabs.includes(this.state.tabIndex);

    return (
      <Box sx={{ paddingTop: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="basic tabs">
            <Tab label="Main" {...a11yProps(0)} />
            <Tab label="Promotions" {...a11yProps(1)} />
            <Tab label="Rooms" {...a11yProps(2)} />
            <Tab label="Room mapping" {...a11yProps(3)} />
            <Tab label="Room Images" {...a11yProps(4)} />
            <Tab label="Sell Prices" {...a11yProps(5)} />
            <Tab label="Property Images" {...a11yProps(6)} />
            <Tab label="Marketing & Tags" {...a11yProps(7)} />
            <Tab label="Archived Promotions" {...a11yProps(8)} />
            <Tab label="Search Ranking" {...a11yProps(9)} />
            <Tab label="Rates" {...a11yProps(10)} />
          </Tabs>
        </Box>

        <TabPanel value={this.state.tabIndex} index={0}>
          <BedbankContext.Provider value={this.state}>
            <Form
              schema={this.state.schemas.main}
              validator={validator}
              formData={this.state.property}
              uiSchema={this.uiSchemas(this.props).main}
              onSubmit={this.onSubmit}
              onChange={this.handleMainChange}
            />

            <Typography pt={1} variant="h5">
              Logo
            </Typography>
            <ImageUploadField
              label="logo"
              value={this.state.property.logoId}
              multiple={false}
              onUpload={(field_key, newValue) => {
                this.handleLogoChange(newValue);
              }}
            />
          </BedbankContext.Provider>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={1}>
          <Form
            schema={this.state.schemas.promotions}
            validator={validator}
            formData={this.state.activePromotions}
            uiSchema={this.uiSchemas(this.props).promotions}
            onSubmit={this.onSubmit}
            onChange={this.handlePromotionsChange}
            templates={{ ArrayFieldTemplate: this.arrayFieldTemplate }}
          />
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={2}>
          <BedbankRoomManagement
            property={this.state.property}
            promotions={this.state.activePromotions}
            handleChange={this.handleRoomsChange}
            onSubmit={this.onSubmit}
          />
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={3}>
          <BedbankRoomMapping
            property={this.state.property}
            handleChange={this.handleRoomMappingChange}
            onForceDecoupleRoomsClick={this.props.onForceDecoupleRoomsClick}
          />
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={4}>
          {this.state.property.rooms.length && (
            <Box mb={1}>
              <Button variant="text" size="small" onClick={this.toggleAllRoomImages}>
                {isAllRoomImagesExpanded ? 'Collapse All Rooms' : 'Expand All Rooms'}
              </Button>
            </Box>
          )}
          <Box>
            {this.state.property.rooms.map((room, index) => (
              <Accordion
                variant="outlined"
                expanded={this.state.roomsExpansionStatuses[index]}
                key={room.id}
                onChange={() => {
                  this.toggleRoomImages(index);
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls={room.id} id={room.id}>
                  {room.name}
                </AccordionSummary>

                <AccordionDetails>
                  <ImagesForm
                    images={room.images}
                    onImagesChange={(images) => this.handleRoomImagesChange(room.id, images)}
                    onCreateImage={(imageServiceId) => this.createRoomImage(room.id, imageServiceId)}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={5}>
          <Form
            schema={this.state.schemas.sellPrices}
            validator={validator}
            formData={{
              sellIsEnabled: this.state.property.sellIsEnabled,
              sell: this.state.property.sell,
              rooms: this.state.property.rooms.filter((r) => r.promotionId),
            }}
            uiSchema={this.uiSchemas(this.props).sellPrices}
            onSubmit={this.onSubmit}
            onChange={this.handleSellPricesChange}
          >
            <></>
          </Form>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={6}>
          <ImagesForm
            images={this.state.property.images}
            onImagesChange={this.handleImagesChange}
            onCreateImage={this.createPropertyImage}
          />
          <Form
            schema={this.state.schemas.videos}
            validator={validator}
            formData={this.state.property.videos}
            uiSchema={getVideosSchema()}
            onSubmit={this.onSubmit}
            onChange={this.handleVideosChange}
          ></Form>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={7}>
          <Form
            schema={this.state.schemas.campaigns}
            validator={validator}
            formData={this.state.property.campaigns}
            uiSchema={this.uiSchemas(this.props).campaigns}
            onSubmit={this.onSubmit}
            onChange={this.handleTagsChange}
          ></Form>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={8}>
          <ArchivedPromotionPane
            promotions={this.state.archivedPromotions}
            canUpdateArchivedPromotions={this.state.activePromotions.length === 0}
            handleArchivedPromotionUpdate={this.handleArchivedPromotionUpdate}
          />
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={9}>
          <Paper className="branded-panel">
            <SearchOrderingPane vertical="hotel" offerId={this.state.property.id} />
          </Paper>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={10}>
          <RateInfo propertyId={this.props.property.id} />
        </TabPanel>

        {this.state.alertState && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {this.state.alertState.message}
          </Alert>
        )}

        {showSaveButton && (
          <div className="button-container">
            <Button variant="contained" color={this.state.saveState} onClick={this.onSubmit}>
              {buttonMessages[this.state.saveState]}
            </Button>
          </div>
        )}
      </Box>
    );
  }
}

export default withSnackbar(BedbankPropertiesEditForm);
