import React, { useState } from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Order } from '@luxuryescapes/contract-svc-order';
import { CancellationPolicy, formatDate } from '@luxuryescapes/lib-refunds';

import { formatDateSlashesWithClock, getBrowserTimeZoneAbbreviation } from '~/services/TimeService';

import { sortBy } from '~/utils/arrayUtils';

interface Props {
  room: Order.BedbankItemRoom;
  cancellationPolicy: CancellationPolicy;
  propertyTimezone: string;
  refundIssuer: 'Vendor' | 'LE';
  nights: number;
}

function cancellationText(policy: CancellationPolicy['policies'][number], refundIssuer: 'Vendor' | 'LE') {
  let quantity = `${policy.nights} night`;
  if (policy.amount) {
    quantity = `$${policy.amount} ${policy.currency}`;
  } else if (policy.percent) {
    quantity = `${policy.percent}%`;
  }
  return `${refundIssuer} retains ${quantity} of ${
    refundIssuer === 'Vendor' ? 'cost' : 'price'
  } if cancel after ${formatDateSlashesWithClock(policy.start)} (${getBrowserTimeZoneAbbreviation()})`; // display browser local time
}

function CancellationPolicies({ room, cancellationPolicy, propertyTimezone, refundIssuer, nights }: Props) {
  const [showPartialRefundDetails, setShowPartialRefundDetails] = useState(false);

  const refundReceiver = refundIssuer === 'LE' ? 'Customer' : 'LE';

  if (room.refundable) {
    const sortedPoliciesDesc = sortBy(cancellationPolicy.policies, (cp) => cp.start, 'desc');
    const now = new Date();
    // if last penalty end is in the past, it is no longer refundable at all
    let notRefundable = sortedPoliciesDesc.length ? now > sortedPoliciesDesc[0].end : true;
    const applicablePolicy = sortedPoliciesDesc.find((cp) => cp.start <= now);
    if (applicablePolicy && !notRefundable) {
      if (applicablePolicy.amount && applicablePolicy.amount >= room.cost_price) {
        notRefundable = true;
      } else if (applicablePolicy.nights && applicablePolicy.nights === nights) {
        notRefundable = true;
      } else if (applicablePolicy.percent && applicablePolicy.percent === 100) {
        notRefundable = true;
      }
    }

    // if all penalty start are in the future, then it is still fully refundable
    const fullyRefundable = sortedPoliciesDesc.length && !applicablePolicy;

    const sortedPolicies = sortBy(cancellationPolicy.policies, (cp) => cp.start, 'asc');
    const applicablePolicyIndex = sortedPolicies.indexOf(applicablePolicy);

    return (
      <Stack direction="row" spacing={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CurrencyExchangeIcon color="success" />
            <Box sx={{ display: 'inline' }}>
              <Typography sx={{ display: 'inline', color: fullyRefundable ? 'green' : 'red' }} component="span">
                {fullyRefundable ? '' : '(Expired) '}
                &nbsp;Free cancellation&nbsp;
              </Typography>
              {!!cancellationPolicy.policies.length && (
                <Typography sx={{ display: 'inline', color: grey[800] }} component="span">
                  until {formatDate.formattedDateByTimezone(cancellationPolicy.policies[0]?.start, propertyTimezone)}{' '}
                  (local time of property)
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0 16px' }}>
            {sortedPolicies.length && (
              <ul>
                {fullyRefundable && (
                  <li key="fully-refundable">
                    <Typography sx={{ color: 'green' }} component="div">
                      {refundReceiver} should get full refund from {refundIssuer} if cancel now
                    </Typography>
                  </li>
                )}
                {notRefundable && (
                  <li key="not-refundable">
                    <Typography sx={{ color: 'red' }} component="div">
                      {refundReceiver} can no longer get any refund from {refundIssuer}
                    </Typography>
                  </li>
                )}
                {sortedPolicies.map((policy, i) => {
                  const text = cancellationText(policy, refundIssuer);
                  // highlight current penalty
                  // strike out expired penalty
                  return (
                    <li key={i}>
                      {applicablePolicyIndex >= i ? (
                        applicablePolicyIndex === i ? (
                          <Typography sx={{ color: 'red' }} component="div">
                            {text}
                          </Typography>
                        ) : (
                          <s>{text}</s>
                        )
                      ) : (
                        text
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </AccordionDetails>
        </Accordion>
      </Stack>
    );
  }
  if (room.partially_refundable) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <CurrencyExchangeIcon color="success" />
          <Typography sx={{ color: 'green' }} component="div">
            Partially Refundable
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Button
            sx={{ color: grey[800], fontSize: 'sm' }}
            onClick={() => setShowPartialRefundDetails((state) => !state)}
          >
            {showPartialRefundDetails ? 'Hide Details' : 'Show Details'}
            {showPartialRefundDetails ? (
              <ExpandLessIcon sx={{ color: grey[800] }} />
            ) : (
              <ExpandMoreIcon sx={{ color: grey[800] }} />
            )}
          </Button>
          {showPartialRefundDetails && !!cancellationPolicy.description.length && (
            <span style={{ color: grey[900] }}>
              <ul>
                {cancellationPolicy.description.map((description, i) => (
                  <li key={i}>{description}</li>
                ))}
              </ul>
            </span>
          )}
        </Stack>
      </>
    );
  }

  if (!room.partially_refundable && !room.refundable) {
    return (
      <Stack direction="row" spacing={1}>
        <CurrencyExchangeIcon color="error" />
        <Typography sx={{ color: 'red' }} component="div">
          Non-refundable
        </Typography>
      </Stack>
    );
  }

  return null;
}

export default CancellationPolicies;
