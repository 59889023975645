import React from 'react';

import { Button, Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import dateFormatter from '~/utils/dateFormatter';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

/** Remove /api prefix for better UX */
const removePathPrefix = (path: string): string => {
  const v1Prefix = '/api/le-notify';
  const v2Prefix = '/api/v2/le-notify';
  if (path.startsWith(v1Prefix)) {
    return path.slice(v1Prefix.length);
  }
  if (path.startsWith(v2Prefix)) {
    const prefixToRemove = '/api';
    return path.slice(prefixToRemove.length);
  }
  return path;
};

const getColumns = ({
  handleViewDetails,
  handleResendEmail,
}: {
  handleViewDetails: ({ id }: { id: string }) => Promise<void>;
  handleResendEmail: ({ id }: { id: string }) => Promise<void>;
}): Array<GridColDef> => [
  {
    field: 'id',
    headerName: 'ID',
    ...sharedOpts,
  },
  {
    field: 'http_path',
    headerName: 'Path',
    renderCell: (params) => removePathPrefix(params.row.http_path),
    ...sharedOpts,
  },
  {
    field: 'attempt_count',
    headerName: 'Attempt Count',
    ...sharedOpts,
  },
  {
    field: 'first_occurred_at',
    headerName: 'First Occurred At',
    renderCell: (params) => dateFormatter(params.row.first_occurred_at),
    ...sharedOpts,
  },
  {
    field: 'last_occurred_at',
    headerName: 'Last Occurred At',
    renderCell: (params) => dateFormatter(params.row.last_occurred_at),
    ...sharedOpts,
  },
  {
    field: 'successful_sent_at',
    headerName: 'Successful Sent At',
    renderCell: (params) => dateFormatter(params.row.successful_sent_at),
    ...sharedOpts,
  },
  {
    field: 'payload',
    headerName: 'Payload',
    renderCell: (params) => JSON.stringify(params.row.payload),
    ...sharedOpts,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    renderCell: (params) => JSON.stringify(params.row.reason),
    ...sharedOpts,
  },
  {
    field: 'slack_msg_link',
    headerName: 'Slack Link',
    renderCell: (params) => {
      return params.row.slack_msg_link ? (
        <Link href={params.row.slack_msg_link} target="_blank">
          Slack
        </Link>
      ) : (
        <span>N/A</span>
      );
    },
    ...sharedOpts,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0.5}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              handleViewDetails({ id: params.row.id });
            }}
          >
            Details
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={params.row.successful_sent_at}
            onClick={() => {
              handleResendEmail({ id: params.row.id });
            }}
          >
            Resend
          </Button>
        </Stack>
      );
    },
    ...sharedOpts,
  },
];

export default getColumns;
