import qs from 'qs';

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer';
import { components, operations, paths } from '@luxuryescapes/contract-public-offer/server';

import { json_headers, request } from './common';

const convertAccommodationPackages = (
  publicOffer: PublicOfferV2.LeHotelOffer,
): Array<Omit<App.AccommodationPackage, 'addons'>> => {
  return publicOffer.options.map((option) => {
    const ratePlan = publicOffer.ratePlans[option.fkRatePlanId];
    const publicOfferRoomRate = publicOffer.roomRates[option.fkRoomRateId];
    const roomType = publicOffer.roomTypes[option.fkRoomTypeId];

    const roomRate = {
      id: publicOfferRoomRate.id,
      capacities: publicOfferRoomRate.capacities,
      rate_plan: { id: option.fkRatePlanId, name: ratePlan.id },
    };

    return {
      le_package_id: option.fkPackageId, // todo: fix below to use sfid once offer changes published
      id_salesforce_external: option.fkPackageId,
      fk_property_id: publicOffer.property.id,
      fk_room_type_id: option.fkRoomTypeId,
      fk_room_rate_id: option.fkRoomRateId,
      name: option.name,
      price: option.price,
      number_of_nights: option.duration,
      offer_id_salesforce_external: publicOffer.id,
      package_options: [],
      prices: [
        {
          currency_code: option.currencyCode,
          price: option.price,
        },
      ],
      bundle_prices: [],
      regions: [],
      room_rate: roomRate,
      room_rates: [roomRate],
      room_type: {
        id: option.fkRoomTypeId,
        name: roomType.name,
        room_rates: [roomRate],
      },
      status: 'content-approved',
      unique_key: option.id,
    };
  });
};

const convertTourPackages = (publicOffer: PublicOfferV2.LeTourOffer): Array<Omit<App.TourPackage, 'addons'>> => {
  return publicOffer.options.map((option) => {
    const pkg = publicOffer.packages[option.fkPackageId];
    return {
      id_salesforce_external: option.fkPackageId,
      name: pkg.name,
      price: option.price,
      number_of_days: option.duration,
      prices: [
        {
          currency_code: option.currencyCode,
          price: option.price,
        },
      ],
      bundle_prices: [],
      offer_id_salesforce_external: publicOffer.id,
      unique_key: option.id,
      fk_tour_id: publicOffer.tour.id,
      fk_tour_option_id: option.id,
      status: 'content-approved',
      regions: [],
    };
  });
};

const convertTourOffer = (publicOffer: PublicOfferV2.LeTourOffer): App.TourOffer => {
  return {
    brand_schedules: [],
    id_salesforce_external: publicOffer.id,
    images: publicOffer.images as unknown as Array<App.OfferImage>,
    location: publicOffer.location,
    locations: publicOffer.tags.location,
    name: publicOffer.name,
    packages: convertTourPackages(publicOffer),
    slug: publicOffer.slug,
    type: publicOffer.type,
    book_by_date: '',
    travel_to_date: '',
    enable_customer_portal_date_change: false,
    days_before_check_in_changes_disallowed: 21,
    number_of_date_changes: '0',
    package_upgrades_allowed: false,
    experiences_in_flow: false,
    experiences_curated: false,
    subjectLine: publicOffer.subjectLine,
    onlinePurchaseSchedule: publicOffer.schedules.onlinePurchase,
    listSchedule: publicOffer?.schedules?.listVisibility,
  };
};

const convertOffer = (publicOffer: PublicOfferV2.LeOffer): App.Offer => {
  if (publicOffer.type === 'tour') {
    return convertTourOffer(publicOffer);
  }

  return {
    brand_schedules: [],
    id_salesforce_external: publicOffer.id,
    images: publicOffer.images as unknown as Array<App.OfferImage>,
    location: publicOffer.location,
    locations: publicOffer.tags.location,
    name: publicOffer.name,
    packages: convertAccommodationPackages(publicOffer),
    slug: publicOffer.slug,
    type: publicOffer.type,
    book_by_date: '',
    travel_to_date: '',
    enable_customer_portal_date_change: false,
    days_before_check_in_changes_disallowed: 21,
    number_of_date_changes: '0',
    package_upgrades_allowed: false,
    experiences_in_flow: false,
    experiences_curated: false,
    property: publicOffer.property,
    subjectLine: publicOffer.subjectLine,
    onlinePurchaseSchedule: publicOffer.schedules.onlinePurchase,
    listSchedule: publicOffer?.schedules?.listVisibility,
    luxPlusSchedule: publicOffer?.schedules?.luxPlus,
  };
};

export default class PublicOffersService {
  static getPublicOfferById(
    id_salesforce_external: string,
    regionCode: string,
    brand: App.Tenant['brand'],
  ): Promise<{
    result:
      | components['schemas']['leHotelOffer']
      | components['schemas']['v1TourOffer']
      | components['schemas']['v2TourOffer']
      | components['schemas']['bedbankOffer'];
  }> {
    return request(
      `${window.configs.API_HOST}/api/v2/public-offers/${id_salesforce_external}?region=${regionCode}&brand=${brand}`,
      { method: 'GET', credentials: 'omit' },
    );
  }

  static getTourV2OfferById(
    id: string,
    regionCode: string,
    brand: App.Tenant['brand'],
  ): Promise<{ result: PublicOfferV2.TourV2Offer }> {
    return request(`${window.configs.API_HOST}/api/v2/public-offers/${id}?region=${regionCode}&brand=${brand}`, {
      method: 'GET',
      credentials: 'omit',
    });
  }
  static getBedbankOfferById(
    id: string,
    regionCode: string,
    brand: App.Tenant['brand'],
  ): Promise<{ result: PublicOfferV2.BedbankOffer }> {
    return request(`${window.configs.API_HOST}/api/v2/public-offers/${id}?region=${regionCode}&brand=${brand}`, {
      method: 'GET',
      credentials: 'omit',
    });
  }

  static getMultiplePublicOffersById(
    queryParams: operations['offerIndex']['parameters']['query'],
  ): Promise<{ result: Array<PublicOfferV2.LeOffer> }> {
    return request(`${window.configs.API_HOST}/api/v2/public-offers?${qs.stringify(queryParams)}`, {
      method: 'GET',
      credentials: 'omit',
    });
  }

  static async getOfferById(
    id_salesforce_external: string,
    regionCode: string,
    brand: App.Tenant['brand'],
  ): Promise<App.Offer> {
    const publicOffer = await PublicOffersService.getPublicOfferById(id_salesforce_external, regionCode, brand);
    if (publicOffer.result.type === 'bedbank_hotel' || publicOffer.result.type === 'tour_v2') {
      throw new Error('Not an LE offer');
    }
    return convertOffer(publicOffer.result);
  }

  static async getMultipleOffersById(
    ids: Array<string>,
    regionCode: operations['offerIndex']['parameters']['query']['region'],
    brand: operations['offerIndex']['parameters']['query']['brand'],
  ): Promise<Array<App.Offer>> {
    const publicOffers = await PublicOffersService.getMultiplePublicOffersById({
      offerIds: ids.toString(),
      region: regionCode,
      brand,
    });

    return publicOffers.result.map((publicOffer) => convertOffer(publicOffer));
  }

  static async getAddons(pkg: App.Package): Promise<Array<App.Addon>> {
    const response = await request(
      `${window.configs.API_HOST}/api/public-offers/${pkg.offer_id_salesforce_external}/packages/${
        pkg.id_salesforce_external ?? pkg.le_package_id
      }/addons`,
      { method: 'GET', credentials: 'omit' },
    );
    return response.result;
  }

  static async resyncData(offerId: string, propertyId: string) {
    return request(`${window.configs.API_HOST}/api/v2/public-offers/${offerId}/manual-resync`, {
      method: 'PATCH',
      body: JSON.stringify({ propertyId }),
      headers: json_headers,
    });
  }

  static async createTranslationRequest(
    payload: paths['/api/v2/public-offers/translations']['post']['parameters']['body']['payload'],
    params: {
      brand: string;
      regionCode: string;
    },
  ): Promise<
    | paths['/api/v2/public-offers/translations']['post']['responses']['202']['content']['application/json']
    | paths['/api/v2/public-offers/translations']['post']['responses']['208']['content']['application/json']
  > {
    const url = new URL('/api/v2/public-offers/translations', window.configs.API_HOST);
    url.searchParams.set('brand', params.brand);
    url.searchParams.set('region', params.regionCode);

    return request(url.toString(), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: json_headers,
    });
  }
}
