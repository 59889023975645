import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Button, Drawer, Stack } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { CruiseOffersProvider } from '~/components/Cruises/context/useCruiseOffers';

import rateCodeService from '~/services/cruises/RateCodeService';

import CreateRateCodeForm from './components/CreateRateCodeForm';
import RateCodeList from './components/RateCodeList';
import RateCodeManagementSearch from './components/RateCodeManagementSearch';
import RatePromosManagement from './components/RatePromosManagement';
import { INITIAL_FEEDBACK_STATE } from './constants';
import { CreateRateFormValues, DataRow, Feedback, SearchValues } from './types';

const RateCodeManagementPage: React.FC = (): JSX.Element => {
  const [total, setTotal] = useState<number>(0);
  const [pagination, setPagination] = useState({ perPage: 10, page: 0 });
  const [ratePromoDrawer, setRatePromosDrawer] = useState<boolean>(false);
  const [createRateDrawer, setCreateRateDrawer] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback>(INITIAL_FEEDBACK_STATE);
  const [ratePromoManagementValues, setRatePromosManagementValues] = useState<DataRow>(null);
  const [rateCodeList, setRateCodeList] = useState<Array<CruisesContract.RateCodeListResponse>>([]);

  const getList = useCallback(
    async (params?: CruisesContract.RateCodeListQuery) => {
      setFeedback({ loading: true, status: null, message: null });

      const res = await rateCodeService.getRateCodeList({
        rateCodes: params?.rateCodes,
        vendorCode: params?.vendorCode,
        promotionName: params?.promotionName,
        take: pagination.perPage,
        skip: params?.skip,
      });

      if (res?.status === 200) {
        setRateCodeList(res.result);
        setTotal(res.total);
      } else {
        setRateCodeList([]);
      }

      setFeedback({ loading: false });
    },
    [pagination.perPage],
  );

  const handleSearch = useCallback(
    async (searchValues: SearchValues) => {
      await getList({
        rateCodes: searchValues.rateCode,
        vendorCode: searchValues.vendorCode,
        promotionName: searchValues.promotionName,
      });
    },
    [getList],
  );

  const handleCreateRateSubmit = useCallback(async (formValues: CreateRateFormValues) => {
    if (!formValues.rateCode || !formValues.vendorId || !formValues.departureIds?.length) {
      setFeedback({
        loading: false,
        status: 'error',
        message: 'Please fill all the fields',
      });
    } else {
      setFeedback({ loading: true, status: null, message: null });

      const res = await rateCodeService.createRateCodeByDepartureId({
        rateCode: formValues.rateCode,
        vendorId: formValues.vendorId,
        departureIds: formValues.departureIds,
        isFlash: formValues.isFlash,
      });

      if (res?.status === 201) {
        setFeedback({
          loading: false,
          status: 'success',
          message: `Rate code created successfully`,
        });
      } else {
        setFeedback({
          loading: false,
          status: 'error',
          message: 'Something went wrong. Please contact cruises team',
        });
      }
    }
  }, []);

  const handleRowClick = useCallback((rowData: DataRow) => {
    setRatePromosDrawer(true);
    setRatePromosManagementValues(rowData);
  }, []);

  const handleRatePromosDrawer = useCallback(() => {
    setRatePromosDrawer((prevValue) => !prevValue);
  }, []);

  const handleCreateRateDrawer = useCallback(() => {
    setCreateRateDrawer((prevValue) => !prevValue);
  }, []);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CruiseOffersProvider>
      <Box>
        <Helmet>
          <title>Rate Code Management</title>
        </Helmet>

        <Box>
          <RateCodeManagementSearch onSearch={handleSearch} loading={feedback.loading} />
        </Box>

        <Stack mt={2} width="100%" direction="row" justifyContent="flex-end">
          <Button variant="text" onClick={handleCreateRateDrawer}>
            Create New Rate Code
          </Button>
        </Stack>

        <Box mt={1}>
          <RateCodeList
            page={pagination.page}
            rateCodes={rateCodeList}
            loading={feedback.loading}
            onRowClick={handleRowClick}
            perPage={pagination.perPage}
            total={total}
            onPaginate={(page) => {
              setPagination({
                ...pagination,
                page,
              });
              getList({
                skip: page * pagination.perPage,
              });
            }}
          />
        </Box>
      </Box>

      <Drawer anchor="right" open={createRateDrawer} onClose={handleCreateRateDrawer}>
        <CreateRateCodeForm feedback={feedback} onClose={handleCreateRateDrawer} onSubmit={handleCreateRateSubmit} />
      </Drawer>

      <Drawer anchor="right" open={ratePromoDrawer} onClose={handleRatePromosDrawer}>
        <RatePromosManagement onClose={handleRatePromosDrawer} managementValues={ratePromoManagementValues} />
      </Drawer>
    </CruiseOffersProvider>
  );
};

export default RateCodeManagementPage;
