import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import * as libRegions from '@luxuryescapes/lib-regions';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import SearchService from '~/services/SearchService';
import { addDaysRaw, formatDateISO } from '~/services/TimeService';

const regions = libRegions.getRegions().map((r) => r.code);
const regionOptions = ['All Regions', ...regions];

const tomorrow = addDaysRaw(1).tz('Australia/Sydney').startOf('day');

interface PositionOverrideDialogProps {
  offerId: string;
  region: string;
  variant: string;
  curPosition?: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function PositionOverrideDialog({
  offerId,
  region,
  variant,
  curPosition,
  open,
  onClose,
  onSuccess,
}: PositionOverrideDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRegions, setSelectedRegions] = useState<Array<string>>([region]);
  const [showAllRegions, setShowAllRegions] = useState(false);
  const [position, setPosition] = useState<number>(curPosition || 1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setPosition(curPosition || 1);
  }, [curPosition]);

  const [expireDate, setExpireDate] = useState<string>(tomorrow.toISOString());

  const [touched, setTouched] = useState({
    regions: false,
    position: false,
    expireDate: false,
  });

  const [submitAttempted, setSubmitAttempted] = useState(false);

  const handleRegionChange = (event: SelectChangeEvent<Array<string>>) => {
    const value = event.target.value;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    const clickedValue =
      selectedRegions.find((v) => !selectedValues.includes(v)) ||
      selectedValues.find((v) => !selectedRegions.includes(v));

    if (clickedValue === 'All Regions' || !clickedValue) {
      if (!showAllRegions) {
        setSelectedRegions(regions);
        setShowAllRegions(true);
      } else {
        setSelectedRegions([]);
        setShowAllRegions(false);
      }
    } else {
      const isSelected = selectedRegions.includes(clickedValue);
      let newSelection;

      if (isSelected) {
        newSelection = selectedRegions.filter((r) => r !== clickedValue);
        setShowAllRegions(false);
      } else {
        newSelection = [...selectedRegions, clickedValue];
        if (newSelection.length === regions.length) {
          setShowAllRegions(true);
        }
      }
      setSelectedRegions(newSelection);
    }
  };

  const renderSelectedRegions = (selected: Array<string>) => {
    if (showAllRegions) {
      return 'All Regions';
    }
    return selected.join(', ');
  };

  const getDisplayValue = () => {
    return showAllRegions ? ['All Regions', ...selectedRegions] : selectedRegions;
  };

  const handleSubmit = async () => {
    setSubmitAttempted(true);

    if (!isFormValid()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const positionOverrides = selectedRegions.map((region) => ({
        region,
        variant,
        position,
        expireDate: formatDateISO(expireDate),
      }));

      await SearchService.setHotelSearchPositions(offerId, positionOverrides);
      onClose();
      onSuccess();
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    return selectedRegions.length > 0 && position > 0 && position <= 100 && expireDate !== '';
  };

  const shouldShowError = (fieldName: keyof typeof touched) => {
    return touched[fieldName] || submitAttempted;
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle id="manual-positioning-dialog-title">Manual Positioning</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="info">
            Manual positioning may take a few minutes to take effect on the website. Global position must be between 1
            and 100.
          </Alert>
          <FormControl fullWidth required>
            <InputLabel id="regions-label">Regions</InputLabel>
            <Select
              labelId="regions-label"
              multiple
              value={getDisplayValue()}
              onChange={(e) => {
                handleRegionChange(e);
                setTouched((prev) => ({ ...prev, regions: true }));
              }}
              input={<OutlinedInput label="Regions" />}
              renderValue={renderSelectedRegions}
              error={shouldShowError('regions') && selectedRegions.length === 0}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 'auto',
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                autoFocus: false,
              }}
            >
              {regionOptions.map((region) => (
                <MenuItem key={region} value={region}>
                  <Checkbox checked={region === 'All Regions' ? showAllRegions : selectedRegions.includes(region)} />
                  <ListItemText primary={region} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            required
            label="Global Position (1~100)"
            type="number"
            value={position}
            onChange={(e) => {
              setPosition(Number(e.target.value));
              setTouched((prev) => ({ ...prev, position: true }));
            }}
            InputProps={{ inputProps: { min: 1, max: 100 } }}
            error={shouldShowError('position') && (position <= 0 || position > 100)}
          />

          <DateTimeWidget
            value={expireDate}
            label="Expire Date"
            showDateOnly
            format="YYYY-MM-DD"
            minDate={tomorrow}
            onChange={setExpireDate}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PositionOverrideDialog;
