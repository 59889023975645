import { CommissionRule } from '~/types/services/agentHub';

export type CommissionsConditionTypes =
  | 'location'
  | 'vendor'
  | 'agency'
  | 'agent'
  | 'productType'
  | 'offer'
  | 'affiliation'
  | 'margin'
  | 'tier';

export type CommissionOption = Array<{
  value: string | number;
  label: string;
}>;

export type RuleConditionType = [keyof RuleConditions, CommissionOption];

export type RuleConditions = {
  [key in CommissionsConditionTypes]: CommissionOption;
};

export interface RuleCondition {
  type: keyof RuleConditions;
  value: CommissionOption;
}

export interface CommissionFormData {
  description: string;
  startDate: string;
  endDate: string;
  regions: Array<string>;
  discountType: string;
  discountAmount: number;
}

export const INITIAL_COMMISSION_CONDITIONS: RuleConditions = {
  agency: [],
  agent: [],
  offer: [],
  vendor: [],
  location: [],
  productType: [],
  affiliation: [],
  margin: [],
  tier: [],
};

export const ruleConditionsToTextMap = new Map<keyof RuleConditions, string>([
  ['agency', 'Agencies'],
  ['agent', 'Agents'],
  ['offer', 'Offers'],
  ['vendor', 'Vendors'],
  ['location', 'Locations'],
  ['productType', 'Product type'],
  ['affiliation', 'Affiliation'],
  ['margin', 'Margin'],
  ['tier', 'Tier'],
]);

export const COMMISSION_RULE_CONDITION_TYPES: Array<CommissionsConditionTypes> = [
  'location',
  'vendor',
  'agency',
  'agent',
  'productType',
  'offer',
  'affiliation',
  'margin',
  'tier',
];

export const REGION_SORT_ORDER = [
  'Australia',
  'New Zealand',
  'United States',
  'United Kingdom',
  'Singapore',
  'India',
  'Germany',
  'Italy',
  'Canada',
  'China',
  'France',
  'Hong Kong',
  'Indonesia',
  'Ireland',
  'Israel',
  'Japan',
  'Korea',
  'Macau',
  'Malaysia',
  'Netherlands',
  'Philippines',
  'Qatar',
  'Russia',
  'Saudi Arabia',
  'South Africa',
  'Spain',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'Vietnam',
];

export const PRODUCT_TYPES_OPTIONS = [
  'addon',
  'always_on',
  'bedbank_hotel',
  'bundle_and_save',
  'car_hire',
  'connection_tour',
  'corporate',
  'cruise',
  'cruise-exclusive',
  'cruise-flash',
  'cruise-tactical',
  'direct_tour',
  'experience',
  'flash',
  'hotel',
  'last_minute_hotel',
  'limited_time_special',
  'partner_tour',
  'rental',
  'tactical_ao_hotel',
  'tour',
  'transfer',
  'ultra_lux_tour',
  'ultralux_hotel',
];

export const TIER_OPTIONS: CommissionRule[0]['tier'] = ['tier1', 'tier2', 'tier3'];

export const AVAILABLE_REGIONS = [
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'GB', label: 'United Kingdom' },
];
