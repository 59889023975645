import React from 'react';

import { Box, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import { CancellationPolicy } from '@luxuryescapes/lib-refunds';

import SimpleCancellationPolicies from '~/components/Purchases/OrderDetail/BedbankList/SimpleCancellationPolicies';

interface Props {
  room: Order.BedbankItemRoom;
  customerCancellationPolicy: CancellationPolicy;
  supplierCancellationPolicy: CancellationPolicy;
  propertyTimezone: string;
  item: Order.BedbankItem;
}

export default function SimpleBedbankCancellationPolicies({
  room,
  customerCancellationPolicy,
  item,
  propertyTimezone,
  supplierCancellationPolicy,
}: Props) {
  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Customer Refund Policy
        </Typography>
        <SimpleCancellationPolicies
          room={room}
          cancellationPolicy={customerCancellationPolicy}
          propertyTimezone={propertyTimezone}
          refundIssuer="LE"
          nights={item.nights}
        />
      </Box>

      {!!supplierCancellationPolicy && (
        <Box>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Supplier Refund Policy
          </Typography>
          <SimpleCancellationPolicies
            room={room}
            cancellationPolicy={supplierCancellationPolicy}
            propertyTimezone={propertyTimezone}
            refundIssuer="Vendor"
            nights={item.nights}
          />
        </Box>
      )}
    </>
  );
}
