import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CruiseInclusionFilter } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService from '~/services/cruises/InclusionsService';

import InclusionsFilterCreate from './InclusionsFilterCreate';

type Props = {
  vendorId: string;
};

function InclusionsFilterList({ vendorId }: Props) {
  const [inclusionFilters, setInclusionFilters] = useState<Array<CruiseInclusionFilter>>([]);
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteButton, setDeleteButton] = useState<string | null>(null);
  const { inclusionId, action } = useParams<{ inclusionId: string; action?: string }>();

  const handleDeleteBanner = useCallback(
    async (id: string) => {
      try {
        await inclusionsService.deleteFilter(inclusionId, id);
        enqueueSnackbar('Inclusion Filter deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting Inclusion Filter', { variant: 'error' });
      }
    },
    [enqueueSnackbar, inclusionId],
  );

  const fetchInclusionFilters = useCallback(async () => {
    setFetching(true);

    const response = await inclusionsService.getFilters(inclusionId);
    setInclusionFilters(response.result);

    setFetching(false);
  }, [inclusionId, setInclusionFilters, setFetching]);

  useEffect(() => {
    fetchInclusionFilters();
  }, [fetchInclusionFilters]);

  const actionColumn = useCallback(
    (cell, row) => (
      <Box>
        {deleteButton !== row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(row.id);
              }}
            >
              Delete
            </Button>
          </Box>
        )}

        {deleteButton === row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleDeleteBanner(row.id);
                fetchInclusionFilters();
              }}
            >
              Confirm
            </Button>

            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    ),
    [deleteButton, handleDeleteBanner, fetchInclusionFilters],
  );

  const columns: Array<GridColDef> = [
    {
      field: 'filterType',
      headerName: 'Filter Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    {
      field: 'filterId',
      headerName: 'Filter ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
      renderCell: (params) => {
        if (params.row.filterType === 'RATE') {
          return (
            <Link to={`/cruises/rate-code-management/${params.value}/edit`} target="_blank" rel="noreferrer">
              {params.value}
            </Link>
          );
        }

        if (params.row.filterType === 'DEPARTURE') {
          return (
            <Link to={`/cruises/sailings/${params.value}`} target="_blank" rel="noreferrer">
              {params.value}
            </Link>
          );
        }
      },
    },
    {
      field: 'shouldExclude',
      headerName: 'Exclude',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
      renderCell: (params) => (params.value ? 'Yes' : 'No'),
    },
    {
      field: 'applyToAllDeparturesInOffer',
      headerName: 'Apply to all departures in offer',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
      renderCell: (params) => (params.value ? 'Yes' : 'No'),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      sortable: false,
      renderCell: (params) => actionColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <Box>
        <DataGrid
          loading={fetching}
          rows={inclusionFilters || []}
          columns={columns}
          getRowId={(row: CruiseInclusionFilter) => row.id}
          autoHeight
        />
      </Box>

      <Stack
        mt={2}
        width="100%"
        direction="row"
        justifyContent="flex-end"
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={1}
      >
        <Button
          component={Link}
          to={`/cruises/inclusions/edit/${inclusionId}/filters/create`}
          variant="outlined"
          size="small"
          fullWidth
        >
          Add Inclusion Filter
        </Button>
      </Stack>

      {action === 'create' && <InclusionsFilterCreate vendorId={vendorId} onSave={fetchInclusionFilters} />}
    </Box>
  );
}

export default InclusionsFilterList;
