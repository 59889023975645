import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import InputDepartureSelect from '~/components/Cruises/components/Selects/InputDepartureSelect';
import InputRateCodeSelect from '~/components/Cruises/components/Selects/InputRateCodeSelect';
import { CruiseInclusionFilter } from '~/components/Cruises/pages/Inclusions/types';

import cruiseLineService from '~/services/cruises/CruiseLineService';
import inclusionsService from '~/services/cruises/InclusionsService';

const TYPES: Record<string, CruiseInclusionFilter['filterType']> = {
  RATE: 'RATE',
  DEPARTURE: 'DEPARTURE',
};

const INITIAL_FORM_STATE: Partial<CruiseInclusionFilter> = {
  filterId: undefined,
  filterType: TYPES.RATE,
  shouldExclude: false,
  applyToAllDeparturesInOffer: false,
};

type Props = {
  fkInclusionId: string;
  vendorId: string;
  inclusionFilter?: CruiseInclusionFilter;
  onSave: () => void;
};

function InclusionFilterForm({ fkInclusionId, vendorId, inclusionFilter, onSave }: Props) {
  const history = useHistory();
  const [formValues, setFormValues] = useState<Partial<CruiseInclusionFilter>>(INITIAL_FORM_STATE);
  const [selectedRateIds, setSelectedRateIds] = useState<Array<string>>([]);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<Array<string>>([]);
  const [status, setStatus] = useState<string>();
  const [vendor, setVendor] = useState<API.CruiseLine | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target?.name) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value ?? undefined,
        });
      }
    },
    [formValues],
  );

  const loadCruiseLine = useCallback(async () => {
    const vendor = await cruiseLineService.getCruiseLineById(vendorId);
    setVendor(vendor);
  }, [vendorId]);

  const handleSubmit = useCallback(async () => {
    const isEditing = !!inclusionFilter;
    setStatus('loading');

    try {
      if (isEditing) {
        await inclusionsService.updateFilter(fkInclusionId, inclusionFilter.id, formValues);
      } else {
        const filtersIds = formValues.filterType === TYPES.RATE ? selectedRateIds : selectedDepartureIds;

        await Promise.all(
          filtersIds.map((filterId) => {
            return inclusionsService.createFilter(fkInclusionId, {
              ...formValues,
              filterId,
            });
          }),
        );
      }

      setStatus('success');
      enqueueSnackbar('Inclusion Filter saved with success', { variant: 'success' });
      onSave();
      history.push(`/cruises/inclusions/edit/${fkInclusionId}/filters`);
    } catch (error) {
      console.error(error);
      setStatus('error');
      enqueueSnackbar('Error saving Inclusion Filter', { variant: 'error' });
    }
  }, [
    formValues,
    history,
    enqueueSnackbar,
    fkInclusionId,
    inclusionFilter,
    onSave,
    selectedDepartureIds,
    selectedRateIds,
  ]);

  const handleCancel = useCallback(() => {
    history.push(`/cruises/inclusions/edit/${fkInclusionId}/filters`);
  }, [history, fkInclusionId]);

  const isSaveEnabled = useMemo(() => {
    return (
      status !== 'loading' &&
      (formValues.filterType === TYPES.RATE ? selectedRateIds.length > 0 : selectedDepartureIds.length > 0)
    );
  }, [formValues, selectedDepartureIds, selectedRateIds, status]);

  useEffect(() => {
    if (inclusionFilter) {
      setFormValues({
        filterId: inclusionFilter.filterId,
        filterType: inclusionFilter.filterType,
        shouldExclude: inclusionFilter.shouldExclude,
      });
    }
  }, [inclusionFilter]);

  useEffect(() => {
    if (!vendor) {
      loadCruiseLine();
    }
  }, [vendor, loadCruiseLine]);

  return (
    <Box>
      <Box>
        <FormControl>
          <RadioGroup
            defaultValue={TYPES.RATE}
            name="filterType"
            value={formValues.filterType}
            onChange={handleChangeField}
            row
          >
            <FormControlLabel value={TYPES.RATE} control={<Radio />} label="Rate" />
            <FormControlLabel value={TYPES.DEPARTURE} control={<Radio />} label="Departure" />
          </RadioGroup>
        </FormControl>
      </Box>

      {formValues.filterType === TYPES.RATE && (
        <Box mt={2}>
          <InputRateCodeSelect
            vendorCode={vendor?.code}
            selectedRateIds={selectedRateIds}
            isLoading={!vendor}
            onChange={setSelectedRateIds}
          />
        </Box>
      )}

      {formValues.filterType === TYPES.DEPARTURE && (
        <Box mt={2}>
          <InputDepartureSelect
            vendorId={vendorId}
            selectedDepartureIds={selectedDepartureIds}
            onChange={setSelectedDepartureIds}
          />
        </Box>
      )}

      <Box mt={2}>
        {formValues.filterType === TYPES.DEPARTURE && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Apply to all departures of this offer"
              checked={formValues.applyToAllDeparturesInOffer}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormValues({
                  ...formValues,
                  applyToAllDeparturesInOffer: event.target.checked,
                  shouldExclude: false,
                });
              }}
            />
          </FormGroup>
        )}

        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label={`Exclude ${formValues.filterType === TYPES.RATE ? 'rates' : 'departures'}`}
            checked={formValues.shouldExclude}
            disabled={formValues.applyToAllDeparturesInOffer}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({ ...formValues, shouldExclude: event.target.checked });
            }}
          />
        </FormGroup>
      </Box>

      <Box mt={3} gap={1} display="flex" justifyContent="end">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={!isSaveEnabled}>
          {status === 'loading' && <Spinner size={15} />}
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default InclusionFilterForm;
