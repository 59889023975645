import React from 'react';

import { Box, Chip } from '@mui/material';

import { getOrderDowngradeTypeLabel } from '../Purchases/utils/getOrderDowngradeTypeLabel';

type Props = {
  item: App.AnyItem;
  items?: Array<App.AnyItem>;
};

function OrderTag({ item, items }: Props) {
  let label, displayedId;

  const upgradeTo = items?.find((i) => i.fk_downgraded_from_id === item.id && i.status === 'completed');
  const downgradeFrom = items?.find((i) => i.id === item.fk_downgraded_from_id);

  if (upgradeTo) {
    displayedId = upgradeTo?.booking_number;
    label = getOrderDowngradeTypeLabel(item.downgrade_type, upgradeTo, null);
  } else if (downgradeFrom) {
    displayedId = downgradeFrom?.booking_number;
    label = getOrderDowngradeTypeLabel(item.downgrade_type, null, downgradeFrom);
  }

  if (label) {
    return (
      <Box>
        <Chip label={`${label} ${displayedId}`} color="info" />
      </Box>
    );
  }

  return null;
}

export default OrderTag;
