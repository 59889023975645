import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import {
  COMMENT_TYPES,
  CUSTOM_OFFER_COMMENT_TYPES,
  INTERNAL,
  VISIBLE_TO_PUBLIC,
  isAttachmentComment,
} from '~/consts/notes';
import { ADD_NOTE } from '~/consts/order';

import useQuery from '~/hooks/useQuery';

import { addOrderNote } from '~/services/OrdersService';

import { buttonMessages, buttonStates } from '../../Common/Forms/states/submitButton';

function mapCommentType(commentType: string) {
  if (VISIBLE_TO_PUBLIC.includes(commentType)) {
    return `[Visible to Customer] - ${commentType}`;
  }

  if (INTERNAL.includes(commentType)) {
    return `[Internal Use] - ${commentType}`;
  }

  return commentType;
}

function OrderAddNotePage() {
  const query = useQuery();

  const { id_orders: orderId } = useParams<{ id_orders: string }>();
  const userId = query.get('user_id');
  const offerId = query.get('offer_id');
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [invoice, setInvoice] = useState<any>(null);
  const [comment, setComment] = useState<string>(null);
  const [commentType, setCommentType] = useState<string>(null);
  const [saveState, setSaveState] = useState(buttonStates.default);
  const comments = offerId ? CUSTOM_OFFER_COMMENT_TYPES : COMMENT_TYPES;
  const showPdf = isAttachmentComment(commentType);

  const submitNote = async (event) => {
    event.preventDefault();

    try {
      setSaveState(buttonStates.saving);
      const formData = new FormData();
      formData.append('comment', comment);
      formData.append('comment_type', commentType);
      if (showPdf) {
        formData.append('invoice', invoice);
      }
      await addOrderNote(orderId, formData);

      if (userId && offerId) {
        history.push(`/users/${userId}/custom-offers/${offerId}`);
      } else {
        history.push(`/purchases/${orderId}`);
      }
    } catch (error) {
      setSaveState(buttonStates.failed);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="xl">
      {offerId && (
        <Button component={Link} to={`/users/${userId}/custom-offers/${offerId}`}>
          <ChevronLeftIcon /> Custom offer
        </Button>
      )}
      {!offerId && (
        <Button component={Link} to={`/purchases/${orderId}`}>
          <ChevronLeftIcon /> Order summary
        </Button>
      )}
      <PageHeader title={ADD_NOTE} />
      <form onSubmit={submitNote}>
        <Stack spacing={2}>
          <TextField
            required
            label="Comment"
            name="comment"
            onChange={(event) => setComment(event.target.value)}
            value={comment}
            inputProps={{ maxLength: 100 }}
          />
          <FormControl required>
            <InputLabel id="comment-type-select-label">Type</InputLabel>
            <Select
              label="Type"
              labelId="comment-type-select-label"
              name="comment_type"
              onChange={(event) => setCommentType(event.target.value)}
              required
              value={commentType}
            >
              {comments.map((commentType) => (
                <MenuItem key={commentType} value={commentType}>
                  {mapCommentType(commentType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showPdf && (
            <Stack spacing={1} direction="row">
              <Button component="label" sx={{ width: '100%' }} variant="contained" startIcon={<CloudUploadIcon />}>
                Upload invoice
                <Box display="none">
                  <input
                    type="file"
                    onChange={(event) => {
                      setInvoice(event.target?.files[0]);
                      event.target.value = '';
                    }}
                  />
                </Box>
              </Button>
              {invoice && (
                <Chip
                  sx={{ width: '75px' }}
                  color="primary"
                  onDelete={() => setInvoice(null)}
                  avatar={<PictureAsPdfIcon fontSize="large" />}
                />
              )}
            </Stack>
          )}
          <Button sx={{ maxWidth: '150px' }} variant="contained" type="submit">
            {buttonMessages[saveState]}
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default OrderAddNotePage;
