import React from 'react';

import { Box, Stack, TextField } from '@mui/material';

import { VENDOR_REFUND_AGREES_NUMBER, VENDOR_REFUND_AGREES_PERCENTAGE } from '../../consts/refund';

export default class RefundVendorAgrees extends React.Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
  }

  componentDidMount() {
    if (this.props.refund.is_tour && this.props.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_NUMBER) {
      // we only want to show the percentage option if the refund is for a tour
      this.props.handleValueChange({
        target: {
          name: 'refund_vendor_agrees_to',
          value: VENDOR_REFUND_AGREES_PERCENTAGE,
        },
      });
    }
  }

  handler(event) {
    let key = event.target.name;
    let value = Number(event.target.value);

    if (key == 'number_of_nights' && value <= this.props.refund.total_nights && value >= 0 && Number.isInteger(value)) {
      this.props.handleValueChange(event);
    } else if (key == 'percentage' && value <= 100 && value >= 0) {
      this.props.handleValueChange(event);
    }
  }

  render() {
    return (
      <Box id="RefundVendorAgrees">
        <h4>Payable to vendor</h4>
        <Stack direction="row" spacing={2}>
          {!this.props.refund.is_tour && (
            <Box>
              <input
                type="radio"
                key={VENDOR_REFUND_AGREES_NUMBER}
                name="refund_vendor_agrees_to"
                value={VENDOR_REFUND_AGREES_NUMBER}
                onChange={this.props.handleValueChange}
                checked={this.props.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_NUMBER}
              />
              <label>Number of nights</label>
            </Box>
          )}
          <Box>
            <input
              type="radio"
              key={VENDOR_REFUND_AGREES_PERCENTAGE}
              name="refund_vendor_agrees_to"
              value={VENDOR_REFUND_AGREES_PERCENTAGE}
              onChange={this.props.handleValueChange}
              checked={this.props.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE}
            />
            <label>Percentage</label>
          </Box>
        </Stack>

        {this.props.refund_vendor_agrees_to == VENDOR_REFUND_AGREES_NUMBER && (
          <Box>
            <TextField
              name="number_of_nights"
              value={this.props.refund.number_of_nights}
              onChange={this.handler}
              type="number"
              data-cy="number_of_nights"
              inputProps={{ step: 1 }}
            />
          </Box>
        )}
        {this.props.refund_vendor_agrees_to == VENDOR_REFUND_AGREES_PERCENTAGE && (
          <Box>
            <TextField
              name="percentage"
              value={this.props.refund.percentage}
              onChange={this.handler}
              data-cy="percentage"
            />
          </Box>
        )}
      </Box>
    );
  }
}
